import './sectionicons.scss';
import React from 'react';

import NextSection from '../../../components/buttons/NextSection';
import Section from '../../../components/Section/Section';
import CrossIcon from '../../../components/svgs/CrossIcon';
import DownIcon from '../../../components/svgs/DownIcon';
import EditPencil from '../../../components/svgs/EditPencil';
import EyeClosed from '../../../components/svgs/EyeClosed';
import EyeOpen from '../../../components/svgs/EyeOpened';
import FacebookIcon from '../../../components/svgs/FacebookIcon';
import FacebookLogo from '../../../components/svgs/FacebookLogo';
import GearIcon from '../../../components/svgs/GearIcon';
import GitHubIcon from '../../../components/svgs/GitHubIcon';
import GitHubLogo from '../../../components/svgs/GitHubLogo';
import InstagramIcon from '../../../components/svgs/InstagramIcon';
import InstagramLogo from '../../../components/svgs/InstagramLogo';
import KebabIcon from '../../../components/svgs/KebabIcon';
import LeftIcon from '../../../components/svgs/LeftIcon';
import LoginIcon from '../../../components/svgs/LoginIcon';
import LogoutIcon from '../../../components/svgs/LogoutIcon';
import MinusIcon from '../../../components/svgs/MinusIcon';
import PlusIcon from '../../../components/svgs/PlusIcon';
import ProfileIcon from '../../../components/svgs/ProfileIcon';
import RightIcon from '../../../components/svgs/RightIcon';
import Triangle from '../../../components/svgs/Triangle';
import UpIcon from '../../../components/svgs/UpIcon';

function SectionInputs(props) {
  return (
    <Section colorizeButtons className="section-icons" {...props}>
      <h1>Icons Library</h1>
      <div className="svg-icons flex-container">
        <EditPencil />
        <GearIcon />
        <KebabIcon />
        <CrossIcon />
        <MinusIcon />
        <PlusIcon />
        <ProfileIcon />
        <LeftIcon />
        <UpIcon />
        <RightIcon />
        <DownIcon />
        <Triangle />
        <LoginIcon />
        <LogoutIcon />
        <EyeOpen />
        <EyeClosed />
      </div>
      <div className="flex-container">
        <div className="social-container">
          <FacebookIcon />
          <FacebookLogo />
        </div>
        <div className="social-container">
          <GitHubIcon />
          <GitHubLogo />
        </div>
        <div className="social-container">
          <InstagramIcon />
          <InstagramLogo />
        </div>
      </div>
      <NextSection />
    </Section>
  );
}

export default SectionInputs;
