import './dropdown.scss';

import React, { useState, useId } from 'react';

import PropTypes from 'prop-types';

import useClickOutside from '../../hooks/useClickOutside';
import Input from '../inputs/Input';

function DropDown({ value, values: dropDownValues, className, name, onChange, ...otherProps }) {
  const [menuVisible, setMenuVisibility] = useState(false);
  const id = useId().replaceAll(':', ''); // Remove invalid characters from id to use it as element's id

  useClickOutside({
    selectors: [`#${id}`],
    additionalCriteria: menuVisible,
    action: () => setMenuVisibility(false),
  });

  const handleFieldClick = () => setMenuVisibility((bool) => !bool);

  return (
    <Input
      id={id}
      type="text"
      readOnly
      onClick={handleFieldClick}
      value={`${value} ▾`}
      className={`dropdown${className ? ` ${className}` : ''}`}
      {...otherProps}
    >
      <div className={`dropdown-list${menuVisible ? ' visible' : ' hidden'}`}>
        {dropDownValues.map((dropDownValue) => (
          /* Using input to pass name and value to changeHandler via event as it is done for "normal" inputs */
          <input
            key={dropDownValue}
            type="text"
            readOnly
            name={name}
            value={dropDownValue}
            onClick={onChange}
            className="dropdown-item"
          />
        ))}
      </div>
    </Input>
  );
}

DropDown.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DropDown.defaultProps = {
  className: '',
  name: null,
};

export default DropDown;
