import React from 'react';

function EyeOpened(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      transform="scale(0.8)"
      fill="none"
      {...props}
    >
      <g
        stroke="#000000"
        clipPath="url(#clip0_113_49)"
      >
        <path
          className="stroke"
          d="M15 7C6.5 7 1 15.5 1 15.5C1 15.5 6.5 24.5 15.5 24.5C24.5 24.5 29 15.5 29 15.5C29 15.5 23.5 7 15 7Z"
        />
        <circle
          fill="black"
          className="stroke fill"
          cx="15.5"
          cy="15.5"
          r="5"
        />
        <circle
          fill="#ffffff"
          stroke="none"
          cx="13"
          cy="13"
          r="3"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          className="stroke"
          d="M4 12C4 12 2.5 11 1 8.5M9 8C9 8 8.5 7 8 5M18 7V4.5M25.5 11.5C25.5 11.5 25.5 10.5 26.5 8"
        />
      </g>
      <defs>
        <clipPath id="clip0_113_49">
          <rect
            fill="#ffffff"
            width="30"
            height="21"
            transform="translate(0 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EyeOpened;
