import './footer.scss';
import React from 'react';

import { Link } from 'react-router-dom';

import company from '../../config/company';
import socials from '../../config/socials';

function Footer() {
  return (
    <footer>
      <section id="social-bar">
        {Object.values(socials).map((social) => (
          <a
            aria-label={`Go to ${social.NAME} page`}
            href={social.LINK}
            target="_blank"
            rel="noopener noreferrer"
            key={social.LINK}
          >
            <social.ICON color="var(--content-background-color)" className="phablet-max" />
            <social.LOGO className="tablet-min" />
          </a>
        ))}
      </section>
      <section id="legal-bar">
        <Link to="/privacy">Privacy Policy</Link>
        <div>{`© ${new Date().getFullYear()} ${company.NAME}`}</div>
      </section>
    </footer>
  );
}

export default Footer;
