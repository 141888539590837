/* eslint-disable no-console */
const getQueryName = (query) => {
  const queryName = query.replaceAll(/[\n\r\s]/g, '').match(/{(\w*)\(/)[1];
  return queryName;
};

const fetchGraphQL = async (query) => {
  if (!navigator.onLine) throw new Error('Offline');

  const token = localStorage.getItem('token');
  if (!token) throw new Error('Unauthenticated');

  const response = await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Accept: 'application/json', Authorization: `Bearer ${token}` },
    body: JSON.stringify({ query }),
  });

  if (response.status !== 200) throw response;

  const body = await response.json();
  if (body.errors) throw body.errors[0];

  return body.data[getQueryName(query)];
};

export default fetchGraphQL;
