import React from 'react';

import NextSection from '../../../components/buttons/NextSection';
import Section from '../../../components/Section/Section';

function SectionIntro(props) {
  return (
    <Section colorizeButtons {...props}>
      <h1>Sections</h1>
      <p>Sections are blocks of content and can be customized.</p>
      <p>Large paddings help to emphasize the content and keep space for the toolabr and buttons.</p>
      <NextSection />
    </Section>
  );
}

export default SectionIntro;
