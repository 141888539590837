/* Application Styles */
/* Styles have to be imported before everything else for Safari to use it */
import './index.scss';

import { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';

import App from './containers/App';
import { UserProvider } from './contexts/UserContext';
import reportWebVitals from './reportWebVitals';

/* Initialize app configuration */
import './config/init';

smoothscroll.polyfill(); // Allow smooth scroll to work on ios

createRoot(document.getElementById('app')).render(
  <StrictMode>
    <BrowserRouter basename="/">
      <UserProvider>
        <App />
      </UserProvider>
    </BrowserRouter>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
