import React, { useState, useContext } from 'react';

import setDynamicColumnsAPI from '../../../../api/setDynamicColumnsAPI';
import setGroupByCategoryAPI from '../../../../api/setGroupByCategoryAPI';
import setGroupingAPI from '../../../../api/setGroupingAPI';
import setShowAverageAPI from '../../../../api/setShowAverageAPI';
import setShowDatesAPI from '../../../../api/setShowDatesAPI';
import setShowSelectedExercisesAPI from '../../../../api/setShowSelectedExercisesAPI';
import setShowTooltipAPI from '../../../../api/setShowTooltipAPI';
import MenuButton from '../../../../components/buttons/MenuButton';
import Modal from '../../../../components/Modal/Modal';
import KebabIcon from '../../../../components/svgs/KebabIcon';
import UserContext from '../../../../contexts/UserContext';
import ExerciseCompleteSettingsForm from '../ExerciseCompleteSettingsForm';
import ExerciseDeleteForm from '../ExerciseDeleteForm/ExerciseDeleteForm';

function PreferencesMenu() {
  const { user, setUser } = useContext(UserContext);

  const handleGroupByCategory = () => {
    setUser((prevUser) => {
      const newGroupByCategory = !prevUser.sport.groupByCategory ?? false;
      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser.sport,
          groupByCategory: newGroupByCategory,
        },
      };

      if (navigator.onLine && !!localStorage.getItem('token')) {
        setGroupByCategoryAPI(prevUser._id, newGroupByCategory);
      }

      return newUser;
    });
  };

  const handleShowTooltip = () => {
    setUser((prevUser) => {
      const newShowTooltip = !prevUser.sport.showTooltip;
      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser.sport,
          showTooltip: newShowTooltip,
        },
      };

      if (navigator.onLine && !!localStorage.getItem('token')) {
        setShowTooltipAPI(prevUser._id, newShowTooltip);
      }

      return newUser;
    });
  };

  const handleShowSelectedExercises = () => {
    setUser((prevUser) => {
      const newShowSelectedExercises = !prevUser.sport.showSelectedExercises;
      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser.sport,
          showSelectedExercises: newShowSelectedExercises,
        },
      };

      if (navigator.onLine && !!localStorage.getItem('token')) {
        setShowSelectedExercisesAPI(prevUser._id, newShowSelectedExercises);
      }

      return newUser;
    });
  };

  const handleShowAverage = () => {
    setUser((prevUser) => {
      const newShowAverage = !prevUser.sport.showAverage;
      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser.sport,
          showAverage: newShowAverage,
        },
      };

      if (navigator.onLine && !!localStorage.getItem('token')) {
        setShowAverageAPI(prevUser._id, newShowAverage);
      }

      return newUser;
    });
  };

  const [showGroupbByOptions, setShowGroupByOptions] = useState(false);
  const handleShowGroupByOptions = () => setShowGroupByOptions((bool) => !bool);
  const { period } = user.sport; // "one_week" | "one_month" | "three_months" | "one_year"
  const handleGroupBy = showGroupbByOptions ? (event) => {
    const newGroupBy = event.target.getAttribute('value');
    if (newGroupBy === (user?.sport?.grouping && JSON.parse(user.sport.grouping)?.[period])) return;

    setUser((prevUser) => {
      const newGrouping = JSON.stringify({
        ...(prevUser?.sport?.grouping && JSON.parse(prevUser.sport.grouping)),
        [period]: newGroupBy,
      });
      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser.sport,
          grouping: newGrouping,
        },
      };

      if (navigator.onLine && !!localStorage.getItem('token')) {
        setGroupingAPI(prevUser._id, newGrouping);
      }

      return (newUser);
    });
  } : undefined; // Deactivate function if options are hidden

  const handleDynamicColumns = () => {
    setUser((prevUser) => {
      const newDynamicColumns = !prevUser.sport.dynamicColumns ?? true;
      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser?.sport,
          dynamicColumns: newDynamicColumns,
        },
      };

      if (navigator.onLine && !!localStorage.getItem('token')) {
        setDynamicColumnsAPI(prevUser._id, newDynamicColumns);
      }

      return newUser;
    });
  };

  const handleShowDates = () => {
    setUser((prevUser) => {
      const newShowDates = !prevUser.sport.showDates ?? true;
      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser?.sport,
          showDates: newShowDates,
        },
      };

      if (navigator.onLine && !!localStorage.getItem('token')) {
        setShowDatesAPI(prevUser._id, newShowDates);
      }

      return newUser;
    });
  };

  const [modalFormType, setModalFormType] = useState(null);
  const [isExerciseModalOpen, setIsExerciseModalOpen] = useState(false);
  const closeModal = () => {
    setModalFormType(null);
    setIsExerciseModalOpen(false);
  };

  const handleDisplayEditContent = () => {
    setModalFormType('edit');
    setIsExerciseModalOpen(true);
  };
  const handleNewExercise = () => {
    setModalFormType('new');
    setIsExerciseModalOpen(true);
  };
  const handleDisplayDeleteContent = () => {
    setModalFormType('delete');
    setIsExerciseModalOpen(true);
  };

  const groupByItemStyle = {
    verticalAlign: 'center',
    height: `${showGroupbByOptions ? '2rem' : '0'}`,
    opacity: `${showGroupbByOptions ? '100%' : '0'}`,
    padding: `${showGroupbByOptions ? '0.5rem 1rem' : '0'}`,
  };

  const groupBy = JSON.parse(user.sport.grouping)[period]; // day | week | month
  const groupByCategory = user.sport.groupByCategory ?? false;
  const showSelectedExercises = user.sport.showSelectedExercises ?? false;
  const showTooltip = user.sport.showTooltip ?? true;
  const dynamicColumns = user.sport.dynamicColumns ?? true;
  const showDates = user.sport.showDates ?? true;
  const showAverage = user.sport.showAverage ?? false;
  return (
    <>
      <MenuButton
        aria-label="Exercise preference menu"
        nopadding
        notriangle
        label={<KebabIcon />}
        boxStyle={{ width: '250px' }}
        closeOnClick={false}
      >
        <>
          <div
            role="button"
            tabIndex="0"
            className="item"
            style={{ align: 'left' }}
            onClick={handleGroupByCategory}
            onKeyPress={handleGroupByCategory}
          >
            {groupByCategory ? 'remove categories' : 'group by category'}
          </div>
          <div
            role="button"
            tabIndex="0"
            className="item"
            style={{ align: 'left' }}
            onClick={handleShowSelectedExercises}
            onKeyPress={handleShowSelectedExercises}
          >
            {showSelectedExercises ? 'hide selected exercises' : 'show selected exercises'}
          </div>
          <div
            role="button"
            tabIndex="0"
            className="item"
            style={{ align: 'left' }}
            onClick={handleShowTooltip}
            onKeyPress={handleShowTooltip}
          >
            {showTooltip ? 'hide tooltip' : 'show tooltip'}
          </div>
          <div
            role="button"
            tabIndex="0"
            className="item"
            style={{ align: 'left' }}
            onClick={handleShowAverage}
            onKeyPress={handleShowAverage}
          >
            {showAverage ? 'hide average' : 'show average'}
          </div>
          <div
            role="button"
            tabIndex="0"
            className="item"
            style={{ display: 'flex', justifyContent: 'space-between' }}
            onClick={handleShowGroupByOptions}
            onKeyPress={handleShowGroupByOptions}
          >
            <span>
              {`group by ${groupBy}`}
            </span>
            <span>▹</span>
          </div>
          <div
            role="button"
            tabIndex="0"
            className="item"
            value="day"
            onClick={handleGroupBy}
            onKeyPress={handleGroupBy}
            style={{ align: 'right', ...groupByItemStyle }}
          >
            day
          </div>
          {(period === 'one_month' || period === 'three_months' || period === 'one_year')
              && (
              <div
                role="button"
                tabIndex="0"
                className="item"
                value="week"
                onClick={handleGroupBy}
                onKeyPress={handleGroupBy}
                style={{ align: 'right', ...groupByItemStyle }}
              >
                week
              </div>
              )}
          {(period === 'three_months' || period === 'one_year')
              && (
              <div
                role="button"
                tabIndex="0"
                className="item"
                value="month"
                onClick={handleGroupBy}
                onKeyPress={handleGroupBy}
                style={{ align: 'right', ...groupByItemStyle }}
              >
                month
              </div>
              )}
          <div
            role="button"
            tabIndex="0"
            className="item"
            style={{ align: 'left' }}
            onClick={handleDynamicColumns}
            onKeyPress={handleDynamicColumns}
          >
            {dynamicColumns ? 'keep columns width' : 'use columns space'}
          </div>
          <div
            role="button"
            tabIndex="0"
            className="item"
            style={{ align: 'left' }}
            onClick={handleShowDates}
            onKeyPress={handleShowDates}
          >
            {showDates ? 'hide dates' : 'show dates'}
          </div>
          <div
            role="button"
            tabIndex="0"
            className="item"
            style={{ align: 'left' }}
            onClick={handleDisplayEditContent}
            onKeyPress={handleDisplayEditContent}
          >
            edit current exercise
          </div>
          <div
            role="button"
            tabIndex="0"
            className="item"
            style={{ align: 'left' }}
            onClick={handleNewExercise}
            onKeyPress={handleNewExercise}
          >
            create new exercise
          </div>
        </>
      </MenuButton>
      <Modal
        title={
          modalFormType === 'new' ? 'Create New Exercise'
            : modalFormType === 'edit' ? 'Edit Exercise'
              : modalFormType === 'delete' ? 'Delete Exercise'
                : null
        }
        isOpen={isExerciseModalOpen}
        setIsOpen={setIsExerciseModalOpen}
      >
        <ExerciseDeleteForm
          closeModal={closeModal}
          handleDisplayEditContent={handleDisplayEditContent}
          userContext={{ user, setUser }}
          className={modalFormType === 'delete' ? 'visible' : 'hidden'}
        />
        <ExerciseCompleteSettingsForm
          isNewExercise={modalFormType === 'new'}
          closeModal={closeModal}
          handleDisplayDeleteContent={handleDisplayDeleteContent}
          userContext={{ user, setUser }}
          className={modalFormType === 'new' || modalFormType === 'edit' ? 'visible' : 'hidden'}
        />
      </Modal>
    </>
  );
}

export default PreferencesMenu;
