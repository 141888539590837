import React from 'react';

import { useNavigate } from 'react-router-dom';

import LeftButton from './LeftButton';

function BackButton(props) {
  const navigate = useNavigate();
  return <LeftButton aria-label="Go back" onClick={() => navigate(-1)} {...props} />;
}

export default BackButton;
