const days = [
  [2, 3, 4, 3, 2],
  [3, 4, 4, 3, 2],
  [4, 4, 3, 5, 4],
  [5, 6, 6, 4, 4],
  [7, 6, 6, 5, 3],
  [8, 8, 6, 7, 6],
  [10, 6, 10, 8, 6],
  [12, 8, 8, 10, 8],
  [14, 10, 14, 8, 8],
  [20],
  [16, 12, 14, 10, 10],
  [16, 16, 12, 11, 10],
  [18, 16, 12, 12, 10],
  [20, 16, 14, 12, 12],
  [22, 12, 18, 12, 12],
  [24, 20, 16, 12, 8],
  [26, 20, 14, 12, 10],
  [28, 22, 14, 12, 10],
  [28, 22, 14, 12, 12],
  [30, 20, 20, 10, 10],
  [32, 20, 18, 10, 12],
  [32, 22, 18, 14, 10],
  [34, 20, 18, 16, 12],
  [36, 22, 16, 16, 14],
  [38, 24, 18, 14, 12],
  [38, 22, 16, 20, 12],
  [40, 18, 24, 16, 16],
  [50],
  [34, 24, 22, 20, 18],
  [36, 26, 22, 22, 14],
  [38, 26, 22, 22, 14],
  [40, 26, 24, 18, 16],
  [40, 28, 24, 18, 16],
  [42, 24, 22, 22, 18],
  [46, 26, 24, 20, 16],
  [46, 26, 24, 20, 18],
  [48, 26, 26, 20, 16],
  [50, 28, 24, 20, 18],
  [50, 26, 26, 22, 18],
  [52, 26, 26, 22, 18],
  [54, 28, 24, 22, 18],
  [56, 28, 24, 22, 18],
  [58, 28, 24, 22, 18],
  [60, 26, 24, 24, 20],
  [62, 30, 34, 22, 18],
  [64, 30, 24, 20, 20],
  [66, 30, 24, 22, 20],
  [68, 30, 24, 22, 20],
  [70, 32, 24, 22, 18],
  [74, 32, 28, 18, 18],
  [76, 32, 24, 20, 20],
  [78, 32, 26, 20, 18],
  [100],
];

export default days;
