const getContainerElement = (element) => {
  try {
    if (
      element.tagName === 'ARTICLE'
    || element.tagName === 'SECTION'
    || element.tagName === 'MAIN'
    || element.tagName === 'BODY'
    || element.classList.contains('document')
    ) {
      return element;
    }
    return getContainerElement(element.parentNode);
  } catch (err) {
    return err;
  }
};

export default getContainerElement;
