import './tooltip.scss';
import React from 'react';

import propTypes from 'prop-types';

function Tooltip({
  show,
  bottom,
  center,
  children,
  ...otherProps
}) {
  return (
    <div
      className={`tooltip${
        bottom ? ' bottom' : ' top'}${
        center ? ' center' : ''}${
        show ? ' visible' : ' hidden'
      }`}
      {...otherProps}
    >
      {children}
    </div>
  );
}

Tooltip.propTypes = {
  show: propTypes.bool,
  bottom: propTypes.bool,
  center: propTypes.bool,
  children: propTypes.node,
};
Tooltip.defaultProps = {
  show: false,
  bottom: false,
  center: false,
  children: 'text',
};

export default Tooltip;
