import './navbar.scss';
import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Linkbar from './Linkbar';
import ProfileForm from './ProfileForm';
import Button from '../../components/buttons/Button';
import Hamburger from '../../components/buttons/Hamburger';
import Modal from '../../components/Modal/Modal';
import ProfileIcon from '../../components/svgs/ProfileIcon';
import company from '../../config/company';
import UserContext from '../../contexts/UserContext';

function Navbar({
  handleToggle,
  isHamburgerOpen,
}) {
  const isLoggedIn = !!localStorage.getItem('token');

  const profilePicture = useContext(UserContext).user.profile.profile_picture;
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const urlToken = queryParameters.get('token');
    if (!isLoggedIn && urlToken) {
      setIsProfileOpen(true);
    }
  }, [isLoggedIn]);

  const handleProfileClick = () => setIsProfileOpen(true);
  const closeProfileModal = () => setIsProfileOpen(false);

  return (
    <>
      <div className="navbar">
        <Hamburger id="hamburger" onClick={handleToggle} isOpen={isHamburgerOpen} />
        <Link id="brand" to="/" aria-label="Go to Home page" hidden={window.location.pathname === '/sport'}>
          <company.LOGO />
          <div className="title phablet-min">{company.NAME}</div>
        </Link>
        <Linkbar id="topnavbar" className="tablet-min" />
        <Button
          aria-label="Profile"
          nopadding
          id="profile-button"
          className="profile"
          onClick={handleProfileClick}
        >
          {profilePicture ? <img src={profilePicture} alt="" /> : <ProfileIcon /> }
        </Button>
      </div>
      <Modal
        title={isLoggedIn ? 'Profile' : ''}
        isOpen={isProfileOpen}
        setIsOpen={setIsProfileOpen}
      >
        <ProfileForm
          closeModal={closeProfileModal}
          isResetPasswordValidated={window.location.pathname === '/reset-password'}
        />
      </Modal>
    </>
  );
}

Navbar.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  isHamburgerOpen: PropTypes.bool.isRequired,
};

export default Navbar;
