import './sets.scss';

import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';

import Button from '../../../../components/buttons/Button';
import LeftButton from '../../../../components/buttons/LeftButton';
import Modal from '../../../../components/Modal/Modal';
import Section from '../../../../components/Section/Section';
import GearIcon from '../../../../components/svgs/GearIcon';
import MinusIcon from '../../../../components/svgs/MinusIcon';
import PlusIcon from '../../../../components/svgs/PlusIcon';
import Timer from '../../../../components/Timer/Timer';
import Toolbar from '../../../../components/Toolbar/Toolbar';
import UserContext from '../../../../contexts/UserContext';
import WakeLock from '../../../../lib/WakeLock';
import ExerciseSimpleSettings from '../ExerciseSimpleSettings/ExerciseSimpleSettings';
import ExerciseSimpleSettingsContainer from '../ExerciseSimpleSettings/ExerciseSimpleSettingsContainer';
import ExerciseSimpleSettingsSaveButton from '../ExerciseSimpleSettings/ExerciseSimpleSettingsSaveButton';
import progressionDays from '../progressionDays';
import CancelWorkoutModal from './CancelWorkoutModal';
import FinishWorkoutModal from './FinishWorkoutModal/FinishWorkoutModal';

function getUserLastExerciseSession(exerciseName, user) {
  return JSON.parse(user.sport.exercises)[exerciseName].history.at(-1)?.sets || [0, 0, 0, 0, 0];
}

function Sets() {
  const { user, setUser } = useContext(UserContext);
  const exerciseName = user.sport.currentExercise;
  const { day } = JSON.parse(user.sport.exercises)[exerciseName];
  const freeSet = !Number.isInteger(day);
  const exercise = JSON.parse(user.sport.exercises)[exerciseName];

  const warmupPause = { minutes: 0, seconds: 10 };
  const [pause, setPause] = useState(warmupPause);
  const [showTimer, setShowTimer] = useState(true);
  const [warmUp, setWarmUp] = useState(true);
  const [setsDone, setSetsDone] = useState([0]);
  const lastExerciseSession = useMemo(() => getUserLastExerciseSession(exerciseName, user), [exerciseName, user]);
  const [setsToDo, setSetsToDo] = useState(freeSet ? lastExerciseSession : progressionDays[day - 1]);
  const [setNumber, setSetNumber] = useState(0);
  const repsDone = setsDone[setNumber];
  const totalRepsToDo = setsToDo.reduce((total, set) => total + set);
  const [totalRepsDone, setTotalRepsDone] = useState(0);
  const totalRepsToGo = totalRepsToDo - totalRepsDone;
  const done = totalRepsToGo <= 0;

  // Increase length of setToDo on extra sets to display new sets in sets-bar
  useEffect(() => {
    if (setNumber > setsToDo.length - 1) {
      setSetsToDo((prevSetsToDo) => [...prevSetsToDo, 0]);
    }
  }, [setNumber, setsToDo]);

  // Keep screen from turning off during rest time (android only)
  useEffect(() => {
    const wakeLock = WakeLock();
    wakeLock.request();
    return wakeLock.release;
  }, []);

  const handleTimerEnd = useCallback(() => {
    setShowTimer(false);
    if (warmUp) {
      setWarmUp(false);
      const restTime = parseFloat(JSON.parse(user.sport.exercises)[exerciseName].restTime);
      const restPause = { minutes: Math.floor(restTime / 60), seconds: restTime % 60 };
      setPause(restPause); // Switch to rest timer if necessary
    }
  }, [exerciseName, user.sport.exercises, warmUp]);

  const handleRest = useCallback(() => {
    setSetNumber((prevSetNumber) => prevSetNumber + 1);
    setSetsDone((prevSetsDone) => [...prevSetsDone, 0]);
    setShowTimer(true);
  }, []);

  const handleRepsDone = useCallback((n) => {
    setSetsDone((prevSetsDone) => {
      const newSetsDone = [...prevSetsDone];
      newSetsDone[setNumber] = prevSetsDone[setNumber] + n;
      return newSetsDone;
    });
    setTotalRepsDone((total) => total + n);
  }, [setNumber]);
  const handlePlusRep = useCallback(() => handleRepsDone(1), [handleRepsDone]);
  const handleMinusRep = useCallback(() => handleRepsDone(-1), [handleRepsDone]);

  const [isExerciseModalOpen, setIsExerciseModalOpen] = useState(false);
  const openSettings = useCallback(() => setIsExerciseModalOpen(true), []);
  const closeSettings = useCallback(() => setIsExerciseModalOpen(false), []);

  const [isCancelModalOpen, setIsCancelWorkoutOpen] = useState(false);
  const handleOpenCancelModal = () => setIsCancelWorkoutOpen(true);

  const [isFinishModalOpen, setIsFinishWorkoutOpen] = useState(false);
  const handleFinish = () => setIsFinishWorkoutOpen(true);

  return (
    <main id="sets" className={`${showTimer ? 'inverse-colors' : 'set-colors'}`}>
      <Toolbar>
        <LeftButton aria-label="Go back" onClick={handleOpenCancelModal} noresize />
        <div>{exerciseName}</div>
        <div style={{ width: '2rem', height: '2rem' }} />
      </Toolbar>
      <Section>
        <div className="sets-bar">
          <div className="sets-bar-background" />
          <div className={`sets-bar-title${setNumber === 0 && showTimer ? ' hide' : ' show'}`}>
            reps done
          </div>
          <div className="sets-bar-subtitle">
            {freeSet ? 'previous workout' : 'reps to do'}
          </div>
          {setsToDo.map((repsToDo, i) => {
            const showRepsDone = i < setNumber || (i === setNumber && !showTimer);
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className={`reps-container${showRepsDone ? ' set-text-color' : ' inverse-text-color'}`}
              >
                <div className={`reps-container-background${
                  i === setNumber
                    ? `${showTimer ? ' inverse-background-color' : ' set-background-color'}`
                    : ' hide-background'
                }`}
                />
                <span className={`reps-done${showRepsDone ? ' show' : ' hide'}`}>{setsDone[i]}</span>
                <span className={`reps-to-do${showRepsDone ? ' reduce' : ' plain'}`}>
                  {repsToDo}
                </span>
              </div>
            );
          })}
        </div>
        <div className="center-container">
          <div className="counter-container">
            {showTimer ? (
              <Timer
                className="timer"
                minutes={pause.minutes}
                seconds={pause.seconds}
                isActive={showTimer}
                onFinish={handleTimerEnd}
              />
            ) : (
              <>
                <Button
                  square
                  nopadding
                  style={{ fontSize: '2em' }}
                  disabled={repsDone === 0}
                  onClick={handleMinusRep}
                >
                  <MinusIcon />
                </Button>
                <div
                  role="button"
                  className="counter"
                  onClick={handlePlusRep}
                  onKeyPress={handlePlusRep}
                  tabIndex="0"
                >
                  {repsDone}
                </div>
                <Button
                  square
                  nopadding
                  style={{ fontSize: '2em' }}
                  onClick={handlePlusRep}
                >
                  <PlusIcon />
                </Button>
              </>
            )}
          </div>
          <div className="subtitle">{warmUp ? 'warm-up' : showTimer ? 'rest now' : 'reps done'}</div>
        </div>
        <div className="bottom-container">
          <div className="footer">
            <div>
              {`${done ? totalRepsDone : totalRepsToGo} total ${done ? 'done' : 'to go'}`}
            </div>
            <div className="settings">
              <div>{`${exercise.addedWeight} kg`}</div>
              <div>{`${exercise.restTime} s`}</div>
              <Button
                square
                nopadding
                onClick={openSettings}
              >
                <GearIcon />
              </Button>
            </div>
          </div>
          <div className="buttons">
            {showTimer ? <Button onClick={handleTimerEnd} border>Skip</Button> : null}
            {!showTimer ? <Button onClick={handleFinish} border>Finish</Button> : null}
            {!showTimer ? <Button onClick={handleRest} border>Rest</Button> : null}
          </div>
        </div>
      </Section>
      <Modal
        title={exerciseName}
        isOpen={isExerciseModalOpen}
        setIsOpen={setIsExerciseModalOpen}
      >
        <ExerciseSimpleSettingsContainer userContext={{ user, setUser }}>
          <ExerciseSimpleSettings />
          <ExerciseSimpleSettingsSaveButton
            closeSettings={closeSettings}
          />
        </ExerciseSimpleSettingsContainer>
      </Modal>
      <CancelWorkoutModal isOpen={isCancelModalOpen} setIsOpen={setIsCancelWorkoutOpen} />
      <FinishWorkoutModal
        isOpen={isFinishModalOpen}
        setIsOpen={setIsFinishWorkoutOpen}
        userContext={{ user, setUser }}
        setsDone={setsDone}
      />
    </main>
  );
}

export default Sets;
