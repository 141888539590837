import './selectsetbutton.scss';

import React, { useContext, useState } from 'react';

import setExercisesAPI from '../../../../api/setExercisesAPI';
import Button from '../../../../components/buttons/Button';
import UserContext from '../../../../contexts/UserContext';
import pushupsDays from '../progressionDays';
import SelectSetModal from '../SelectSetModal';

function SelectSetButton() {
  const { user, setUser } = useContext(UserContext);
  const exercises = JSON.parse(user.sport.exercises);
  const { currentExercise } = user.sport;
  const { history } = exercises[currentExercise];
  const previousSets = (history.length !== 0 && history[history.length - 1].sets) || [0, 0, 0, 0, 0];
  const { day } = exercises[currentExercise];
  const freeSets = !Number.isInteger(day);
  const nextSets = pushupsDays[day - 1];
  const sets = freeSets ? previousSets : nextSets;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChangeSet = () => setIsModalOpen(true);
  const handleChangeDay = (newDay) => {
    let newExercises;
    setUser((prevUser) => {
      newExercises = JSON.stringify({
        ...exercises,
        [currentExercise]: {
          ...exercises[currentExercise],
          day: newDay,
        },
      });
      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser.sport,
          exercises: newExercises,
        },
      };
      return newUser;
    });

    const isSaved = setExercisesAPI(user._id, newExercises, 'new day saved');

    if (isSaved) {
      setIsModalOpen(false);
    }
  };

  const setsTitle = Number.isInteger(day) ? 'next sets' : 'previous sets';
  return (
    <>
      <Button
        onClick={handleChangeSet}
        className="select-set-button"
      >
        <div className="next-sets">
          <div className="days-container">
            <div className="column-header">day</div>
            <div className="day d-day">{day === 'free-sets' ? '-' : day}</div>
          </div>
          <div className="sets-container">
            <div className="column-header">{setsTitle}</div>
            {/* eslint-disable-next-line react/no-array-index-key */}
            <div className="sets">{sets.map((reps, j) => <span className="set d-day" key={`reps ${j}`}>{reps}</span>)}</div>
          </div>
          <div className="total-container">
            <div className="column-header">total</div>
            <div className="total d-day">{sets.reduce((total, reps) => total + reps)}</div>
          </div>
        </div>
      </Button>
      <SelectSetModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onSelect={handleChangeDay}
      />
    </>
  );
}

export default SelectSetButton;
