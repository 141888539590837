import './deletemodal.scss';
import React from 'react';

import PropTypes from 'prop-types';

import SaveButton from '../buttons/SaveButton';
import Modal from '../Modal/Modal';

function DeleteModal({
  isOpen,
  setIsOpen,
  onDelete,
  name,
  title,
  className,
}) {
  const closeModal = () => setIsOpen(false);
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={title || 'Delete confirmation'}
    >
      <div className={`delete-modal${className ? ` ${className}` : ''}`}>
        <div className="content">
          You are about to delete
          {` ${name} `}
          and all its data.
          <br />
          <br />
          Are you sure you want to proceed ?
          <br />
        </div>
        <div className="buttons-bar">
          <SaveButton
            className="delete-button"
            onClick={onDelete}
            timeoutCallback={closeModal}
          >
            DELETE
          </SaveButton>
        </div>
      </div>
    </Modal>
  );
}

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
};

DeleteModal.defaultProps = {
  title: null,
  className: null,
};

export default DeleteModal;
