import './historylist.scss';
import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '../../../../components/buttons/Button';
import Modal from '../../../../components/Modal/Modal';
import EditPencil from '../../../../components/svgs/EditPencil';
import PlusIcon from '../../../../components/svgs/PlusIcon';
import EditWorkoutForm from '../EditWorkoutForm';

function HistoryList({ history, histogramHeight, showDates }) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [workoutToEdit, setWorkoutToEdit] = useState(null);
  const handleEdit = (workout) => {
    setWorkoutToEdit(workout);
    setIsEditModalOpen(true);
  };
  const handleAdd = () => {
    setWorkoutToEdit(null);
    setIsEditModalOpen(true);
  };

  const itemsList = useMemo(() => (history.length !== 0
    ? history
      .sort((a, b) => b.time - a.time)
      .map((workout) => (
        <div key={workout.time} className="item">
          <div className="date date-column">
            {new Date(workout.time)
              .toLocaleDateString('en-us', { month: '2-digit', day: '2-digit', timeZone: 'UTC' })}
          </div>
          <div className="sets sets-column">
            {workout.sets.map((reps, i, sets) => (
              <div
                className="day"
                // eslint-disable-next-line react/no-array-index-key
                key={`${workout.time}-${workout.name}-${i}`}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: `${sets.length !== 1 ? i * (100 / (sets.length - 1)) : 0}%`,
                  transform: `translate(${sets.length !== 1 ? -i * (100 / (sets.length - 1)) : 0}%, -50%)`,
                }}
              >
                {reps}
              </div>
            ))}
          </div>
          <div className="total total-column">
            {workout.sets.reduce((total, reps) => total + reps, 0)}
          </div>
          <div className="edit-column">
            <Button square nopadding className="edit-button" onClick={() => handleEdit(workout)}>
              <EditPencil className="edit-icon" />
            </Button>
          </div>
        </div>
      ))
    : (
      <div className="no-data-message">
        no  data for now
      </div>
    )), [history]);

  const closeModal = () => setIsEditModalOpen(false);

  return (
    <>
      <div
        className="history-list"
        style={{ height: 'calc('
        + '100%'
        + ` - ${histogramHeight}px`
        + `${showDates ? ' - var(--date-line-height)' : ''}`
        + `${showDates ? ' - var(--date-font-size)' : ''}`
        + ' - 2 * var(--bars-margin)'
        + ' - var(--periods-bar-height)'
        + ')' }}
      >
        <div className="header">
          <div className="date-column">date</div>
          <div className="sets-column">sets</div>
          <div className="total-column">total</div>
          <div className="edit-column">
            <Button square nopadding className="edit-button" onClick={handleAdd}>
              <PlusIcon className="edit-icon" />
            </Button>
          </div>
        </div>
        <div className="list-container">
          {itemsList}
        </div>
      </div>
      <Modal
        title={workoutToEdit === null ? 'New workout' : 'Edit workout'}
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
      >
        <EditWorkoutForm workout={workoutToEdit} closeModal={closeModal} />
      </Modal>
    </>
  );
}

HistoryList.propTypes = {
  history: PropTypes.any.isRequired,
  histogramHeight: PropTypes.number.isRequired,
  showDates: PropTypes.bool.isRequired,
};

export default HistoryList;
