import React from 'react';

function KebabIcon(props) {
  return (
    <svg {...props} width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#000000" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle cx="15" cy="23" r="2" />
        <circle cx="15" cy="15" r="2" />
        <circle cx="15" cy="7" r="2" />
      </g>
    </svg>
  );
}

export default KebabIcon;
