import './overflowtop.scss';
import React from 'react';

import PropTypes from 'prop-types';

import Triangle from '../svgs/Triangle';

function OverflowTop({ showTopTriangle }) {
  return (
    <div className={`overflow-top${!showTopTriangle ? ' hidden' : ''}`}>
      <Triangle className={!showTopTriangle ? 'hidden' : ''} />
    </div>
  );
}

OverflowTop.propTypes = {
  showTopTriangle: PropTypes.bool.isRequired,
};

export default OverflowTop;
