export const themeCategories = [
  {
    title: 'Theme Colors',
    variables: [
      {
        variable: '--color1',
        defaultValue: '#ffffff',
        type: 'color',
        name: 'color1',
        tip: 'color1',
      },
      {
        variable: '--color2',
        defaultValue: '#fafafa',
        type: 'color',
        name: 'color2',
        tip: 'color2',
      },
      {
        variable: '--color3',
        defaultValue: 'rgb(229, 229, 234)',
        type: 'color',
        name: 'color3',
        tip: 'color3',
      },
      {
        variable: '--color4',
        defaultValue: '#7c777f',
        type: 'color',
        name: 'color4',
        tip: 'color4',
      },
      {
        variable: '--color5',
        defaultValue: '#565763',
        type: 'color',
        name: 'color5',
        tip: 'color5',
      },
      {
        variable: '--primary',
        defaultValue: 'rgb(13,24,34) ',
        type: 'color',
        name: 'primary',
        tip: 'primary',
      },
    ],
  },
  {
    title: 'Text',
    variables: [
      {
        variable: '--font-name',
        defaultValue: 'Comfortaa',
        type: 'text',
        name: 'font name',
        tip: 'font name',
      },
      {
        variable: '--font-size',
        defaultValue: '16px',
        type: 'text',
        name: 'font size',
        tip: 'font size',
      },
      {
        variable: '--font-size-phone',
        defaultValue: '16px',
        type: 'text',
        name: 'font size (phone)',
        tip: 'font size (phone)',
      },
      {
        variable: '--text-color',
        defaultValue: 'var(--color5)',
        type: 'text',
        name: 'text color',
        tip: 'text color',
      },
      {
        variable: '--link-color',
        defaultValue: 'var(--text-color)',
        type: 'text',
        name: 'link color',
        tip: 'link color',
      },
      {
        variable: '--link-hover-color',
        defaultValue: 'var(--primary)',
        type: 'text',
        name: 'link hover color',
        tip: 'link hover color',
      },
    ],
  },
  {
    title: 'Lines & Borders',
    variables: [
      {
        variable: '--border-width',
        defaultValue: '1px',
        type: 'text',
        name: 'border width',
        tip: 'border width',
      },
      {
        variable: '--border-color',
        defaultValue: 'var(--color3)',
        type: 'color',
        name: 'border color',
        tip: 'border color',
      },
      {
        variable: '--border-radius',
        defaultValue: '10px',
        type: 'text',
        name: 'border radius',
        tip: 'border radius',
      },
      {
        variable: '--small-spread',
        defaultValue: '0 0 1px 1px',
        type: 'text',
        name: 'small spread',
        tip: 'offset-x | offset-y | blur-radius | spread-radius',
      },
      {
        variable: '--large-spread',
        defaultValue: '0 0 4px 4px',
        type: 'text',
        name: 'large spread',
        tip: 'offset-x | offset-y | blur-radius | spread-radius',
      },
      {
        variable: '--light-shadow-color',
        defaultValue: 'rgba(0,0,0,1%)',
        type: 'color',
        name: 'light shadow color',
        tip: 'shadow color',
      },
      {
        variable: '--medium-shadow-color',
        defaultValue: 'rgba(0,0,0,2.5%)',
        type: 'color',
        name: 'medium shadow color',
        tip: 'shadow color',
      },
      {
        variable: '--shadow-color',
        defaultValue: 'rgba(0,0,0,5%)',
        type: 'color',
        name: 'shadow color',
        tip: 'shadow color',
      },
      {
        variable: '--dark-shadow-color',
        defaultValue: 'rgba(0,0,0,7.5%)',
        type: 'color',
        name: 'dark shadow color',
        tip: 'shadow color',
      },
      {
        variable: '--small-light-shadow',
        defaultValue: 'var(--small-spread) var(--light-shadow-color)',
        type: 'text',
        name: 'small light shadow',
        tip: 'Display a small size light shadow around elements',
      },
      {
        variable: '--small-medium-shadow',
        defaultValue: 'var(--small-spread) var(--medium-shadow-color)',
        type: 'text',
        name: 'small medium shadow',
        tip: 'Display a small size medium shadow around elements',
      },
      {
        variable: '--small-shadow',
        defaultValue: 'var(--small-spread) var(--shadow-color)',
        type: 'text',
        name: 'small shadow',
        tip: 'Display a small size shadow around elements',
      },
      {
        variable: '--small-dark-shadow',
        defaultValue: 'var(--small-spread) var(--dark-shadow-color)',
        type: 'text',
        name: 'small dark shadow',
        tip: 'Display a small size dark shadow around elements',
      },
      {
        variable: '--large-light-shadow',
        defaultValue: 'var(--large-spread) var(--light-shadow-color)',
        type: 'text',
        name: 'large light shadow',
        tip: 'Display a large size light shadow around elements',
      },
      {
        variable: '--large-medium-shadow',
        defaultValue: 'var(--large-spread) var(--medium-shadow-color)',
        type: 'text',
        name: 'large medium shadow',
        tip: 'Display a large size medium shadow around elements',
      },
      {
        variable: '--large-shadow',
        defaultValue: 'var(--large-spread) var(--shadow-color)',
        type: 'text',
        name: 'large shadow',
        tip: 'Display a large size shadow around elements',
      },
      {
        variable: '--large-dark-shadow',
        defaultValue: 'var(--large-spread) var(--dark-shadow-color)',
        type: 'text',
        name: 'large dark shadow',
        tip: 'Display a large size dark shadow around elements',
      },
    ],
  },
  {
    title: 'Layout',
    variables: [
      {
        variable: '--layout-background-color',
        defaultValue: 'var(--color1)',
        type: 'color',
        name: 'layout bg color',
        tip: 'The color under all other layout elements',
      },
      {
        variable: '--layout-border-width',
        defaultValue: '0px',
        type: 'text',
        name: 'layout border width',
        tip: `The border's thickness of the layout elements.
        Such as navbar, side panel, modals... `,
      },
      {
        variable: '--main-border-width',
        defaultValue: 'var(--layout-border-width)',
        type: 'text',
        name: 'main border width',
        tip: `The border thickness which is at the bottom of the main content area
              Separating with it with the footer`,
      },
      {
        variable: '--main-border-color',
        defaultValue: 'var(--border-color)',
        type: 'color',
        name: 'main border color',
        tip: `The border color which is at the bottom of the main content area
              Separating with it with the footer`,
      },
      {
        variable: '--layout-transition-duration',
        defaultValue: '0.3s',
        type: 'text',
        name: 'layout transition time',
        tip: 'Effect duration of the layout moving parts',
      },
      {
        variable: '--element-transition-duration',
        defaultValue: '0.3s',
        type: 'text',
        name: 'elmnt transition time',
        tip: 'Effect duration of the elements contained within the pages',
      },
    ],
  },
  {
    title: 'Content',
    variables: [
      {
        variable: '--content-background-color',
        defaultValue: 'var(--color2)',
        type: 'color',
        name: 'background color',
        tip: 'Color of the central content area',
      },
      {
        variable: '--content-border-color',
        defaultValue: 'none',
        type: 'color',
        name: 'border color',
        tip: 'content border color',
      },
      {
        variable: '--content-padding',
        defaultValue: '3rem',
        type: 'text',
        name: 'padding',
        tip: 'content padding',
      },
      {
        variable: '--content-transition-duration',
        defaultValue: '0.5s',
        type: 'text',
        name: 'transition time',
        tip: 'content transition time',
      },
      {
        variable: '--image-transition-duration',
        defaultValue: '2s',
        type: 'text',
        name: 'img transition time',
        tip: 'image transition time',
      },
    ],
  },
  {
    title: 'Navbar',
    variables: [
      {
        variable: '--navbar-position',
        defaultValue: 'sticky',
        type: 'select',
        values: ['sticky', 'relative'],
        name: 'position',
        tip: 'header position',
      },
      {
        variable: '--navbar-background-color',
        defaultValue: 'var(--layout-background-color)',
        type: 'text',
        name: 'background color',
        tip: 'header background color',
      },
      {
        variable: '--navbar-text-color',
        defaultValue: 'var(--text-color)',
        type: 'text',
        name: 'text color',
        tip: 'header text color',
      },
      {
        variable: '--navbar-height',
        defaultValue: '4rem',
        type: 'text',
        name: 'height',
        tip: 'header height',
      },
      {
        variable: '--navbar-top-padding',
        defaultValue: '0',
        type: 'text',
        name: 'top padding',
        tip: 'header top padding',
      },
      {
        variable: '--navbar-bottom-padding',
        defaultValue: '0',
        type: 'text',
        name: 'bottom padding',
        tip: 'header bottom padding',
      },
      {
        variable: '--navbar-x-padding',
        defaultValue: '1rem',
        type: 'text',
        name: 'x-padding',
        tip: 'header x-padding',
      },
      {
        variable: '--navbar-border-width',
        defaultValue: 'var(--layout-border-width)',
        type: 'text',
        name: 'border-width',
        tip: 'thickness of the header\'s border',
      },
      {
        variable: '--navbar-border-color',
        defaultValue: 'var(--border-color)',
        type: 'color',
        name: 'border-color',
        tip: 'color of the header\'s border',
      },
    ],
  },
  {
    title: 'Hamburger',
    variables: [
      {
        variable: '--hamburger-size',
        defaultValue: '2rem',
        type: 'text',
        name: 'size',
        tip: 'hamburger size',
      },
      {
        variable: '--hamburger-line-width',
        defaultValue: '2px',
        type: 'text',
        name: 'line width',
        tip: 'hamburger line width',
      },
      {
        variable: '--hamburger-color',
        defaultValue: 'var(--navbar-text-color)',
        type: 'text',
        name: 'color',
        tip: 'hamburger color',
      },
      {
        variable: '--hamburger-background-color',
        defaultValue: 'var(--navbar-background-color)',
        type: 'text',
        name: 'background color',
        tip: 'hamburger background color',
      },
      {
        variable: '--hamburger-border-color',
        defaultValue: 'transparent',
        type: 'text',
        name: 'border color',
        tip: 'hamburger border color',
      },
      {
        variable: '--hamburger-border-width',
        defaultValue: 'var(--border-width)',
        type: 'text',
        name: 'border width',
        tip: 'hamburger border width',
      },
      {
        variable: '--hamburger-transition-duration',
        defaultValue: 'var(--element-transition-duration)',
        type: 'text',
        name: 'transition time',
        tip: 'hamburger transition time',
      },
    ],
  },
  {
    title: 'General Navigation',
    variables: [
      {
        variable: '--nav-item-height',
        defaultValue: '3rem',
        type: 'text',
        name: 'item height',
        tip: 'nav item height',
      },
      {
        variable: '--nav-item-gap',
        defaultValue: '0.3rem',
        type: 'text',
        name: 'item gap',
        tip: 'nav item gap',
      },
      {
        variable: '--nav-item-x-padding',
        defaultValue: '1rem',
        type: 'text',
        name: 'item x-padding',
        tip: 'nav item x-padding',
      },
      {
        variable: '--nav-item-border-width',
        defaultValue: 'var(--border-width)',
        type: 'text',
        name: 'item border width',
        tip: 'nav item border width',
      },
      {
        variable: '--nav-item-underline-width',
        defaultValue: '100%',
        type: 'text',
        name: 'item underline width',
        tip: 'nav item underline width',
      },

      {
        variable: '--nav-hover-background-color',
        defaultValue: 'var(--color5)',
        type: 'text',
        name: 'hover bg color',
        tip: 'nav hover background color',
      },
      {
        variable: '--nav-active-background-color',
        defaultValue: 'var(--primary)',
        type: 'text',
        name: 'active bg color',
        tip: 'nav active background color',
      },
      {
        variable: '--nav-underline-thickness',
        defaultValue: '2px',
        type: 'text',
        name: 'underline thickness',
        tip: 'nav underline thickness',
      },
      {
        variable: '--nav-underline-height',
        defaultValue: '0.3rem',
        type: 'text',
        name: 'underline height',
        tip: 'nav underline height',
      },
    ],
  },
  {
    title: 'Top Navigation',
    variables: [
      {
        variable: '--topnavbar-item-height',
        defaultValue: 'var(--nav-item-height)',
        type: 'text',
        name: 'item height',
        tip: 'topnavbar item height',
      },
      {
        variable: '--topnavbar-item-width',
        defaultValue: 'fit-content',
        type: 'text',
        name: 'item width',
        tip: 'topnavbar item width',
      },
      {
        variable: '--topnavbar-item-gap',
        defaultValue: 'var(--nav-item-gap)',
        type: 'text',
        name: 'item gap',
        tip: 'topnavbar item gap',
      },
      {
        variable: '--topnavbar-item-x-padding',
        defaultValue: 'var(--nav-item-x-padding)',
        type: 'text',
        name: 'item x-padding',
        tip: 'topnavbar item x-padding',
      },
      {
        variable: '--topnavbar-item-border-width',
        defaultValue: 'var(--nav-item-border-width)',
        type: 'text',
        name: 'item border width',
        tip: 'topnavbar item border width',
      },
      {
        variable: '--topnavbar-item-underline-width',
        defaultValue: 'var(--nav-item-underline-width)',
        type: 'text',
        name: 'item underline width',
        tip: 'topnavbar item underline width',
      },

      {
        variable: '--topnavbar-item-text-color',
        defaultValue: 'var(--navbar-text-color)',
        type: 'text',
        name: 'item text color',
        tip: 'topnavbar item text color',
      },
      {
        variable: '--topnavbar-item-background-color',
        defaultValue: 'var(--navbar-background-color)',
        type: 'text',
        name: 'item bg color',
        tip: 'topnavbar item background color',
      },
      {
        variable: '--topnavbar-item-underline-color',
        defaultValue: 'var(--navbar-background-color)',
        type: 'text',
        name: 'item underline color',
        tip: 'topnavbar item underline color',
      },
      {
        variable: '--topnavbar-item-border-color',
        defaultValue: 'var(--topnavbar-item-text-color)',
        type: 'text',
        name: 'item border color',
        tip: 'topnavbar item border color',
      },

      {
        variable: '--topnavbar-hover-text-color',
        defaultValue: 'var(--navbar-background-color)',
        type: 'text',
        name: 'hover text color',
        tip: 'topnavbar hover text color',
      },
      {
        variable: '--topnavbar-hover-underline-color',
        defaultValue: 'var(--topnavbar-hover-text-color)',
        type: 'text',
        name: 'hover underline color',
        tip: 'topnavbar hover underline color',
      },
      {
        variable: '--topnavbar-hover-background-color',
        defaultValue: 'var(--nav-hover-background-color)',
        type: 'text',
        name: 'hover bg color',
        tip: 'topnavbar hover background color',
      },
      {
        variable: '--topnavbar-hover-border-color',
        defaultValue: 'var(--topnavbar-hover-background-color)',
        type: 'text',
        name: 'hover border color',
        tip: 'topnavbar hover border color',
      },

      {
        variable: '--topnavbar-active-text-color',
        defaultValue: 'var(--navbar-background-color)',
        type: 'text',
        name: 'active text color',
        tip: 'topnavbar active text color',
      },
      {
        variable: '--topnavbar-active-underline-color',
        defaultValue: 'var(--topnavbar-active-text-color)',
        type: 'text',
        name: 'active underline color',
        tip: 'topnavbar active underline color',
      },
      {
        variable: '--topnavbar-active-background-color',
        defaultValue: 'var(--nav-active-background-color)',
        type: 'text',
        name: 'active bg color',
        tip: 'topnavbar active background color',
      },
      {
        variable: '--topnavbar-active-border-color',
        defaultValue: 'var(--topnavbar-active-background-color)',
        type: 'text',
        name: 'active border color',
        tip: 'topnavbar active border color',
      },
    ],
  },
  {
    title: 'Side Navigation',
    variables: [
      {
        variable: '--sidenavbar-display',
        defaultValue: 'block',
        type: 'select',
        values: ['block', 'none'],
        name: 'display',
        tip: `Sidenavbar display:
        "block" is show Sidenavbar.
        "none" is hide it.`,
      },
      {
        variable: '--sidenavbar-position',
        defaultValue: 'absolute',
        type: 'select',
        values: ['relative', 'absolute'],
        name: 'position',
        tip: `Sidenavbar behavior:
        "relative" means it resides side by side with content.
        "absolute" means it goes over the content.`,
      },
      {
        variable: '--sidenavbar-align-items',
        defaultValue: 'baseline',
        type: 'select',
        values: ['baseline', 'center', 'end'],
        name: 'align items',
        tip: 'Align items to the left, center or right of the Sidenavbar',
      },
      {
        variable: '--sidenavbar-width',
        defaultValue: '100%',
        type: 'text',
        name: 'width',
        tip: 'Width of the side navigation bar for screens smaller than 1200px',
      },
      {
        variable: '--sidenavbar-width-for-wide-screen',
        defaultValue: '10rem',
        type: 'text',
        name: 'width for wide screen',
        tip: 'Width of the side navigation bar for screens widder than 1200px',
      },
      {
        variable: '--sidenavbar-height',
        defaultValue: '100%',
        type: 'text',
        name: 'height',
        tip: 'sidenavbar height',
      },
      {
        variable: '--sidenavbar-y-padding',
        defaultValue: '1rem',
        type: 'text',
        name: 'y-padding',
        tip: 'sidenavbar y-padding',
      },
      {
        variable: '--sidenavbar-background-color',
        defaultValue: 'var(--ombrage-background)',
        type: 'text',
        name: 'background color',
        tip: 'sidenavbar background color',
      },
      {
        variable: '--sidenavbar-transition-duration',
        defaultValue: 'var(--layout-transition-duration)',
        type: 'text',
        name: 'transition time',
        tip: 'sidenavbar transition time',
      },

      {
        variable: '--sidenavbar-item-height',
        defaultValue: 'var(--nav-item-height)',
        type: 'text',
        name: 'item height',
        tip: 'sidenavbar item height',
      },
      {
        variable: '--sidenavbar-item-width',
        defaultValue: 'fit-content',
        type: 'text',
        name: 'item width',
        tip: 'sidenavbar item width',
      },
      {
        variable: '--sidenavbar-item-gap',
        defaultValue: 'var(--nav-item-gap)',
        type: 'text',
        name: 'item gap',
        tip: 'sidenavbar item gap',
      },
      {
        variable: '--sidenavbar-item-x-padding',
        defaultValue: 'var(--nav-item-x-padding)',
        type: 'text',
        name: 'item x-padding',
        tip: 'sidenavbar item x-padding',
      },
      {
        variable: '--sidenavbar-item-border-width',
        defaultValue: 'var(--nav-item-border-width)',
        type: 'text',
        name: 'item border width',
        tip: 'sidenavbar item border width',
      },
      {
        variable: '--sidenavbar-item-underline-width',
        defaultValue: 'var(--nav-item-underline-width)',
        type: 'text',
        name: 'item underline width',
        tip: 'sidenavbar item underline width',
      },

      {
        variable: '--sidenavbar-item-text-color',
        defaultValue: 'var(--text-color)',
        type: 'text',
        name: 'item text color',
        tip: 'sidenavbar item text color',
      },
      {
        variable: '--sidenavbar-item-underline-color',
        defaultValue: 'var(--content-background-color)',
        type: 'text',
        name: 'item underline color',
        tip: 'sidenavbar item underline color',
      },
      {
        variable: '--sidenavbar-item-background-color',
        defaultValue: 'var(--content-background-color)',
        type: 'text',
        name: 'item bg color',
        tip: 'sidenavbar item background color',
      },
      {
        variable: '--sidenavbar-item-border-color',
        defaultValue: 'var(--sidenavbar-item-text-color)',
        type: 'text',
        name: 'item border color',
        tip: 'sidenavbar item border color',
      },

      {
        variable: '--sidenavbar-hover-text-color',
        defaultValue: 'var(--content-background-color)',
        type: 'text',
        name: 'hover text color',
        tip: 'sidenavbar hover text color',
      },
      {
        variable: '--sidenavbar-hover-underline-color',
        defaultValue: 'var(--sidenavbar-hover-text-color)',
        type: 'text',
        name: 'hover underline color',
        tip: 'sidenavbar hover underline color',
      },
      {
        variable: '--sidenavbar-hover-background-color',
        defaultValue: 'var(--nav-hover-background-color)',
        type: 'text',
        name: 'hover bg color',
        tip: 'sidenavbar hover background color',
      },
      {
        variable: '--sidenavbar-hover-border-color',
        defaultValue: 'var(--sidenavbar-hover-background-color)',
        type: 'text',
        name: 'hover border color',
        tip: 'sidenavbar hover border color',
      },

      {
        variable: '--sidenavbar-active-text-color',
        defaultValue: 'var(--content-background-color)',
        type: 'text',
        name: 'active text color',
        tip: 'sidenavbar active text color',
      },
      {
        variable: '--sidenavbar-active-underline-color',
        defaultValue: 'var(--sidenavbar-active-text-color)',
        type: 'text',
        name: 'active underline color',
        tip: 'sidenavbar active underline color',
      },
      {
        variable: '--sidenavbar-active-background-color',
        defaultValue: 'var(--nav-active-background-color)',
        type: 'text',
        name: 'active bg color',
        tip: 'sidenavbar active background color',
      },
      {
        variable: '--sidenavbar-active-border-color',
        defaultValue: 'var(--sidenavbar-active-background-color)',
        type: 'text',
        name: 'active border color',
        tip: 'sidenavbar active border color',
      },
    ],
  },
  {
    title: 'Footer',
    variables: [
      {
        variable: '--footer-background-color',
        defaultValue: 'var(--color1)',
        type: 'text',
        name: 'background color',
        tip: 'footer background color',
      },
      {
        variable: '--footer-y-padding',
        defaultValue: '1rem',
        type: 'text',
        name: 'y-padding',
        tip: 'footer y-padding',
      },
      {
        variable: '--footer-section-padding',
        defaultValue: '0.5rem',
        type: 'text',
        name: 'section padding',
        tip: 'footer section padding',
      },
      {
        variable: '--footer-social-logo-size',
        defaultValue: '1.2rem',
        type: 'text',
        name: 'social logo size',
        tip: 'footer social logo size',
      },
      {
        variable: '--footer-text-color',
        defaultValue: 'var(--text-color)',
        type: 'text',
        name: 'text color',
        tip: 'footer text color',
      },
      {
        variable: '--footer-link-color',
        defaultValue: 'var(--text-color)',
        type: 'text',
        name: 'link color',
        tip: 'footer link color',
      },
      {
        variable: '--footer-link-hover-color',
        defaultValue: 'var(--link-hover-color)',
        type: 'text',
        name: 'link hover color',
        tip: 'footer link hover color',
      },
    ],
  },
  {
    title: 'Toolbars',
    variables: [
      {
        variable: '--toolbar-position',
        defaultValue: 'sticky',
        type: 'select',
        values: ['sticky', 'relative'],
        name: 'position',
        tip: 'Behavior of the toolbar position',
      },
      {
        variable: '--toolbar-top',
        defaultValue: '0',
        type: 'text',
        name: 'top',
        tip: 'Number of pixels from the top of its container',
      },
      {
        variable: '--toolbar-text-color',
        defaultValue: 'var(--text-color)',
        type: 'color',
        name: 'text color',
        tip: 'Color of the text inside the toolbar',
      },
      {
        variable: '--toolbar-border-color',
        defaultValue: 'var(--text-color)',
        type: 'color',
        name: 'border color',
        tip: 'Color of the borders inside the toolbar',
      },
      {
        variable: '--toolbar-hover-text-color',
        defaultValue: 'var(--content-background-color)',
        type: 'color',
        name: 'hovered text color',
        tip: 'Color of the text when hovered inside the toolbar',
      },
      {
        variable: '--toolbar-background-color',
        defaultValue: 'transparent',
        type: 'color',
        name: 'background color',
        tip: 'Color of the background of the toolbar',
      },
      {
        variable: '--toolbar-padding',
        defaultValue: '1rem',
        type: 'text',
        name: 'padding',
        tip: 'Padding of the toolbar',
      },
    ],
  },
  {
    title: 'Buttons',
    variables: [
      {
        variable: '--button-height',
        defaultValue: '2em',
        type: 'text',
        name: 'height',
        tip: 'button height',
      },
      {
        variable: '--button-padding-y',
        defaultValue: '0.3rem',
        type: 'text',
        name: 'padding y',
        tip: 'top and bottom button padding',
      },
      {
        variable: '--button-padding-x',
        defaultValue: '1rem',
        type: 'text',
        name: 'padding x',
        tip: 'left and right button padding',
      },
      {
        variable: '--button-border-width',
        defaultValue: 'var(--border-width)',
        type: 'text',
        name: 'border width',
        tip: 'button border width',
      },
      {
        variable: '--button-border-radius',
        defaultValue: 'var(--border-radius)',
        type: 'text',
        name: 'border radius',
        tip: 'button border radius',
      },
      {
        variable: '--button-font-name',
        defaultValue: 'var(--font-name)',
        type: 'text',
        name: 'font name',
        tip: 'button font name',
      },
      {
        variable: '--button-text-color',
        defaultValue: 'var(--blue)',
        type: 'text',
        name: 'text color',
        tip: 'button text color',
      },
      {
        variable: '--button-background-color',
        defaultValue: 'var(--content-background-color)',
        type: 'text',
        name: 'button bg color',
        tip: 'button background color',
      },
      {
        variable: '--button-border-color',
        defaultValue: 'var(--button-background-color)',
        type: 'text',
        name: 'border color',
        tip: 'button border color',
      },
      {
        variable: '--button-hover-text-color',
        defaultValue: 'var(--button-background-color)',
        type: 'text',
        name: 'hover text color',
        tip: 'text color on hover',
      },
      {
        variable: '--button-hover-border-color',
        defaultValue: 'var(--button-background-color)',
        type: 'text',
        name: 'hover border color',
        tip: 'border color on hover',
      },
      {
        variable: '--button-hover-background-color',
        defaultValue: 'var(--button-text-color)',
        type: 'text',
        name: 'hover bg color',
        tip: 'button background color on hover',
      },
      {
        variable: '--button-transition-duration',
        defaultValue: 'var(--element-transition-duration)',
        type: 'text',
        name: 'transition time',
        tip: 'button transition time',
      },
    ],
  },
  {
    title: 'Inputs',
    variables: [
      {
        variable: '--input-padding',
        defaultValue: '0.7rem',
        type: 'text',
        name: 'padding',
        tip: `Space around the text inside the input's field.
        Needed for the label to sit on top of the field`,
      },
      {
        variable: '--input-margin',
        defaultValue: '2rem',
        type: 'text',
        name: 'margin',
        tip: 'Space around the input\'s field',
      },
      {
        variable: '--input-text-color',
        defaultValue: 'var(--text-color)',
        type: 'text',
        name: 'text color',
        tip: 'Color of the input\'s texts',
      },
      {
        variable: '--input-background-color',
        defaultValue: 'var(--content-background-color)',
        type: 'text',
        name: 'background color',
        tip: 'Color of the input\'s background',
      },
      {
        variable: '--input-border-color',
        defaultValue: 'var(--gray5)',
        type: 'color',
        name: 'border color',
        tip: 'Color of the input\'s border',
      },
      {
        variable: '--input-shadow',
        defaultValue: 'var(--large-medium-shadow)',
        type: 'text',
        name: 'shadow ',
        tip: 'Shadow projected around the input',
      },
      {
        variable: '--input-message-size',
        defaultValue: '0.7em',
        type: 'text',
        name: 'message size',
        tip: 'Size of the message that can appear under the field',
      },
    ],
  },
  {
    title: 'Box Buttons',
    variables: [
      {
        variable: '--box-background-color',
        defaultValue: 'var(--layout-background-color)',
        type: 'color',
        name: 'box background color',
        tip: 'box background color',
      },

      {
        variable: '--box-padding',
        defaultValue: '1rem',
        type: 'text',
        name: 'box padding',
        tip: 'box padding around content',
      },
      {
        variable: '--box-gap-with-button',
        defaultValue: '3px',
        type: 'text',
        name: 'box gap with button',
        tip: 'separation between the box and the button',
      },
      {
        variable: '--box-gap-with-container',
        defaultValue: '1rem',
        type: 'text',
        name: 'box gap with container',
        tip: 'separation between the box and the container where the box button is placed',
      },
    ],
  },
  {
    title: 'Menu',
    variables: [
      {
        variable: '--menu-box-padding',
        defaultValue: '0.5rem',
        type: 'text',
        name: 'box padding',
        tip: 'Padding of the menu box',
      },
      {
        variable: '--menu-item-hover-color',
        defaultValue: 'var(--button-hover-background-color)',
        type: 'color',
        name: 'item hover color',
        tip: 'Color of the items when hovering',
      },
      {
        variable: '--menu-item-active-color',
        defaultValue: 'var(--primary)',
        type: 'color',
        name: 'item active color',
        tip: 'Color of the items when active',
      },
      {
        variable: '--menu-item-padding-x',
        defaultValue: '1rem',
        type: 'text',
        name: 'item padding x',
        tip: 'Left and right padding of the menu items',
      },
      {
        variable: '--menu-item-padding-y',
        defaultValue: '0.3rem',
        type: 'text',
        name: 'item padding y',
        tip: 'Top and bottom padding of the menu items',
      },
      {
        variable: '--menu-item-border-radius',
        defaultValue: 'var(--border-radius)',
        type: 'text',
        name: 'item border radius',
        tip: 'Radius of the corners\' menu items',
      },
    ],
  },
  {
    title: 'Modals',
    variables: [
      {
        variable: '--modal-background-color',
        defaultValue: 'var(--color1)',
        type: 'color',
        name: 'background color',
        tip: 'modal background color',
      },
      {
        variable: '--modal-shadow',
        defaultValue: 'var(--large-medium-shadow)',
        type: 'text',
        name: 'shadow',
        tip: 'Shadow projected around the modal',
      },
      {
        variable: '--modal-ombrage-background',
        defaultValue: 'var(--ombrage-background)',
        type: 'color',
        name: 'modal ombrage color',
        tip: 'Background around the modal, hidding the page content',
      },
      {
        variable: '--modal-ombrage-backdrop-filter',
        defaultValue: 'var(--backdrop-filter)',
        type: 'text',
        name: 'modal ombrage filter',
        tip: 'Filter used to create blur and saturate effect',
      },
    ],
  },

  {
    title: 'Other Elements',
    variables: [
      {
        variable: '--overflow-indicator-height',
        defaultValue: '1rem',
        type: 'text',
        name: 'overflow indicator height',
        tip: 'height of the indicator showing when the content is overflowing its container',
      },
      {
        variable: '--overflow-indicator-shadow',
        defaultValue: 'var(--small-shadow)',
        type: 'text',
        name: 'overflow indicator shadow',
        tip: 'Shadow of the indicator showing when the content is overflowing the box',
      },
      {
        variable: '--ombrage-background',
        defaultValue: 'rgba(229, 229, 234, 0.2)',
        type: 'color',
        name: 'ombrage background',
        tip: 'Background used to hide content when opening modal for example',
      },
      {
        variable: '--backdrop-filter',
        defaultValue: 'saturate(180%) blur(10px)',
        type: 'text',
        name: 'backdrop filter',
        tip: 'Apply effect as saturation or blur...',
      },
    ],
  },
];

const defaultTheme = {};
themeCategories.forEach((category) => {
  category.variables.forEach((parameter) => {
    defaultTheme[parameter.variable] = parameter.defaultValue;
  });
});

export default defaultTheme;
