import React from 'react';

function MinusIcon(props) {
  return (
    <svg {...props} width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#000000">
        <path d="M4,15 L26,15" />
      </g>
    </svg>
  );
}

export default MinusIcon;
