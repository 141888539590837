import React, { useEffect } from 'react';

import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import Layout from './Layout/Layout';
import routes from '../config/routes';

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return children;
};

function App() {
  const location = useLocation();

  const RouteComponents = routes.map((route) => (
    <Route
      exact={route.path === '/'}
      key={route.path}
      path={route.path}
      element={route.fullpage
        ? <route.children />
        : <Layout location={location}><route.children /></Layout>}
    />
  ));

  return (
    <ScrollToTop>
      <Routes location={location}>
        {RouteComponents}
        <Route element={<Navigate to="/" replace />} />
      </Routes>
    </ScrollToTop>
  );
}

export default App;
