import './exercisedeleteform.scss';
import React from 'react';

import PropTypes from 'prop-types';

import setCurrentExerciseAPI from '../../../../api/setCurrentExerciseAPI';
import setExercisesAPI from '../../../../api/setExercisesAPI';
import Button from '../../../../components/buttons/Button';
import SaveButton from '../../../../components/buttons/SaveButton';

function ExerciseDeleteForm({ closeModal, handleDisplayEditContent, userContext, className }) {
  const { user, setUser } = userContext;
  const exercise = user.sport.currentExercise;
  const exercises = JSON.parse(user.sport.exercises);

  const handleDeleteExercise = async () => {
    let isDeleted = false;
    delete exercises[exercise];
    const newExercises = JSON.stringify(exercises);
    const newCurrentExercise = Object.keys(exercises)[0];

    setUser((prevUser) => {
      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser.sport,
          currentExercise: newCurrentExercise,
          exercises: newExercises,
        },
      };
      return newUser;
    });

    isDeleted = await setExercisesAPI(user._id, newExercises, 'exercise deleted');

    if (isDeleted) {
      setCurrentExerciseAPI(user._id, newCurrentExercise);
    }

    return isDeleted;
  };

  return (
    <div className={`delete-exercise${className ? ` ${className}` : ''}`}>
      <div className="delete-exercise__content">
        You are about to delete &ldquo;
        {exercise}
        &rdquo; and all its data.
        <br />
        <br />
        Are you sure you want to proceed ?
        <br />
      </div>
      <div className="delete-exercise__buttons">
        <Button onClick={handleDisplayEditContent}>
          edit exercise
        </Button>
        <SaveButton
          className="delete-button"
          onClick={handleDeleteExercise}
          timeoutCallback={closeModal}
        >
          DELETE
        </SaveButton>
      </div>
    </div>
  );
}

ExerciseDeleteForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleDisplayEditContent: PropTypes.func.isRequired,
  userContext: PropTypes.shape({
    user: PropTypes.object,
    setUser: PropTypes.func,
  }).isRequired,
  className: PropTypes.string.isRequired,
};

export default ExerciseDeleteForm;
