import './finishworkoutmodal.scss';
import React from 'react';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import setExercisesAPI from '../../../../../api/setExercisesAPI';
import Button from '../../../../../components/buttons/Button';
import Modal from '../../../../../components/Modal/Modal';

function FinishWorkoutModal({ isOpen, setIsOpen, userContext, setsDone }) {
  const navigate = useNavigate();
  const { user, setUser } = userContext;
  const { currentExercise } = user.sport;
  const { day } = JSON.parse(user.sport.exercises)[currentExercise];
  const freeSet = !Number.isInteger(day);

  const handleFinishWorkout = () => {
    if (setsDone.at(-1) === 0) {
      setsDone.pop(); // No need for setSetsDone as are quitting the page and new render is not needed.
    }
    setUser((prevUser) => {
      const exercises = JSON.parse(prevUser.sport.exercises);
      const newExercises = JSON.stringify({
        ...exercises,
        [currentExercise]: {
          ...exercises[currentExercise],
          day: freeSet ? 'free-sets' : day + 1,
          history: [
            ...exercises[currentExercise].history,
            {
              time: Date.now(),
              day,
              restTime: parseFloat(exercises[currentExercise].restTime),
              addedWeight: parseFloat(exercises[currentExercise].addedWeight),
              bodyweight: parseFloat(prevUser.profile.bodyweight),
              sets: setsDone,
            },
          ],
        },
      });
      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser.sport,
          exercises: newExercises,
        },
      };

      setExercisesAPI(newUser._id, newExercises, 'new workout saved');

      return newUser;
    });
    navigate('/sport');
  };

  const handleContinueWorkout = () => setIsOpen(false);

  return (
    <Modal
      title="Finish workout"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="finish-workout-modal"
    >
      <div className="buttons-bar">
        <Button onClick={handleContinueWorkout}>
          Continue
        </Button>
        <Button onClick={handleFinishWorkout} className="finish-button">
          Finish
        </Button>
      </div>
    </Modal>
  );
}

FinishWorkoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setsDone: PropTypes.array.isRequired,
  userContext: PropTypes.shape({
    setUser: PropTypes.func.isRequired,
    user: PropTypes.shape({
      sport: PropTypes.shape({
        currentExercise: PropTypes.string.isRequired,
        exercises: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default FinishWorkoutModal;
