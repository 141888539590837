import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Input from '../Input';

function Checkbox({
  label,
  name,
  required,
  defaultChecked,
  invalid,
  message,
  messageColor,
  style,
  className,
  noborder,
  zIndex,
  id,
  ...inputAttributes
}) {
  const [newValue, setNewValue] = useState(defaultChecked);
  const handleNewValue = (event) => {
    setNewValue(event.target.checked);
  };

  return (
    <Input
      type="checkbox"
      label={label}
      name={name}
      required={required}
      checked={newValue}
      onChange={handleNewValue}
      invalid={invalid && !newValue}
      showMessage={invalid && !newValue}
      message={message || `${label} must be a checked`}
      messageColor={messageColor}
      style={style}
      className={className}
      noborder={noborder}
      zIndex={zIndex}
      id={id}
      {...inputAttributes}
    />
  );
}

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  invalid: PropTypes.bool,
  message: PropTypes.string,
  messageColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  className: PropTypes.string,
  noborder: PropTypes.bool,
  zIndex: PropTypes.number,
  id: PropTypes.string,
};
Checkbox.defaultProps = {
  label: 'checkbox',
  name: null,
  required: false,
  defaultChecked: false,
  invalid: false,
  message: null,
  messageColor: 'var(--red, red)',
  backgroundColor: '',
  textColor: '',
  style: {},
  className: '',
  noborder: null,
  zIndex: null,
  id: null,
};
export default Checkbox;
