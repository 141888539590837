/* eslint-disable max-len */
import './sport.scss';
import React, { useRef, useState, useContext, useLayoutEffect, useEffect, useCallback, useMemo } from 'react';

import { createPortal } from 'react-dom';

import BackButton from '../../../components/buttons/BackButton';
import Button from '../../../components/buttons/Button';
import Modal from '../../../components/Modal/Modal';
import Section from '../../../components/Section/Section';
import Toolbar from '../../../components/Toolbar/Toolbar';
import UserContext from '../../../contexts/UserContext';
import historyJS from '../../../lib/historyJS';
import ExerciseSimpleSettings from './ExerciseSimpleSettings/ExerciseSimpleSettings';
import ExerciseSimpleSettingsContainer from './ExerciseSimpleSettings/ExerciseSimpleSettingsContainer';
import HistogramContainer from './Histogram/HistogramContainer';
import HistoryList from './HistoryList';
import PeriodsBar from './PeriodsBar/PeriodsBar';
import PreferencesMenu from './PreferencesMenu/PreferencesMenu';
import SelectSetButton from './SelectSetButton';
import StartWorkoutButton from './StartWorkoutButton';
import TitleMenu from './TitleMenu/TitleMenu';

const MemoizedHistoryList = React.memo(HistoryList);

function getExerciseTonnage(exercise) {
  return exercise.history.map((entry) => {
    const addedWeight = parseFloat(entry?.addedWeight) || 0;
    const bodyweight = exercise.isBodyweight ? (parseFloat(entry?.bodyweight) || 0) : 0;
    const countRepsInsteadOfTonnage = (!exercise?.addedWeight && !exercise?.isBodyweight);
    const totalWeight = countRepsInsteadOfTonnage ? 1 : (addedWeight + bodyweight);
    return {
      ...entry,
      values: entry.sets.map((reps) => reps * totalWeight),
    };
  });
}

function Sport() {
  const { user, setUser } = useContext(UserContext);
  const exercises = useMemo(() => JSON.parse(user.sport.exercises), [user.sport.exercises]);
  const defaultExercise = Object.keys(exercises)[0];
  const currentExercise = user.sport.currentExercise || defaultExercise; // "pushups"...
  const selectedExercises = useMemo(() => user.sport.selectedExercises || [currentExercise], [currentExercise, user.sport.selectedExercises]);
  const showSelectedExercises = user.sport.showSelectedExercises || false;

  // Get histories to display
  const histories = useMemo(() => Object.keys(exercises)
    .filter((exerciseName) => (showSelectedExercises ? selectedExercises.includes(exerciseName) : exerciseName === currentExercise))
    .map((exerciseName) => ({
      name: exerciseName,
      color: exercises[exerciseName].color,
      data: getExerciseTonnage(exercises[exerciseName]),
    })), [currentExercise, exercises, selectedExercises, showSelectedExercises]);

  const sectionRef = useRef();
  function getInnerSectionWidth() {
    const sectionStyle = window.getComputedStyle(sectionRef.current);
    const sectionWidthAfterDOMIsLoaded = parseFloat(sectionStyle.width.replace('px', '').trim());
    const sectionXPadding = parseFloat(sectionStyle.paddingLeft.replace('px', '')) + parseFloat(sectionStyle.paddingLeft.replace('px', ''));
    return (sectionWidthAfterDOMIsLoaded - sectionXPadding);
  }
  const [sectionWidth, setSectionWidth] = useState();
  const setSectioWidthCallback = useCallback(() => {
    setSectionWidth(getInnerSectionWidth());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setSectioWidthCallback);
    return () => window.removeEventListener('resize', setSectioWidthCallback);
  }, [setSectioWidthCallback]);

  useLayoutEffect(() => {
    setSectioWidthCallback();
  }, [setSectioWidthCallback]);

  const [isStartModalOpen, setIsStartModalOpen] = useState(false);
  const openStartModal = useCallback(() => setIsStartModalOpen(true), []);

  const flatHistory = useMemo(() => historyJS.getFlatDataHistory(histories, 'none'), [histories]);

  const { period } = user.sport; // "one_week" | "one_month" | "three_months" | "one_year"
  const groupBy = JSON.parse(user.sport.grouping)[period]; // day | week | month
  const exercise = exercises[currentExercise];
  const showTooltip = user?.sport?.showTooltip ?? true;
  return (
    <main id="sport">
      <Toolbar colorizeButtons>
        <BackButton />
        <TitleMenu currentExercise={currentExercise} container={sectionRef.current} />
        <PreferencesMenu />
      </Toolbar>
      <Section ref={sectionRef}>
        <HistogramContainer
          height={200}
          period={period}
          groupBy={groupBy}
          histories={histories}
          dynamicColumns={user.sport.dynamicColumns}
          containerWidth={sectionWidth}
          showDates={user?.sport?.showDates ?? true}
          showTooltip={showTooltip}
          showAverage={user?.sport?.showAverage}
          showReps={!exercise?.addedWeight && !exercise?.isBodyweight}
        />
        <PeriodsBar />
        <MemoizedHistoryList
          history={flatHistory}
          histogramHeight={200}
          showDates={user?.sport?.showDates ?? true}
        />
      </Section>
      {createPortal(<Button className="sport-start-button inverse-colors" onClick={openStartModal}>Start</Button>, document.body)}
      <Modal
        title={currentExercise}
        isOpen={isStartModalOpen}
        setIsOpen={setIsStartModalOpen}
        className="start-exercise"
      >
        <ExerciseSimpleSettingsContainer userContext={{ user, setUser }}>
          <ExerciseSimpleSettings />
          <SelectSetButton />
          <StartWorkoutButton />
        </ExerciseSimpleSettingsContainer>
      </Modal>
    </main>
  );
}

export default Sport;
