import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Input from '../Input';

function HourTextInput({
  label,
  name,
  defaultValue,
  placeholder,
  required,
  message,
  messageColor,
  style,
  className,
  noborder,
  zIndex,
  id,
  ...inputAttributes
}) {
  const isNewHourFormatValid = (value) => /^(0?[1-9]|1[0-2]):(0[1-9]|[12345][0-9]):(0[0-9]|[12345][0-9])\s(am|AM|pm|PM)$/.test(value); // eslint-disable-line
  const [newHour, setNewHour] = useState(defaultValue);
  const [isNewHourInputValid, setIsNewHourInputValid] = useState(true);
  const handleNewHour = (event) => {
    setIsNewHourInputValid(true);
    setNewHour(event.target.value);
  };
  const handleNewHourFormatValidation = () => {
    setIsNewHourInputValid(isNewHourFormatValid(newHour) || newHour === '');
  };

  return (
    <Input
      type="text"
      label={label}
      name={name}
      required={required}
      placeholder={placeholder}
      value={newHour}
      onChange={handleNewHour}
      onBlur={handleNewHourFormatValidation}
      invalid={!isNewHourInputValid}
      showMessage={!isNewHourInputValid}
      message={message || `${label} format must be "hh:mm:ss AM/PM"`}
      messageColor={messageColor}
      style={style}
      className={className}
      noborder={noborder}
      zIndex={zIndex}
      id={id}
      {...inputAttributes}
    />
  );
}

HourTextInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  message: PropTypes.string,
  messageColor: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  className: PropTypes.string,
  noborder: PropTypes.bool,
  zIndex: PropTypes.number,
  id: PropTypes.string,
};
HourTextInput.defaultProps = {
  label: 'hour',
  name: null,
  defaultValue: '',
  placeholder: new Date().toLocaleTimeString('en-US'),
  required: false,
  message: null,
  messageColor: 'var(--red, red)',
  style: {},
  className: '',
  noborder: false,
  zIndex: null,
  id: null,
};
export default HourTextInput;
