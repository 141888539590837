/* eslint-disable no-console */
import fetchGraphQL from '../lib/fetchGraphQL';

/**
 * Save the workout current exercises.
 * @param {string} id User id.
 * @param {string} currentExercise Name of the current exercise.
 * @returns {boolean} Is successfully saved?
 */
async function setCurrentExerciseAPI(id, currentExercise) {
  if (typeof id !== 'string') return console.error('"id" must be a string');
  if (typeof currentExercise !== 'string') return console.error('"currentExercise" must be a string');

  return fetchGraphQL(`mutation { setCurrentExercise(
      _id: "${id}",
      currentExercise: "${currentExercise}"
    ) { matchedCount, modifiedCount, upsertedId, upsertedCount } }`)
    .then((response) => {
      if (response.modifiedCount === 1) console.log('current exercise saved');
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}

export default setCurrentExerciseAPI;
