/* eslint-disable no-console */
import fetchGraphQL from '../lib/fetchGraphQL';

/**
 * Save the user's theme.
 * @param {string} id The user id.
 * @param {string} theme The stringified theme object.
 * @returns {boolean} Is successfully saved?
 */
async function setThemeAPI(id, theme) {
  if (typeof id !== 'string') return console.error('"id" must be a string');
  if (typeof theme !== 'string') return console.error('"theme" must be a string');

  return fetchGraphQL(`mutation { setTheme(
      _id: "${id}",
      theme: ${JSON.stringify(theme)}
    ) { matchedCount, modifiedCount, upsertedId, upsertedCount } }`)
    .then((response) => {
      if (response.modifiedCount === 1) console.log('theme saved');
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}

export default setThemeAPI;
