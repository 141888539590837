import React from 'react';

import PropTypes from 'prop-types';

import TextInput from '../TextInput';

function EmailInput({
  label,
  name,
  defaultValue,
  placeholder,
  required,
  message,
  messageColor,
  style,
  className,
  noborder,
  zIndex,
  id,
  ...inputAttributes
}) {
  const EMAIL_ADDRESS_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; // eslint-disable-line max-len
  return (
    <TextInput
      type="text"
      label={label}
      name={name}
      required={required}
      placeholder={placeholder}
      pattern={EMAIL_ADDRESS_REGEX}
      message={message || `${label} format must be like "yourname@domain.com"`}
      messageColor={messageColor}
      style={style}
      className={className}
      noborder={noborder}
      zIndex={zIndex}
      id={id}
      {...inputAttributes}
    />
  );
}

EmailInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  message: PropTypes.string,
  messageColor: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  className: PropTypes.string,
  noborder: PropTypes.bool,
  zIndex: PropTypes.number,
  id: PropTypes.string,
};
EmailInput.defaultProps = {
  label: 'email',
  name: null,
  defaultValue: '',
  placeholder: 'yourname@domain.com',
  required: false,
  message: null,
  messageColor: 'var(--red, red)',
  style: {},
  className: null,
  noborder: false,
  zIndex: null,
  id: null,
};
export default EmailInput;
