import './histogramcontainer.scss';
import React, { useState, useLayoutEffect, useRef, useCallback } from 'react';

import PropTypes from 'prop-types';

import Histogram from '../../../../components/Histogram/Histogram';
import historyJS from '../../../../lib/historyJS';

const MemoizedHistogram = React.memo(Histogram);

function VerticalLine() {
  return (
    <div style={{
      height: 'var(--date-line-height)',
      width: 'var(--line-width, 1px)',
      backgroundColor: 'var(--border-color)',
    }}
    />
  );
}

function HistogramContainer({
  period = 'one_week',
  groupBy = 'day',
  histories = [{
    name: 'history',
    color: '',
    data: { values: [0, 0, 0, 0, 0], time: Date.now() },
  }],
  dynamicColumns = true,
  containerWidth = 100,
  showDates = false,
  showColors = true,
  showTooltip = true,
  showAverage = true,
  showReps = false,
  height = 200,
}) {
  const [middleTime, setMiddleTime] = useState(Date.now());
  const periodDuration = historyJS.getPeriodDuration(period); // Time in ms of the period duration
  const durationDays = periodDuration / (1000 * 60 * 60 * 24); // Number of days of the period
  const startTime = middleTime - periodDuration / 2;
  const endTime = middleTime + periodDuration / 2;
  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
  const startDate = new Date(startTime).toLocaleDateString('en-us', dateOptions);
  const middleDate = new Date(middleTime).toLocaleDateString('en-us', dateOptions);
  const endDate = new Date(endTime).toLocaleDateString('en-us', dateOptions);

  const flatHistories = historyJS.getFlatDataHistory(histories, groupBy);
  const totalTonnageInDuration = Math.round(historyJS.getTotalInDuration(flatHistories, startTime, endTime, 'values') * 10) / 10;
  const averageTonnagePerDay = Math.round(totalTonnageInDuration / durationDays);
  const totalRepsInDuration = historyJS.getTotalInDuration(flatHistories, startTime, endTime, 'sets');
  const averageRepsPerDay = Math.round(totalRepsInDuration / durationDays);

  const containerRef = useRef();
  const [updateMiddleTime, setUpdateMiddleTime] = useState(true);

  // Keep view centered on "middleTime" when "period" changes
  useLayoutEffect(() => {
    const containerElement = containerRef.current;
    const newScrollLeft = historyJS.getScrollLeftPosition(middleTime, period, groupBy, containerElement);
    const maxScrollLeft = containerElement.scrollWidth - containerWidth;
    if (newScrollLeft >= 0 && newScrollLeft <= maxScrollLeft) {
      /**
       * "middleTime" is within the acceptable range, so move scrollLeft to the current time
       * to keep it in the middle of the view screen
       */
      containerElement.scrollLeft = newScrollLeft;
      /**
       * Keep handleScrollHistory from setting a new middle time, as the goal here is to keep it unchanged
       */
      setUpdateMiddleTime(false);
    } else if (newScrollLeft < 0) {
      /**
       * New period/groupBy selection make "middleTime" before the first reachable date.
       * scrollLeft cannot be negative, so it will be 0 for the user to be the closest to its precedent view.
       * Now we need to update the middle date to match with scrollLeft = 0.
       */
      containerElement.scrollLeft = 0;
      const newMiddleTime = historyJS.getHistoriesTimelineStartTime(histories, period, groupBy) + periodDuration / 2;
      setMiddleTime(newMiddleTime);
    } else if (newScrollLeft > maxScrollLeft) {
      /**
       * New period/groupBy selection make "middleTime" after the last reachable date.
       * scrollLeft exceed maxScrollLeft, so we will go there for the user to be the closest to its precedent view.
       * Now we need to update the middle date to match with scrollLeft = maxScrollLeft.
       */
      containerElement.scrollLeft = maxScrollLeft;
      const newMiddleTime = historyJS.getTimelineEndTime(period, groupBy) - periodDuration / 2;
      setMiddleTime(newMiddleTime);
    }
    // We do not want middleTime as a dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    containerWidth,
    groupBy,
    histories,
    period,
    periodDuration,
    setMiddleTime,
    // middleTime, // We do not middleTime as a dependency because scroll changes middleTime
  ]);

  const handleScrollHistory = useCallback(() => {
    /**
   * Do not set middleTime if scroll goal was to keep the middleTime centered after a period change
   * Unless we are out of range and the view has to shift
   */
    if (!updateMiddleTime) {
      setUpdateMiddleTime(true); // Reinitialize value for the next scroll
      return;
    }
    // Avoid weird jumpings when scroll is bouncing on the edge
    const containerElement = containerRef.current;
    const isHistogramOverflowingRight = (containerElement.scrollWidth - containerElement.scrollLeft - containerWidth) < 0;
    if (isHistogramOverflowingRight) return;
    const isHistogramOverflowingLeft = containerElement.scrollLeft < 0;
    if (isHistogramOverflowingLeft) return;

    const newMiddleTime = historyJS.getTimelineEndTime(period, groupBy)
      - ((containerElement.scrollWidth - containerElement.scrollLeft - containerWidth / 2) * (periodDuration / containerWidth));

    setMiddleTime(newMiddleTime);
  }, [containerWidth, updateMiddleTime, groupBy, period, periodDuration, setMiddleTime]);

  const containerHeightWithDates = `calc(${height}px + var(--date-line-height) + var(--date-font-size))`;
  const containerHeightNoDates = `${height}px`;
  return (
    <div
      className="histogram-container"
      style={{ height: showDates ? containerHeightWithDates : containerHeightNoDates }}
    >
      <div className="histogram-info">
        <div className="histogram-total">
          {showReps
            ? `${totalRepsInDuration} reps`
            : `${totalTonnageInDuration} kg in ${totalRepsInDuration} reps`}
        </div>
        <div className={`histogram-average${showAverage ? ' show' : ' hide'}`}>
          {showReps
            ? `${averageRepsPerDay} reps par day on average`
            : `${averageTonnagePerDay} kg per day on average`}
        </div>
      </div>
      <div
        className="scroll-container"
        style={{ width: containerWidth, height }}
        ref={containerRef}
        onScroll={handleScrollHistory}
      >
        <MemoizedHistogram
          histories={histories}
          showingDays={durationDays}
          groupBy={groupBy}
          dynamicColumns={dynamicColumns}
          containerWidth={containerWidth}
          height={height}
          color="var(--text-color)"
          showColors={showColors}
          showTooltip={showTooltip}
          middleTime={showTooltip ? middleTime : null}
          // Optimize when tooltips are hidden, removing middleTime keeps from re-rendering on scroll
        />
      </div>
      <div className={`date-lines${showDates ? ' visible' : ' hidden'}`}>
        <VerticalLine />
        <VerticalLine />
        <VerticalLine />
      </div>
      <div className={`dates${showDates ? ' visible' : ' hidden'}`}>
        <div className="date left">{startDate}</div>
        <div className="date center">
          {
            groupBy === 'day' ? middleDate
              : groupBy === 'week' ? `week ${historyJS.getWeekNumber(middleTime)}`
                : groupBy === 'month' ? new Date(middleTime)
                  .toLocaleDateString('en-us', { month: 'short', timeZone: 'UTC' })
                  : ''
          }

        </div>
        <div className="date right">{endDate}</div>
      </div>
    </div>
  );
}

HistogramContainer.propTypes = {
  height: PropTypes.number,
  period: PropTypes.string,
  groupBy: PropTypes.string,
  histories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.shape({
        values: PropTypes.arrayOf(PropTypes.number),
        color: PropTypes.string,
        time: PropTypes.number,
      })),
    }),
  ),
  dynamicColumns: PropTypes.bool,
  containerWidth: PropTypes.number,
  showDates: PropTypes.bool,
  showColors: PropTypes.bool,
  showTooltip: PropTypes.bool,
  showAverage: PropTypes.bool,
  showReps: PropTypes.bool,
};

HistogramContainer.defaultProps = {
  height: 200,
  period: 'one_week',
  groupBy: 'day',
  histories: [{
    name: 'history',
    color: '',
    data: { values: [0, 0, 0, 0, 0], time: Date.now() },
  }],
  dynamicColumns: true,
  containerWidth: 100,
  showDates: false,
  showColors: true,
  showTooltip: true,
  showAverage: true,
  showReps: false,
};

export default HistogramContainer;
