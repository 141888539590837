import React from 'react';

import Button from './Button';
import DownIcon from '../svgs/DownIcon';

function DownButton(props) {
  return (
    <Button nopadding {...props}>
      <DownIcon />
    </Button>
  );
}

export default DownButton;
