import company from './company';
import importFonts from '../lib/importFonts';

// eslint-disable-next-line no-console
console.log('Initialisation...');

/* DOCUMENT TITLE */
document.querySelector('head > title').innerHTML = company.NAME;

/* APP SHORT NAME */
document.querySelector('head > meta[name=apple-mobile-web-app-title]').content = company.SHORT_NAME;
document.querySelector('head > meta[name=application-name]').content = company.SHORT_NAME;

/* META DESCRIPTION */
document.querySelector('head > meta[name=description]').content = company.DESCRIPTION;

/* FONT FAMILY */
const fontName = window.getComputedStyle(document.documentElement).getPropertyValue('--font-name').trim();
if (fontName !== 'Comfortaa') {
  importFonts(fontName);
}
