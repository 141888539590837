import './resetpasswordpin.scss';

import React, { useEffect } from 'react';

import propTypes from 'prop-types';

function ResetPasswordPin({ email, onSuccess, onFailure }) {
  const [pin, setPin] = React.useState(['', '', '', '', '', '']);

  useEffect(() => {
    if (pin.length === 6 && !pin.includes('')) {
      (async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/checkpin`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, pin: pin.join('') }),
          });
          if (response.status !== 200) throw response;
          const data = await response.json();
          onSuccess(data);
          setPin(['', '', '', '', '', '']);
        } catch (error) {
          console.error(error); // eslint-disable-line no-console
          onFailure();
        }
      })();
    }
  }, [pin, email, onSuccess, onFailure]);

  const handleDigit = (n) => async (e) => {
    let values = e.target.value.split('');
    if (values.length === 0) {
      values = [''];
    }
    setPin((prevPin) => {
      const newPin = prevPin.slice(); // Copy array
      for (let i = 0; i < values.length && i < pin.length; i += 1) {
        newPin[i + n] = values[i];
      }
      return newPin;
    });

    // Move to the next input only if user entered a new value and it's not the last pin.
    if (e?.target?.nextElementSibling && values[0]) {
      e.target.nextElementSibling.focus();
    }
  };

  const handleSelect = (e) => e.target.select();

  return (
    <div className="pin">
      {[0, 1, 2, 3, 4, 5].map((n) => (
        <input
          key={n}
          className="digit"
          value={pin[n]}
          onChange={handleDigit(n)}
          onSelect={handleSelect}
        />
      ))}
    </div>
  );
}

ResetPasswordPin.propTypes = {
  email: propTypes.string.isRequired,
  onSuccess: propTypes.func.isRequired,
  onFailure: propTypes.func.isRequired,
};

export default ResetPasswordPin;
