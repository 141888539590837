import './perioditem.scss';

import React from 'react';

import PropTypes from 'prop-types';

function PeriodItem({ value, children, period, onClick }) {
  return (
    <div
      role="button"
      tabIndex="0"
      value={value}
      onClick={onClick}
      onKeyPress={onClick}
      className={`period-item${period === value ? ' active' : ''}`}
    >
      {children}
    </div>
  );
}

PeriodItem.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PeriodItem;
