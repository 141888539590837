export default function convertCssUnitsToPx(cssValue, container = document.body) {
  if (!cssValue) return cssValue;

  const supportedUnits = {

    // Absolute sizes
    px: (value) => value,
    cm: (value) => value * 38,
    mm: (value) => value * 3.8,
    q: (value) => value * 0.95,
    in: (value) => value * 96,
    pc: (value) => value * 16,
    pt: (value) => value * 1.333333,

    // Relative sizes
    rem: (value) => value * parseFloat(getComputedStyle(document.documentElement).fontSize),
    em: (value) => value * parseFloat(getComputedStyle(container).fontSize),
    vw: (value) => value * (window.innerWidth / 100),
    vh: (value) => value * (window.innerHeight / 100),

    // Times
    ms: (value) => value,
    s: (value) => value * 1000,

    // Angles
    deg: (value) => value,
    rad: (value) => value * (180 / Math.PI),
    grad: (value) => value * (180 / 200),
    turn: (value) => value * 360,

  };

  // Match positive and negative numbers including decimals with following unit
  const pattern = new RegExp(`^([-+]?(?:\\d+(?:\\.\\d+)?))(${Object.keys(supportedUnits).join('|')})$`, 'i');

  // If is a match, return example: [ "-2.75rem", "-2.75", "rem" ]
  const matches = String.prototype.toString.apply(cssValue).trim().match(pattern);

  if (matches) {
    const value = Number(matches[1]);
    const unit = matches[2].toLocaleLowerCase();

    // Sanity check, make sure unit conversion function exists
    if (unit in supportedUnits) {
      return supportedUnits[unit](value);
    }
  }

  return cssValue;
}
