import './toolbar.scss';

import React from 'react';

import PropTypes from 'prop-types';

function Toolbar({
  colorizeButtons,
  style,
  className,
  children,
  ...otherProps
}) {
  return (
    <div
      style={style}
      className={`toolbar${colorizeButtons ? ' colored-buttons' : ''}${className ? ` ${className}` : ''}`}
      {...otherProps}
    >
      {children}
    </div>
  );
}

Toolbar.propTypes = {
  colorizeButtons: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  className: PropTypes.string,
  children: PropTypes.node,
};

Toolbar.defaultProps = {
  colorizeButtons: false,
  style: {},
  className: '',
  children: [],
};

export default Toolbar;
