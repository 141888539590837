/* eslint-disable no-console */
import fetchGraphQL from '../lib/fetchGraphQL';

/**
 * Save the graph's average tonnage visibility.
 * @param {string} id User Id.
 * @param {boolean} showAverage Is average tonnage shown?
 * @returns {boolean} Is successfully saved?
 */
async function setShowAverageAPI(id, showAverage) {
  if (typeof id !== 'string') return console.error('"id" must be a string');
  if (typeof showAverage !== 'boolean') return console.error('"showAverage" must be a boolean');

  return fetchGraphQL(`mutation { setShowAverage(
      _id: "${id}",
      showAverage: ${showAverage}
    ) { matchedCount, modifiedCount, upsertedId, upsertedCount } }`)
    .then((response) => {
      if (response.modifiedCount === 1) console.log('average visibility saved');
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}

export default setShowAverageAPI;
