import './sectionmodals.scss';
import React, { useState, useRef } from 'react';

import Button from '../../../components/buttons/Button';
import NextSection from '../../../components/buttons/NextSection';
import Modal from '../../../components/Modal/Modal';
import Section from '../../../components/Section/Section';
import Sidebar from '../../../components/Sidebar/Sidebar';
import useClickOutside from '../../../hooks/useClickOutside';

function SectionModals(props) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isGlobalModalOpen, setIsGlobalModalOpen] = useState(false);
  const [isLocalModalOpen, setIsLocalModalOpen] = useState(false);
  const [isLocalModalOpen2, setIsLocalModalOpen2] = useState(false);
  const modalSectionRef = useRef();

  useClickOutside({
    selectors: ['#modal-button', '.modal-box'],
    additionalCriteria: isGlobalModalOpen,
    action: () => setIsGlobalModalOpen(false),
  });

  useClickOutside({
    selectors: ['#sidebar-button', '#sidebar'],
    additionalCriteria: isSidebarOpen,
    action: () => setIsSidebarOpen(false),
  });

  useClickOutside({
    selectors: ['#modal-button', '.modal-box'],
    additionalCriteria: isGlobalModalOpen,
    action: () => setIsGlobalModalOpen(false),
  });

  useClickOutside({
    selectors: ['#sidebar-button', '#sidebar'],
    additionalCriteria: isSidebarOpen,
    action: () => setIsSidebarOpen(false),
  });

  const toggleSidebar = () => {
    setIsSidebarOpen((bool) => !bool);
  };
  const openGlobalModal = () => {
    setIsGlobalModalOpen(true);
  };
  const openLocalModal = () => {
    setIsLocalModalOpen(true);
  };
  const openLocalModal2 = () => {
    setIsLocalModalOpen2(true);
  };

  return (
    <Section
      ref={modalSectionRef}
      colorizeButtons
      {...props}
    >
      <h1> Side panel and modal box</h1>
      <div className="flex-container">
        <Button id="modal-button" onClick={openGlobalModal}>Open Global Modal Box</Button>
        <Modal
          title="Big Modal"
          isOpen={isGlobalModalOpen}
          setIsOpen={setIsGlobalModalOpen}
        >
          <div>
            <p>This modal use all the space available.</p>
            <p>You can click outside to close it.</p>
          </div>
        </Modal>
        <Button id="modal-button" onClick={openLocalModal}>Open Local Modal Box</Button>
        <Modal
          title="Local Modal"
          isOpen={isLocalModalOpen}
          setIsOpen={setIsLocalModalOpen}
          container={modalSectionRef.current}
        >
          <div>
            <p>This modal is contained within the Section.</p>
            <p>Clicking outside will not close it this time.</p>
          </div>
        </Modal>
        <Button id="modal-button" onClick={openLocalModal2}>Open Scrollable Modal Box</Button>
        <Modal
          title="Scrollable"
          isOpen={isLocalModalOpen2}
          setIsOpen={setIsLocalModalOpen2}
          container={modalSectionRef.current}
          style={{ '--button-background-color': 'var(--layout-background-color' }}
        >
          <div>
            <p>A lot of content here!</p>
            <p>.</p>
            <p>.</p>
            <p>.</p>
            <p>.</p>
            <p>.</p>
            <p>.</p>
            <p>.</p>
            <p>.</p>
            <p>.</p>
            <p>So the modal becomes scrollable</p>
          </div>
        </Modal>
        <Button id="sidebar-button" onClick={toggleSidebar}>
          {isSidebarOpen ? 'Close Sidebar =>' : 'Open Sidebar <='}
        </Button>
      </div>
      <NextSection />
      <Sidebar
        isOpen={isSidebarOpen}
        style={{ '--sidebar-width': '200px', borderLeft: '1px solid var(--gray)' }}
      >
        <div style={{ margin: '1rem' }}>
          <p>Click outside to close it</p>
          <p>You can add some contextual content here.</p>
          <p>Or maybe a few settings buttons :)</p>
        </div>
      </Sidebar>
    </Section>
  );
}

export default SectionModals;
