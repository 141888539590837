import React from 'react';

function LoginIcon(props) {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#000000" strokeWidth="1" fillRule="evenodd" fill="none" transform="translate(4.000000, 1.000000)">
        <polyline points="4 5.57946667 4 0 22 0 22 28 4 28 4 22.3542667" />
        <path d="M0,14 L18,14 M18,14 L10,6 M18,14 L10,22" strokeLinecap="square" />
      </g>
    </svg>
  );
}

export default LoginIcon;
