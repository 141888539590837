/* Import pages to set React routes */
import About from '../containers/pages/About/About';
import Components from '../containers/pages/Components/Components';
import Privacy from '../containers/pages/Privacy/Privacy';
import Sets from '../containers/pages/Sport/Sets/Sets';
import Sport from '../containers/pages/Sport/Sport';
import Theme from '../containers/pages/Theme/Theme';
import Welcome from '../containers/pages/Welcome/Welcome';

const routes = [
  {
    name: 'Welcome',
    path: '/',
    children: Welcome,
    sidenavbar: false,
    topnavbar: true,
    fullpage: false,
  },
  {
    name: 'Components',
    path: '/components',
    children: Components,
    sidenavbar: true,
    topnavbar: false,
    fullpage: false,
  },
  {
    name: 'Theme',
    path: '/theme',
    children: Theme,
    sidenavbar: true,
    topnavbar: false,
    fullpage: false,
  },
  {
    name: 'Sport',
    path: '/sport',
    children: Sport,
    sidenavbar: true,
    topnavbar: false,
    fullpage: false,
  }, {
    name: 'Sets',
    path: '/sets',
    children: Sets,
    sidenavbar: false,
    topnavbar: false,
    fullpage: true,
  },
  {
    name: 'About',
    path: '/about',
    children: About,
    sidenavbar: false,
    topnavbar: true,
    fullpage: false,
  },
  {
    name: 'Privacy',
    path: '/privacy',
    children: Privacy,
    sidenavbar: false,
    topnavbar: false,
    fullpage: false,
  },
  {
    name: 'Welcome',
    path: '*',
    children: Welcome,
    sidenavbar: false,
    topnavbar: false,
    fullpage: false,
  },
];

export default routes;
