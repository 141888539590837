import React from 'react';

function CrossIcon(props) {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      {...props}
    >
      <g stroke="#000000">
        <path d="M8,8 L22,22" />
        <path d="M8,22 L22,8" />
      </g>
    </svg>
  );
}

export default CrossIcon;
