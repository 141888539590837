import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Input from '../Input';

function TextInput({
  label,
  name,
  defaultValue,
  placeholder,
  required,
  pattern,
  message,
  messageColor,
  style,
  className,
  noborder,
  zIndex,
  id,
  ...inputAttributes
}) {
  const testNewTextFormatValid = (value) => pattern.test(value);
  const [newText, setNewText] = useState(defaultValue);
  const [isNewTextInputValid, setIsNewTextInputValid] = useState(true);
  const handleNewText = ({ target: { value } }) => {
    setIsNewTextInputValid(true);
    setNewText(value);
  };
  const handleNewTextFormatValidation = () => {
    setIsNewTextInputValid(testNewTextFormatValid(newText) || newText === '');
  };

  return (
    <Input
      type="text"
      label={label}
      name={name}
      required={required}
      placeholder={placeholder}
      value={newText}
      pattern={pattern}
      onChange={handleNewText}
      onBlur={handleNewTextFormatValidation}
      invalid={!isNewTextInputValid}
      showMessage={!isNewTextInputValid}
      message={message || 'Invalid entry'}
      messageColor={messageColor}
      style={style}
      className={className}
      noborder={noborder}
      zIndex={zIndex}
      id={id}
      {...inputAttributes}
    />
  );
}

TextInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  pattern: PropTypes.instanceOf(RegExp),
  message: PropTypes.string,
  messageColor: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  className: PropTypes.string,
  noborder: PropTypes.bool,
  zIndex: PropTypes.number,
  id: PropTypes.string,
};
TextInput.defaultProps = {
  label: 'text',
  name: null,
  defaultValue: '',
  placeholder: '',
  required: false,
  pattern: /.*/,
  message: null,
  messageColor: 'var(--red, red)',
  style: {},
  className: null,
  noborder: false,
  zIndex: null,
  id: null,
};
export default TextInput;
