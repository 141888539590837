import './cancelworkoutmodal.scss';
import React from 'react';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Button from '../../../../../components/buttons/Button';
import Modal from '../../../../../components/Modal/Modal';

function CancelWorkoutModal({ isOpen, setIsOpen }) {
  const navigate = useNavigate();
  const handleCancelWorkout = () => {
    navigate('/sport');
  };
  const handleContinueWorkout = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      title="Cancel workout"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="cancel-workout-modal"
    >
      <>
        <p>
          Be aware,
          <br />
          <br />
          this will cancel your workout.
        </p>
        <div className="buttons-bar">
          <Button onClick={handleCancelWorkout} className="cancel-button">
            Cancel
          </Button>
          <Button onClick={handleContinueWorkout}>
            Continue
          </Button>
        </div>
      </>
    </Modal>
  );
}

CancelWorkoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default CancelWorkoutModal;
