/* eslint-disable max-len */
import React from 'react';

function FacebookIcon(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 42 42"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <mask id="facebook-icon-mask">
          <path fill="#FFF" d="M28.9311892,41.8876216 L28.9311892,25.7033514 L34.3634595,25.7033514 L35.1768649,19.3960541 L28.9311892,19.3960541 L28.9311892,15.3692432 C28.9311892,13.5431351 29.4382703,12.2987027 32.0568649,12.2987027 L35.3967568,12.2972432 L35.3967568,6.65594595 C34.8191351,6.57908108 32.8365405,6.40735135 30.5299459,6.40735135 C25.7145405,6.40735135 22.4177838,9.3467027 22.4177838,14.7445946 L22.4177838,19.3960541 L16.9715676,19.3960541 L16.9715676,25.7033514 L22.4177838,25.7033514 L22.4177838,41.8876216 L2.40194595,41.8876216 C1.12767568,41.8876216 0.0953513514,40.8546486 0.0953513514,39.5808649 L0.0953513514,2.40194595 C0.0953513514,1.12783784 1.12783784,0.0953513514 2.40194595,0.0953513514 L39.5808649,0.0953513514 C40.8546486,0.0953513514 41.8872973,1.12783784 41.8872973,2.40194595 L41.8872973,39.5808649 C41.8872973,40.8548108 40.8544865,41.8876216 39.5808649,41.8876216 L28.9311892,41.8876216 Z" />
        </mask>
      </defs>
      <g mask="url(#facebook-icon-mask)">
        <rect x="0" y="0" width="42" height="42" fill="#3B5998" />
        <rect x="0" y="0" width="42" height="42" fill="#000" className="mask" />
      </g>
    </svg>
  );
}

export default FacebookIcon;
