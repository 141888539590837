import './menubutton.scss';
import React from 'react';

import PropTypes from 'prop-types';

import BoxButton from './BoxButton';

function MenuButton({
  className,
  closeOnClick = true,
  children,
  ...otherProps
}) {
  return (
    <BoxButton
      className={`menu-button${className ? ` ${className}` : ''}`}
      closeOnClick={closeOnClick}
      {...otherProps}
    >
      {children}
    </BoxButton>
  );
}

MenuButton.propTypes = {
  className: PropTypes.string,
  closeOnClick: PropTypes.bool,
  children: PropTypes.node,
};

MenuButton.defaultProps = {
  className: '',
  closeOnClick: true,
  children: [],
};

export default MenuButton;
