import React from 'react';

import BackButton from '../../../components/buttons/BackButton';
import FontMinusButton from '../../../components/buttons/FontMinusButton';
import FontPlusButton from '../../../components/buttons/FontPlusButton';
import Section from '../../../components/Section/Section';
import Toolbar from '../../../components/Toolbar/Toolbar';

function About() {
  return (
    <main>
      <Toolbar>
        <BackButton />
        <div style={{ width: '2rem' }} />
        <div className="center">About</div>
        <FontMinusButton isLocal />
        <FontPlusButton isLocal />
      </Toolbar>
      <Section style={{ minHeight: '100%' }}>
        <br />
      </Section>
      <br />
      <br />
    </main>
  );
}

export default About;
