import './input.scss';
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Button from '../../buttons/Button';
import CrossIcon from '../../svgs/CrossIcon';
import EyeClosed from '../../svgs/EyeClosed';
import EyeOpened from '../../svgs/EyeOpened';

function Input({
  type,
  label,
  value,
  checked, // Checkbox input only
  onChange,
  required,
  invalid,
  message,
  messageColor,
  showMessage,
  children,
  name,
  id,
  animate,
  noborder,
  style,
  backgroundColor,
  textColor,
  zIndex,
  className,
  onRemoveFile, // File input only
  ...inputAttributes
}) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePassword = () => setIsPasswordVisible((bool) => !bool);
  const getInputType = (inputType) => {
    switch (inputType) {
      case 'color':
        return 'text';
      case 'password':
        return isPasswordVisible ? 'text' : 'password';
      default:
        return inputType;
    }
  };

  return (
    <div
      className={`input animate${
        type ? ` input-${type}` : ''}${
        invalid ? ' invalid' : ''}${
        required ? ' required' : ''}${
        value || checked ? ' filled' : ''}${
        className ? ' ' : ''}${className || ''}`}
      style={zIndex ? { zIndex } : null}
    >
      <div
        className={`input-border${noborder ? ' no-border' : ''}`}
        style={{ backgroundColor, color: textColor, ...style }}
      >
        {label && (
          <label htmlFor={id}>
            <span>{label}</span>
          </label>
        )}
        <div className="input-container">
          <input
            name={name}
            id={id}
            type={getInputType(type)}
            spellCheck={false}
            style={type === 'color' ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {}}
            value={value}
            checked={checked}
            onChange={onChange}
            {...inputAttributes}
          />
          {type === 'password' && (
            <Button
              nopadding
              round
              name="oeil"
              onClick={togglePassword}
              className="right-button"
            >
              {isPasswordVisible ? <EyeOpened /> : <EyeClosed />}
            </Button>
          )}
          {type === 'file' && (
            <Button
              nopadding
              round
              name="remove-file"
              onClick={onRemoveFile}
              className="right-button"
            >
              <CrossIcon />
            </Button>
          )}
          {type === 'color' && (
            <div className="color-picker-wrapper">
              <input
                type="color"
                name={name}
                value={value || '#ffffff'}
                onChange={onChange}
              />
            </div>
          )}
          {children}
        </div>
        <div className={`input-message${showMessage ? ' show' : ' hidden'}`} style={{ color: messageColor }}>{message}</div>
      </div>
    </div>
  );
}

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  message: PropTypes.string,
  messageColor: PropTypes.string,
  showMessage: PropTypes.bool,
  children: PropTypes.node,
  name: PropTypes.string,
  id: PropTypes.string,
  animate: PropTypes.bool,
  noborder: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  zIndex: PropTypes.number,
  className: PropTypes.string,
  /** File input only */
  onRemoveFile: PropTypes.func,
};
Input.defaultProps = {
  type: 'text',
  label: '',
  value: undefined,
  checked: false,
  onChange: () => {},
  required: false,
  invalid: false,
  message: null,
  messageColor: 'var(--red, red)',
  showMessage: true,
  children: [],
  name: null,
  id: null,
  animate: false,
  noborder: false,
  style: null,
  backgroundColor: null,
  textColor: null,
  zIndex: null,
  className: null,
  /** File input only */
  onRemoveFile: () => { },
};
export default Input;
