/* eslint-disable no-console */
import fetchGraphQL from '../lib/fetchGraphQL';

/**
 * Save the list of selected exercises.
 * @param {string} id The user id.
 * @param {array} selectedExercises The selected exercises' list.
 * @returns {boolean} Is successfully saved?
 */
async function setSelectedExercisesAPI(id, selectedExercises) {
  if (typeof id !== 'string') return console.error('"id" must be a string');
  if (!Array.isArray(selectedExercises)) {
    return console.error(`"selectedExercises" must be an array. Instead got ${typeof selectedExercises} ${selectedExercises}.`);
  }

  return fetchGraphQL(`mutation { setSelectedExercises(
      _id: "${id}",
      selectedExercises: ${JSON.stringify(selectedExercises)}
    ) { matchedCount, modifiedCount, upsertedId, upsertedCount } }`)
    .then((response) => {
      if (response.modifiedCount === 1) console.log('selected exercises saved');
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}

export default setSelectedExercisesAPI;
