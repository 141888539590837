/* eslint-disable no-console */
import fetchGraphQL from '../lib/fetchGraphQL';

/**
 * Save the graph's columns behavior.
 * @param {string} id User Id.
 * @param {boolean} dynamicColumns Do columns automatically widen to use the space available?
 * @returns {boolean} Is successfully saved?
 */
async function setDynamicColumnsAPI(id, dynamicColumns) {
  if (typeof id !== 'string') return console.error('"id" must be a string');
  if (typeof dynamicColumns !== 'boolean') return console.error('"dynamicColumns" must be a boolean');

  return fetchGraphQL(`mutation { setDynamicColumns(
    _id: "${id}",
    dynamicColumns: ${dynamicColumns}
  ) { matchedCount, modifiedCount, upsertedId, upsertedCount } }`)
    .then((response) => {
      if (response.modifiedCount === 1) console.log('columns behavior saved');
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}

export default setDynamicColumnsAPI;
