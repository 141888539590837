import './welcome.scss';
import React from 'react';

import { useNavigate } from 'react-router-dom';

import BackButton from '../../../components/buttons/BackButton';
import Button from '../../../components/buttons/Button';
import FontMinusButton from '../../../components/buttons/FontMinusButton';
import FontPlusButton from '../../../components/buttons/FontPlusButton';
import Section from '../../../components/Section/Section';
import DownIcon from '../../../components/svgs/DownIcon';
import Toolbar from '../../../components/Toolbar/Toolbar';

function Welcome() {
  const navigate = useNavigate();
  const goToComponents = () => navigate('/components');
  return (
    <main id="welcome">
      <Toolbar
        style={{
          '--toolbar-text-color': 'var(--content-background-color)',
          '--toolbar-button-background-color': 'var(--primary)',
        }}
        colorizeButtons
      >
        <BackButton noresize />
        <div className="center" />
        <FontMinusButton isLocal />
        <FontPlusButton isLocal />
      </Toolbar>
      <Section
        style={{
          // backgroundImage: 'url(./welcome_background.jpg)',
          // backgroundSize: 'cover',
          '--section-text-color': 'var(--content-background-color)',
          '--section-background-color': 'var(--primary)',
          height: '100%',
        }}
        colorizeButtons
      >
        <h1 className="animation fadeIn1">React Template</h1>
        <div style={{ marginTop: 'auto' }} />
        <Button
          className="animation fadeIn2"
          style={{ flexDirection: 'column', alignItems: 'center' }}
          onClick={goToComponents}
        >
          <div>Continue</div>
          <div><DownIcon className="animation fadeIn3" /></div>
        </Button>
      </Section>
    </main>
  );
}

export default Welcome;
