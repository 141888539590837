/* eslint-disable no-console */
import fetchGraphQL from '../lib/fetchGraphQL';

/**
 * Save the grouping matrix { period: groupgBy }.
 * @param {string} id The user id.
 * @param {string} grouping The stringified grouping object matrix.
 * @returns {boolean} Is successfully saved?
 */
async function setGroupingAPI(id, grouping) {
  if (typeof id !== 'string') return console.error('"id" must be a string');
  if (typeof grouping !== 'string') return console.error('"grouping" must be a string');

  return fetchGraphQL(`mutation { setGrouping(
      _id: "${id}",
      grouping: ${JSON.stringify(grouping)}
    ) { matchedCount, modifiedCount, upsertedId, upsertedCount } }`)
    .then((response) => {
      if (response.modifiedCount === 1) console.log('grouping saved');
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}

export default setGroupingAPI;
