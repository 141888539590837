import React from 'react';

function RightIcon(props) {
  return (
    <svg {...props} width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#000000" transform="rotate(180) translate(-30 -30)">
        <path d="M6,15 L21,30" />
        <path d="M6,15 L21,0" />
      </g>
    </svg>
  );
}

export default RightIcon;
