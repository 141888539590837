import './exercisecompletesettingsform.scss';
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import setCurrentExerciseAPI from '../../../../api/setCurrentExerciseAPI';
import setExercisesAPI from '../../../../api/setExercisesAPI';
import Button from '../../../../components/buttons/Button';
import SaveButton from '../../../../components/buttons/SaveButton';
import CssInput from '../../../../components/CssInput/CssInput';
import Input from '../../../../components/inputs/Input';
import { isColor } from '../../../../lib/colors';

function ExerciseCompleteSettingsForm({ closeModal, handleDisplayDeleteContent, isNewExercise, userContext, className }) {
  const { user, setUser } = userContext;
  const exercise = user.sport.currentExercise;
  const exercises = JSON.parse(user.sport.exercises);

  const [newExerciseName, setNewExerciseName] = useState(isNewExercise ? '' : exercise);
  const [newExerciseNameIsValid, setNewExerciseNameIsValid] = useState(true);
  const [newExerciseNameMessage, setNewExerciseNameMessage] = useState('');
  const handleExerciseName = (event) => {
    const value = event?.target?.value;
    const nameAlreadyExists = exercises[value] && value !== exercise;
    if (nameAlreadyExists) {
      setNewExerciseNameIsValid(false);
      setNewExerciseNameMessage('this name already exists');
    } else if (value === '') {
      setNewExerciseNameIsValid(false);
      setNewExerciseNameMessage('name cannot be empty');
    } else {
      setNewExerciseNameIsValid(true);
      setNewExerciseNameMessage('');
    }
    setNewExerciseName(value);
  };

  const [newCategoriesList, setNewCategoriesList] = useState(isNewExercise ? '' : exercises[exercise]?.categories?.toString() ?? '');
  const [newCategoriesListIsValid/* , setNewCategoriesListIsValid */] = useState(true);
  const [newCategoriesListMessage/* , setNewCategoriesListMessage */] = useState('');
  const handleNewCategoriesList = (event) => {
    const value = event?.target?.value;
    setNewCategoriesList(value);
  };

  const [newColor, setNewColor] = useState(isNewExercise ? '' : exercises[exercise]?.color?.toString() ?? '');
  const [newColorIsValid, setNewColorIsValid] = useState(true);
  const [newColorMessage, setNewColorMessage] = useState('');
  const handleNewColor = (event) => {
    const value = event?.target?.value;
    if (!isColor(value) && value !== '') {
      setNewColorIsValid(false);
      setNewColorMessage('color is not valid');
    } else {
      setNewColorIsValid(true);
      setNewColorMessage('');
    }
    setNewColor(value);
  };

  const [newRestTime, setNewRestTime] = useState(isNewExercise ? '' : exercises[exercise]?.restTime?.toString() ?? '');
  const [newRestTimeIsValid, setNewRestTimeIsValid] = useState(true);
  const [newRestTimeMessage, setNewRestTimeMessage] = useState('');
  const handleRestTime = (event) => {
    const value = event?.target?.value;
    if (value === '') {
      setNewRestTimeIsValid(false);
      setNewRestTimeMessage('rest time cannot be empty');
    } else if (!/^\d+$/.test(value)) {
      setNewRestTimeIsValid(false);
      setNewRestTimeMessage('rest time must be a number');
    } else {
      setNewRestTimeIsValid(true);
      setNewRestTimeMessage('');
    }
    setNewRestTime(value);
  };

  const [newAddedWeight, setNewAddedWeight] = useState(isNewExercise ? '' : exercises[exercise]?.addedWeight?.toString() ?? '');
  const [newAddedWeightIsValid, setNewAddedWeightIsValid] = useState(true);
  const [newAddedWeightMessage, setNewAddedWeightMessage] = useState('');
  const handleWeight = (event) => {
    const value = event?.target?.value;
    if (value === '') {
      setNewAddedWeightIsValid(false);
      setNewAddedWeightMessage('added weight cannot be empty');
    } else if (!/^\d+$/.test(value)) {
      setNewAddedWeightIsValid(false);
      setNewAddedWeightMessage('added weight must be a number');
    } else {
      setNewAddedWeightIsValid(true);
      setNewAddedWeightMessage('');
    }
    setNewAddedWeight(value);
  };

  const [isBodyweight, setIsBodyweight] = useState(isNewExercise ? false : !!exercises[exercise]?.isBodyweight);
  const handleBodyweightExercise = (event) => {
    setIsBodyweight(event?.target?.checked);
  };

  const saveIsDisabled = !newExerciseNameIsValid || newExerciseName === ''
    || !newRestTimeIsValid || newRestTime === ''
    || !newAddedWeightIsValid || newAddedWeight === '';

  const handleSaveExercise = async () => {
    if (saveIsDisabled) return null;

    let newExercisesObj;
    setUser((prevUser) => {
      const prevExercises = JSON.parse(prevUser.sport.exercises);
      const newCategoriesArray = newCategoriesList.split(',').map((cat) => cat.trim());

      if (!isNewExercise) { // Modifying an existing exercise
        const newExercise = {
          ...prevExercises[exercise],
          categories: newCategoriesArray,
          color: newColor,
          restTime: parseFloat(newRestTime),
          addedWeight: parseFloat(newAddedWeight),
          isBodyweight,
        };
        newExercisesObj = {
          ...prevExercises,
          [newExerciseName]: newExercise,
        };
        if (newExerciseName !== exercise) {
          delete newExercisesObj[exercise];
        }
      } else { // Creating a new exercise
        newExercisesObj = {
          ...prevExercises,
          [newExerciseName]: {
            day: 1,
            categories: newCategoriesArray,
            color: newColor,
            restTime: parseFloat(newRestTime),
            addedWeight: parseFloat(newAddedWeight),
            isBodyweight,
            history: [],
          },
        };
      }

      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser.sport,
          currentExercise: newExerciseName,
          exercises: JSON.stringify(newExercisesObj),
        },
      };
      return newUser;
    });

    if (navigator.onLine && !!localStorage.getItem('token')) {
      const isExerciseSaved = setExercisesAPI(user._id, JSON.stringify(newExercisesObj), 'exercise saved');

      let isCurrentExerciseSaved;
      if (newExerciseName !== exercise) {
        isCurrentExerciseSaved = setCurrentExerciseAPI(user._id, newExerciseName);
      }

      return !!Promise.all([isExerciseSaved, isCurrentExerciseSaved]);
    }

    return true;
  };

  return (
    <div className={`edit-exercise${className ? ` ${className}` : ''}`}>
      <div className="edit-exercise__content">
        <Input
          label="name"
          placeholder="dips"
          type="text"
          onChange={handleExerciseName}
          animate
          required
          value={newExerciseName}
          invalid={!newExerciseNameIsValid}
          message={newExerciseNameMessage}
          messageColor="var(--red, red)"
        />
        <Input
          label="categories"
          placeholder="triceps, pecs"
          type="text"
          onChange={handleNewCategoriesList}
          animate
          value={newCategoriesList}
          invalid={!newCategoriesListIsValid}
          message={newCategoriesListMessage}
          messageColor="var(--red, red)"
        />
        <CssInput
          label="color"
          placeholder="#565763"
          type="color"
          onChange={handleNewColor}
          animate
          value={newColor}
          invalid={!newColorIsValid}
          message={newColorMessage}
          messageColor="var(--red, red)"
        />
        <Input
          label="rest time"
          placeholder="120"
          type="text"
          onChange={handleRestTime}
          animate
          required
          value={newRestTime}
          invalid={!newRestTimeIsValid}
          message={newRestTimeMessage}
          messageColor="var(--red, red)"
        />
        <Input
          label="added weight"
          placeholder="10"
          type="text"
          onChange={handleWeight}
          animate
          required
          value={newAddedWeight}
          invalid={!newAddedWeightIsValid}
          message={newAddedWeightMessage}
          messageColor="var(--red, red)"
        />
        <Input label="bodyweight exercise" type="checkbox" checked={isBodyweight} onChange={handleBodyweightExercise} />
      </div>
      <div className="edit-exercise__buttons">
        {!isNewExercise && (
          <Button
            onClick={handleDisplayDeleteContent}
            style={{ '--button-text-color': 'var(--red, red)', '--button-hover-background-color': 'var(--red, red)' }}
          >
            delete
          </Button>
        )}
        <SaveButton
          className="save-button"
          disabled={saveIsDisabled}
          onClick={handleSaveExercise}
          timeoutCallback={closeModal}
        >
          save
        </SaveButton>
      </div>
    </div>
  );
}

ExerciseCompleteSettingsForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleDisplayDeleteContent: PropTypes.func.isRequired,
  isNewExercise: PropTypes.bool,
  userContext: PropTypes.shape({
    user: PropTypes.object,
    setUser: PropTypes.func,
  }).isRequired,
  className: PropTypes.string.isRequired,
};

ExerciseCompleteSettingsForm.defaultProps = {
  isNewExercise: false,
};

export default ExerciseCompleteSettingsForm;
