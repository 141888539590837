/* eslint-disable max-len */
import React from 'react';

function TwitterIcon(props) {
  const name = 'twitter-icon';
  const color = '#3CF';
  const width = 42;
  const height = 42;
  return (
    <svg {...props} id={name} width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <mask id={`${name}-mask`}>
          <path fill="#FFF" d="M13.2606278,34.0461828 C29.005081,34.0461828 37.6145056,21.0027118 37.6145056,9.6923078 C37.6145056,9.3218286 37.6069596,8.953021 37.5903416,8.5858808 C39.2616602,7.3776584 40.714366,5.8698822 41.8600084,4.1535032 C40.3263658,4.835215 38.6759072,5.2941406 36.944509,5.5010746 C38.7117864,4.441374 40.0685334,2.765049 40.7085252,0.76664 C39.0547276,1.7470698 37.2232014,2.4596558 35.273189,2.844317 C33.7111768,1.1805066 31.4874784,0.14 29.0251402,0.14 C24.2982166,0.14 20.4649438,3.9732728 20.4649438,8.6985262 C20.4649438,9.3702238 20.5400398,10.0235674 20.686897,10.650206 C13.5727326,10.2922456 7.2646028,6.8861912 3.0433326,1.7070186 C2.3082178,2.9719816 1.8843398,4.441374 1.8843398,6.009227 C1.8843398,8.9788874 3.3954522,11.6005988 5.6934122,13.1342414 C4.2891044,13.0908526 2.969904,12.7053542 1.8167506,12.063695 C1.8154906,12.0996078 1.8154906,12.1346204 1.8154906,12.1730028 C1.8154906,16.3183426 4.7659598,19.7794688 8.6826726,20.5638118 C7.9634128,20.7598986 7.2066036,20.865033 6.4255982,20.865033 C5.8748886,20.865033 5.3383638,20.8108306 4.8168596,20.710669 C5.9065958,24.111717 9.0664994,26.5865712 12.8129946,26.6558292 C9.8833854,28.952119 6.1927978,30.319716 2.1817978,30.319716 C1.4917406,30.319716 0.8100302,30.2804656 0.14,30.2012284 C3.9282152,32.629359 8.4265104,34.0461828 13.2610702,34.0461828" transform="translate(0 4)" />
        </mask>
      </defs>
      <g mask={`url(#${name}-mask)`}>
        <rect x="0" y="0" width={width} height={height} fill={color} />
        <rect x="0" y="0" width={width} height={height} fill="#000" className="mask" />
      </g>
    </svg>
  );
}

export default TwitterIcon;
