import React from 'react';

import PropTypes from 'prop-types';

import Button from './Button';
import changeFontSize from '../../lib/changeFontSize';
import getContainerElement from '../../lib/getContainerElement';
import PlusIcon from '../svgs/PlusIcon';

function FontPlusButton({
  isLocal,
  element = document.documentElement,
  ...otherProps
}) {
  const increaseFontSize = (event) => {
    try {
      if (isLocal) {
        changeFontSize(1, getContainerElement(event.target.parentNode));
      }
      if (!isLocal || (isLocal && element !== document.documentElement)) {
        changeFontSize(1, element);
      }
    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  };

  return (
    <Button
      aria-label="Increase font size"
      nopadding
      onClick={increaseFontSize}
      {...otherProps}
    >
      <PlusIcon />
    </Button>
  );
}

FontPlusButton.propTypes = {
  isLocal: PropTypes.bool,
  element: PropTypes.instanceOf(Element),
};

FontPlusButton.defaultProps = {
  isLocal: false,
  element: document.documentElement,
};

export default FontPlusButton;
