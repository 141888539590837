import './document.scss';

import React, { useLayoutEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import CrossButton from '../buttons/CrossButton';
import FontMinusButton from '../buttons/FontMinusButton';
import FontPlusButton from '../buttons/FontPlusButton';

function Document({
  isOpen = true,
  close,
  theme = 'dark',
  children,
  className,
  style,
}) {
  const documentRef = useRef();
  const [documentHeight, setDocumentHeight] = useState(style?.height || 'auto');
  useLayoutEffect(() => {
    setDocumentHeight(`${documentRef.current.offsetHeight}px`);
  }, []);

  const articleRef = useRef();
  const articleElement = articleRef.current;
  return (
    <div
      ref={documentRef}
      className={`document ${theme}${isOpen ? ' is-open' : ' is-closed'}${className ? ` ${className}` : ''}`}
      style={{ height: isOpen ? documentHeight : 0, ...style }}
    >
      <div className="top-buttons">
        <FontMinusButton element={articleElement} noresize />
        <FontPlusButton element={articleElement} noresize />
        <CrossButton aria-label="Close document" noresize onClick={close} />
      </div>
      <article ref={articleRef}>
        {children}
      </article>
      <Button
        aria-label="Close document"
        noresize
        className="close-button"
        onClick={close}
      >
        Close
      </Button>
    </div>
  );
}

Document.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  close: PropTypes.func,
  theme: PropTypes.string,
  isOpen: PropTypes.bool,
};

Document.defaultProps = {
  children: [],
  className: '',
  style: {},
  close: () => {},
  theme: 'dark',
  isOpen: true,
};

export default Document;
