import React from 'react';

function Triangle(props) {
  return (
    <svg {...props} width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#000000">
        <path d="M0,0 L30,0 L15,25.98 L0,0" />
      </g>
    </svg>
  );
}

export default Triangle;
