import './nextsection.scss';

import React from 'react';

import PropTypes from 'prop-types';

import DownButton from './DownButton';

function NextSection(props) {
  const { className, ...otherProps } = props;

  const scrollToNextSection = (element) => {
    if (!element.classList.contains('section')) {
      scrollToNextSection(element.parentElement);
    } else if (element.nextSibling.classList.contains('section')) {
      document.getElementsByClassName('main-container')[0]
        .scrollTo({ top: element.offsetTop + element.offsetHeight, behavior: 'smooth' });
    }
  };

  return (
    <DownButton
      aria-label="Continue to Next Section"
      className={`next-section${className ? ` ${className}` : ''}`}
      onClick={(event) => scrollToNextSection(event.target)}
      {...otherProps}
    />
  );
}

NextSection.propTypes = {
  className: PropTypes.string,
};

NextSection.defaultProps = {
  className: '',
};

export default NextSection;
