import './layout.scss';
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Footer from './Footer';
import Navbar from './Navbar';
import Sidenavbar from './Sidenavbar';
import useClickOutside from '../../hooks/useClickOutside';

function Layout({ children }) {
  const [isSidebarOpen, setSidebar] = useState(false);

  useClickOutside({
    selectors: ['#hamburger'],
    additionalCriteria: isSidebarOpen,
    action: () => setSidebar(false),
  });

  const handleToggle = () => setSidebar(!isSidebarOpen);

  return (
    <>
      <Navbar
        handleToggle={handleToggle}
        isHamburgerOpen={isSidebarOpen}
      />
      <div className="content">
        <Sidenavbar id="sidenavbar" isOpen={isSidebarOpen} />
        <div className="main-container">
          {children}
          <Footer />
        </div>
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: [],
};

export default Layout;
