import './theme.scss';
import React, { useState } from 'react';

// import getColors from 'get-image-colors';

import setThemeAPI from '../../../api/setThemeAPI';
import BackButton from '../../../components/buttons/BackButton';
import BoxButton from '../../../components/buttons/BoxButton';
import Button from '../../../components/buttons/Button';
import FontMinusButton from '../../../components/buttons/FontMinusButton';
import FontPlusButton from '../../../components/buttons/FontPlusButton';
import SaveButton from '../../../components/buttons/SaveButton';
// import Input from '../../../components/inputs/Input';
import Section from '../../../components/Section/Section';
import Toolbar from '../../../components/Toolbar/Toolbar';
import { themeCategories } from '../../../contexts/defaultTheme';
import UserContext from '../../../contexts/UserContext';
import { isColor, hexToRGBValues /* colorBrightness */ } from '../../../lib/colors';
import importFonts from '../../../lib/importFonts';
// import readImage from '../../../lib/readImage';
import CssInputs from './CssInputs';
import ResetModal from './ResetModal';

function Theme() {
  const { user, setUser } = React.useContext(UserContext);

  const handleSave = async () => {
    if (navigator.onLine && !!localStorage.getItem('token')) {
      return setThemeAPI(user._id, user.theme);
    }
    return true;
  };

  const handleCssChange = (event) => {
    const { name: variable, value = '' } = event.target; // Element's name = CSS variable name = --color1

    if (`${variable}`.includes('-color') && isColor(value)) {
      document.documentElement.style.setProperty(variable, value);
      document.documentElement.style.setProperty(`${variable}--rgb`, hexToRGBValues(value).toString());
    }
    if (!`${variable}`.includes('-color')) {
      document.documentElement.style.setProperty(variable, value);
    }

    setUser((prevUser) => {
      const newTheme = { ...JSON.parse(prevUser.theme), [variable]: value };
      const newUser = { ...prevUser, theme: JSON.stringify(newTheme) };
      return newUser;
    });
    // Import font from google api
    if (variable === '--font-name') {
      importFonts(value || window.getComputedStyle(document.documentElement).getPropertyValue(variable));
    }
  };

  /** GET COLORS FROM PICTURE
  const [previewSrc, setPreviewSrc] = React.useState('');
  const [colors, setColors] = React.useState([]);
  const handleImage = (event) => {
    const setPreviewAndAverageColor = (b64Image) => {
      const image = new Image();
      image.onload = () => {
        getColors(b64Image).then(colors => setColors(colors));
        setPreviewSrc(b64Image);
      };
      image.src = b64Image;
    }

    readImage(event.target.files[0], setPreviewAndAverageColor);
  }

  const handleRemoveImage = () => {
    setPreviewSrc('');
    setColors([]);
  }
  */

  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const openResetModal = () => setIsResetModalOpen(true);

  return (
    <main id="theme">
      <Toolbar>
        <BackButton />
        <div className="center" />
        <FontMinusButton isLocal />
        <FontPlusButton isLocal />
        <BoxButton round nopadding notriangle label="?" boxStyle={{ width: '400px' }}>
          <h3>Find new font&apos;s name</h3>
          <p>
            {'Search for new fonts by going to '}
            <a href="https://fonts.google.com/" target="_blank" rel="noopener noreferrer">Google Fonts</a>
          </p>
          <h3>Use theme variables</h3>
          <p>
            The parameters&apos; names are css variables&apos;.
            So you can use them for theming by replacing spaces with dashes!
          </p>
          <i>e.g. use &quot;var(--primary)&quot; in one of the cutomization field.</i>
          <h3>Looking for colorful inspiration?</h3>
          <p>
            <a href="http://colormind.io/bootstrap/" target="_blank" rel="noopener noreferrer">Colormind.io</a>
            {' '}
            will help you visualize your ideas.
          </p>
        </BoxButton>
        <Button onClick={openResetModal}>Reset</Button>
        <ResetModal isOpen={isResetModalOpen} setIsOpen={setIsResetModalOpen} />
        {!!localStorage.getItem('token') && <SaveButton onClick={handleSave} />}
      </Toolbar>
      <Section>
        <div className="theme-container">
          {/* Deactivate Colors from picture feature
          <div>
            <h1>Colors from Picture</h1>
            <Input type="file" accept="image/*" label="picture" name="picture"
              onChange={handleImage}
              onRemoveFile={handleRemoveImage}
            />
            <div className="colors">
              {previewSrc && <img src={previewSrc} alt="" />}
              <div>
                {colors.map(color => {
                  const hexValue = color.hex();
                  return (<Input readOnly type="text" label="" name="color" key={hexValue}
                    value={hexValue}
                    backgroundColor={hexValue}
                    className={colorBrightness(hexValue)}
                  />);
                })}
              </div>
            </div>
          </div>
          */}
          {themeCategories.map((category) => (
            <div key={category.title} className="theme-category">
              <h1 className="theme-category-title">{category.title}</h1>
              <CssInputs onChange={handleCssChange} cssVariables={category.variables} />
            </div>
          ))}
        </div>
      </Section>
    </main>
  );
}

export default Theme;
