import './profileform.scss';
import React, { useState, useContext, useCallback } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ResetPasswordPin from './ResetPasswordPin';
import SaveButton from '../../../components/buttons/SaveButton';
import Input from '../../../components/inputs/Input';
import ProfileIcon from '../../../components/svgs/ProfileIcon';
import defaultUser, { version } from '../../../contexts/defaultUser';
import UserContext from '../../../contexts/UserContext';
import fetchGraphQL from '../../../lib/fetchGraphQL';
import readImage from '../../../lib/readImage';

function ProfileForm({ closeModal, isResetPasswordValidated }) {
  const { user, setUser } = useContext(UserContext);

  const [usernameAlreadyTaken, setUsernameAlreadyTaken] = useState(false);
  const isLoggedIn = !!localStorage.getItem('token');
  const getFormActionType = () => {
    if (isLoggedIn) {
      return 'update';
    }
    if (isResetPasswordValidated) {
      return 'reset';
    }
    return 'login';
  };
  const [action, setAction] = useState(getFormActionType());
  // eslint-disable-next-line no-shadow
  const controlUsername = async (username) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      body: JSON.stringify({ query: `query { getUsernameId(username: "${username}") }` }),
    });
    if (response.status === 200) {
      const { data } = await response.json();
      if (action === 'update') {
        setUsernameAlreadyTaken(data?.getUsernameId && data.getUsernameId !== user._id);
      } else if (action === 'signup') {
        setUsernameAlreadyTaken(data?.getUsernameId && !!data.getUsernameId);
      }
    }
  };

  const [username, setUsername] = useState(user?.profile?.username || '');
  const handleUsername = (event) => {
    const value = event?.target?.value;
    setUsername(value);
    if (action !== 'login') {
      controlUsername(value);
    } else if (action === 'login' && usernameAlreadyTaken) {
      setUsernameAlreadyTaken(false);
    }
  };

  const [email, setEmail] = useState(user?.profile?.email || '');
  const [emailIsValid, setEmailIsValid] = useState(true);
  const handleEmail = (event) => {
    setEmailIsValid(true);
    setEmail(event?.target?.value);
  };
  const EMAIL_ADDRESS_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; // eslint-disable-line max-len
  const handleEmailValidation = () => setEmailIsValid(EMAIL_ADDRESS_REGEX.test(email));

  const [bodyweight, setBodyweight] = useState(user?.profile?.bodyweight?.toString() || '');
  const [bodyweightIsValid, setBodyweightIsValid] = useState(true);
  const handleBodyweight = (event) => {
    setBodyweightIsValid(true);
    setBodyweight(event?.target?.value);
  };
  const handleBodyweightValidation = () => setBodyweightIsValid(/^\d*$/.test(bodyweight));

  const getPasswordStrength = (pwd) => {
    let strength = 0;
    if (pwd.length < 8) { return 0; }
    strength += (pwd.length - 4) / 4;
    strength *= (pwd.match(/[A-Z]/) ? 1.5 : 1);
    strength *= (pwd.match(/\d/) ? 1.5 : 1);
    strength *= (pwd.match(/[@$!%*#?&]/) ? 1.5 : 1);
    return Math.floor(strength);
  };

  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [doesPasswordMatch, setDoesPasswordMatch] = useState(true);
  const [newPasswordCheck, setNewPasswordCheck] = useState('');
  const handlePassword = (event) => {
    if (!passwordIsValid) setPasswordIsValid(true);
    const value = event?.target?.value;
    setPassword(value);
    setPasswordStrength(getPasswordStrength(value));
    setDoesPasswordMatch(newPasswordCheck === value);
  };

  const [passwordCheck, setPasswordCheck] = useState('');
  const handlePasswordCheck = (event) => {
    const value = event?.target?.value;
    setPasswordCheck(value);
    setDoesPasswordMatch(value === password);
  };

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordStrength, setNewPasswordStrength] = useState(0);
  const [newPasswordMatch, setNewPasswordMatch] = useState(true);
  const handleNewPassword = (event) => {
    const value = event?.target?.value;
    setNewPassword(value);
    setNewPasswordStrength(getPasswordStrength(value));
    setNewPasswordMatch(newPasswordCheck === value);
  };

  const handleNewPasswordCheck = (event) => {
    const value = event?.target?.value;
    setNewPasswordCheck(value);
    setNewPasswordMatch(newPassword === value);
  };

  const [isPictureTooLarge, setIsPictureTooLarge] = useState(false);
  const [pictureMessage, setPictureMessage] = useState('');
  const [profilePicture, setProfilePicture] = useState(user.profile?.profile_picture || '');
  const handlePicture = (event) => {
    const image = event?.target?.files[0];
    if (image.size < 1024 * 1024) {
      setIsPictureTooLarge(false);
      setPictureMessage(image.name);
      readImage(image, (img) => setProfilePicture(img));
    } else {
      setIsPictureTooLarge(true);
      setPictureMessage('picture is too big. max size is 1MB');
    }
  };

  const removePicture = () => {
    setProfilePicture('');
    setIsPictureTooLarge(false);
    setPictureMessage('');
  };

  const isUpdateButtonDisabled = !email
    || !emailIsValid
    || !username
    || usernameAlreadyTaken
    || !bodyweight
    || !bodyweightIsValid
    || !newPasswordMatch
    || (!!newPassword && newPasswordStrength < 1)
    || (!!newPassword && !password);

  const isUpdatePasswordButtonDisabled = !newPasswordMatch || (!!newPassword && newPasswordStrength < 1);

  const handleUpdate = async () => {
    if (isUpdateButtonDisabled) return null;

    setUser((prevUser) => {
      const newUser = {
        ...prevUser,
        profile: {
          email,
          username,
          bodyweight: parseFloat(bodyweight),
          profile_picture: profilePicture,
        },
      };
      return newUser;
    });
    try {
      const response = await fetchGraphQL(`mutation { setProfile(
        _id: "${user._id}",
        profile: {
          email: "${email}",
          username: "${username}",
          bodyweight: ${parseFloat(bodyweight)},
          profile_picture: "${profilePicture}",
          ${newPassword ? `password: "${password}",` : ''}
          ${newPassword ? `new_password: "${newPassword}",` : ''}
        }
      ) { matchedCount, modifiedCount, upsertedId, upsertedCount } }`);

      if (response.matchedCount === 1) {
        // eslint-disable-next-line no-console
        console.log('profile saved');
        return true;
      }
      return response;
    } catch (error) {
      if (error.message === '401') {
        setPasswordIsValid(true);
        return false;
      }

      // eslint-disable-next-line no-console
      console.error(error);
      return error;
    }
  };

  const isLoginButtonIsDisabled = !email || !password;

  const handleLogin = async (event) => {
    if (isLoginButtonIsDisabled) return;
    event.preventDefault(); // Prevent submitting/refreshing the form

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, version }),
      });
      if (response.status !== 200) throw response;
      const data = await response.json();
      localStorage.setItem('token', data.token);
      const userFromDB = JSON.parse(data.user);
      setUser(userFromDB);
      closeModal();
    } catch (error) {
      if (error.status === 401) {
        setPasswordIsValid(false);
      } else {
        setPasswordIsValid(!error.statusText);
      }
    }
  };

  const isSignupButtonDisabled = !email
    || !username
    || usernameAlreadyTaken
    || !bodyweightIsValid
    || !newPasswordMatch
    || (!!newPassword && newPasswordStrength < 1);

  const handleSignup = async (event) => {
    event.preventDefault(); // Prevent submitting/refreshing the form
    if (isSignupButtonDisabled) return false;

    const newUser = {
      ...defaultUser,
      profile: {
        email,
        username,
        bodyweight,
        password,
        profile_picture: profilePicture,
      },
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/signup`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: newUser }),
      });
      if (response.status !== 200) throw response;
      const data = await response.json();
      localStorage.setItem('token', data.token);
      newUser._id = data._id;
      delete newUser.profile.password;
      setUser(newUser);
      return true;
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      return false;
    }
  };

  const isResetButtonDisabled = !emailIsValid || !email;
  const [showResetPin, setShowResetPin] = useState(false);
  const handleReset = async (event) => {
    event.preventDefault(); // Prevent submitting/refreshing the form
    if (isResetButtonDisabled) return false;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/passwordreset`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (response.status !== 200) throw response;
      setShowResetPin(true);
      return true;
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      return false;
    }
  };

  const [isResetPasswordInputVisible, setIsResetPasswordInputVisible] = useState(isResetPasswordValidated);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const handlePinSuccess = useCallback((data) => {
    localStorage.setItem('token', data.token);
    const userFromDB = JSON.parse(data.user);
    setUser(userFromDB);
    setShowResetPin(false);
    setShowErrorMessage(false);
    setIsResetPasswordInputVisible(true);
  }, [setUser]);
  const handlePinFailure = useCallback(() => {
    setShowErrorMessage(true);
  }, []);

  const handleUpdateNewPassword = async () => {
    if (isUpdatePasswordButtonDisabled) return null;

    try {
      const response = await fetchGraphQL(`mutation { setProfile(
        _id: "${user._id}",
        profile: {
          new_password: "${newPassword}",
        }
      ) { matchedCount, modifiedCount, upsertedId, upsertedCount } }`);

      if (response.matchedCount === 1) {
        console.log('new password saved'); // eslint-disable-line no-console
        return true;
      }
      return response;
    } catch (error) {
      if (error.message === '401') {
        setPasswordIsValid(true);
        return false;
      }

      console.error(error); // eslint-disable-line no-console
      return error;
    }
  };

  const handleLogoutLink = () => {
    setUser(defaultUser);
    localStorage.clear();
    closeModal();
  };
  const handleLoginLink = () => {
    setShowResetPin(false);
    setAction('login');
  };
  const handleSignupLink = () => {
    setShowResetPin(false);
    setAction('signup');
  };
  const handleResetLink = () => {
    setShowResetPin(false);
    setAction('reset');
  };

  const getTitle = () => {
    if (action === 'login') return 'Login';
    if (action === 'signup') return 'Create your account';
    if (action === 'reset') return 'Reset password';
    return '';
  };

  const getPasswordMessage = () => {
    if ((action === 'login' || action === 'update') && !passwordIsValid) return 'wrong password';
    if (action === 'signup' && password) return `password strength is ${passwordStrength}`;
    return '';
  };

  return (
    <main id="profile-form">
      {action !== 'update' ? (
        <h1 className="title">
          {getTitle()}
        </h1>
      ) : null}
      <div className="form-inputs">
        <div className="user-info-column">
          <div className={`input-container${!isResetPasswordInputVisible ? ' visible' : ' hidden'}`}>
            <Input
              type="email"
              label="email"
              name="email"
              autoComplete="username" // For the browser passwords manager to fill the field
              animate
              required
              value={email}
              onChange={handleEmail}
              onBlur={handleEmailValidation}
              invalid={!emailIsValid}
              message={!emailIsValid ? 'email is not valid' : ''}
              messageColor="var(--red, red)"
            />
          </div>
          <div className={`reset-message-container${showResetPin ? ' visible' : ' hidden'}`}>
            Enter the pin code sent to you by email.
          </div>
          <div className={`reset-pin-container${showResetPin ? ' visible' : ' hidden'}`}>
            <ResetPasswordPin email={email} onSuccess={handlePinSuccess} onFailure={handlePinFailure} />
          </div>
          <div className={`reset-pin-error-message${showErrorMessage ? ' visible' : ' hidden'}`}>
            Pin is not valid.
          </div>
          <div className={`input-container${action.match(/signup|update/) ? ' visible' : ' hidden'}`}>
            <Input
              type="text"
              label="username"
              animate
              required
              value={username}
              onChange={handleUsername}
              invalid={usernameAlreadyTaken}
              message={usernameAlreadyTaken ? 'username already taken' : ''}
              messageColor="var(--red, red)"
            />
          </div>
          <div className={`input-container${action.match(/signup|update/) ? ' visible' : ' hidden'}`}>
            <Input
              type="text"
              label="bodyweight"
              animate
              required
              value={bodyweight}
              onChange={handleBodyweight}
              onBlur={handleBodyweightValidation}
              invalid={!bodyweightIsValid}
              message={!bodyweightIsValid && bodyweight !== '' ? 'bodyweight must be a positive number' : ''}
              messageColor="var(--red, red)"
            />
          </div>
          <div className={`input-container${action.match(/login|signup/) ? ' visible' : ' hidden'}`}>
            <Input
              type="password"
              label="password"
              name="password"
              autoComplete={action === 'signup' ? 'new-password' : 'current-password'}
              animate
              required
              value={password}
              onChange={handlePassword}
              message={getPasswordMessage()}
              messageColor="var(--red, red)"
            />
          </div>
          <div className={`reset-link-container${action === 'login' ? ' visible' : ' hidden'}`}>
            <Link to="/" onClick={handleResetLink} className="reset-link">
              reset password
            </Link>
          </div>
          <div className={`input-container${action === 'signup' ? ' visible' : ' hidden'}`}>
            <Input
              type="password"
              label="password check"
              name="password check"
              autoComplete="new-password"
              animate
              required
              value={passwordCheck}
              onChange={handlePasswordCheck}
              message={!doesPasswordMatch ? 'passwords do not match' : ''}
              messageColor="var(--red, red)"
            />
          </div>
          <div className={`input-container${isResetPasswordInputVisible ? ' visible' : ' hidden'}`}>
            <Input
              type="password"
              label="new password"
              autoComplete="new-password"
              animate
              value={newPassword}
              onChange={handleNewPassword}
              message={newPassword ? `password strength is ${newPasswordStrength}` : ''}
              messageColor="var(--red, red)"
            />
          </div>
          <div className={`input-container${isResetPasswordInputVisible ? ' visible' : ' hidden'}`}>
            <Input
              type="password"
              label="new password check"
              autoComplete="new-password"
              value={newPasswordCheck}
              onChange={handleNewPasswordCheck}
              animate
              invalid={!newPasswordMatch}
              message={!newPasswordMatch ? 'passwords do not match' : ''}
              messageColor="var(--red, red)"
            />
          </div>
          <details className={`change-password${action === 'update' ? ' visible' : ' hidden'}`}>
            <summary>change your password</summary>
            <div className={`input-container${action === 'update' ? ' visible' : ' hidden'}`}>
              <Input
                type="password"
                label="current password"
                autoComplete="current-password"
                animate
                value={password}
                onChange={handlePassword}
                message={action === 'update' && !passwordIsValid ? 'wrong password' : ''}
                messageColor="var(--red, red)"
              />
            </div>
            <Input
              type="password"
              label="new password"
              autoComplete="new-password"
              animate
              value={newPassword}
              onChange={handleNewPassword}
              message={newPassword ? `password strength is ${newPasswordStrength}` : ''}
              messageColor="var(--red, red)"
            />
            <Input
              type="password"
              label="new password check"
              autoComplete="new-password"
              value={newPasswordCheck}
              onChange={handleNewPasswordCheck}
              animate
              invalid={!newPasswordMatch}
              message={!newPasswordMatch ? 'passwords do not match' : ''}
              messageColor="var(--red, red)"
            />
          </details>
        </div>
        <div className={`profile-picture-column${action.match(/update|signup/) ? ' visible' : ' hidden'}`}>
          <Input
            type="file"
            accept="image/*"
            label="Profile picture"
            animate
            onChange={handlePicture}
            onRemoveFile={removePicture}
            invalid={isPictureTooLarge}
            message={pictureMessage}
            messageColor={isPictureTooLarge ? 'var(--red, red)' : 'var(--text-color)'}
          />
          <div className="image-preview">
            {profilePicture ? <img src={profilePicture} alt="" /> : <ProfileIcon />}
          </div>
        </div>
      </div>
      {action === 'update'
        ? (
          <>
            <Link to="/" onClick={handleLogoutLink} className="logout-link">
              Logout
            </Link>
            <SaveButton
              onClick={handleUpdate}
              timeoutCallback={closeModal}
              disabled={isUpdateButtonDisabled}
              className="save-button"
            >
              Save
            </SaveButton>
          </>
        )
        : null}
      {isResetPasswordInputVisible
        ? (
          <SaveButton
            onClick={handleUpdateNewPassword}
            timeoutCallback={closeModal}
            disabled={isUpdatePasswordButtonDisabled}
            className="save-button"
          >
            Save
          </SaveButton>
        )
        : null}
      {action === 'login'
        ? (
          <>
            <Link to="/" onClick={handleSignupLink} className="create-link">
              Create an account
            </Link>
            <SaveButton
              type="submit"
              onClick={handleLogin}
              disabled={isLoginButtonIsDisabled}
              className="login-button"
            >
              Login
            </SaveButton>
          </>
        )
        : null}
      {action === 'signup'
        ? (
          <>
            <Link to="/" onClick={handleLoginLink} className="login-link">
              Login page
            </Link>
            <SaveButton
              onClick={handleSignup}
              timeoutCallback={closeModal}
              disabled={isSignupButtonDisabled}
              className="create-button"
            >
              Create
            </SaveButton>
          </>
        )
        : null}
      {action === 'reset' && !isResetPasswordInputVisible
        ? (
          <>
            <Link to="/" onClick={handleLoginLink} className="login-link">
              Login page
            </Link>
            <SaveButton
              onClick={handleReset}
              // timeoutCallback={closeModal}
              disabled={isResetButtonDisabled}
              className="reset-button"
            >
              Send Reset Link
            </SaveButton>
          </>
        )
        : null}

    </main>
  );
}

ProfileForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isResetPasswordValidated: PropTypes.bool,
};

ProfileForm.defaultProps = {
  isResetPasswordValidated: false,
};

export default ProfileForm;
