import React from 'react';

import Button from './Button';
import CrossIcon from '../svgs/CrossIcon';

function CrossButton(props) {
  return (
    <Button nopadding {...props}>
      <CrossIcon />
    </Button>
  );
}

export default CrossButton;
