/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import './components.scss';
import React, { useRef } from 'react';

import BackButton from '../../../components/buttons/BackButton';
import BoxButton from '../../../components/buttons/BoxButton';
import FontMinusButton from '../../../components/buttons/FontMinusButton';
import FontPlusButton from '../../../components/buttons/FontPlusButton';
import Toolbar from '../../../components/Toolbar/Toolbar';
import SectionButtons from './SectionButtons';
import SectionDocuments from './SectionDocuments';
import SectionIcons from './SectionIcons';
import SectionInputs from './SectionInputs';
import SectionIntro from './SectionIntro';
import SectionModals from './SectionModals';

function Components() {
  const mainRef = useRef();
  const mainElement = mainRef.current;

  return (
    <main id="components" ref={mainRef}>
      <Toolbar>
        <BackButton />
        <div className="center" />
        <FontMinusButton isLocal />
        <FontPlusButton isLocal />
        <BoxButton
          label="?"
          nopadding
          round
          notriangle
          boxStyle={{ width: '200px' }}
          container={mainElement}
        >
          <h3>Toolbar</h3>
          <p>Put your buttons here to add actions to pages</p>
        </BoxButton>
      </Toolbar>
      <SectionIntro style={{ '--section-background-color': 'var(--color1)' }} />
      <SectionButtons header={<h1>Buttons</h1>} />
      <SectionButtons
        header={(
          <>
            <h1>Colorized Buttons</h1>
            <p>
              Sections can automaticaly colorized elements such as texts, buttons or
              dynamic popups with their own text and background colors.
            </p>
          </>
        )}
        colorizeButtons
        style={{ '--section-background-color': 'var(--primary)', '--section-text-color': 'var(--color1)' }}
      />
      <SectionIcons style={{ '--section-background-color': 'var(--color1)' }} />
      <SectionInputs style={{ '--section-background-color': 'var(--color2)' }} />
      <SectionModals style={{ '--section-background-color': 'var(--primary)', '--section-text-color': 'var(--color1)' }} />
      <SectionDocuments style={{ '--section-background-color': 'var(--color5)', '--section-text-color': 'var(--color1)' }} />
    </main>
  );
}

export default Components;
