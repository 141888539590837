import './hamburger.scss';
import React from 'react';

import PropTypes from 'prop-types';

import Button from './Button';

function Hamburger({ isOpen, ...otherProps }) {
  return (
    <Button
      aria-label="General menu"
      nopadding
      round
      className={`hamburger${isOpen ? ' is-open' : ' is-closed'}`}
      {...otherProps}
    >
      <div className="hamburger-container">
        <div className="hamburger-line top-line" />
        <div className="hamburger-line middle-line" />
        <div className="hamburger-line bottom-line" />
      </div>
    </Button>
  );
}

Hamburger.propTypes = {
  isOpen: PropTypes.bool,
};
Hamburger.defaultProps = {
  isOpen: false,
};
export default Hamburger;
