import './boxbutton.scss';

import React, { useState, useRef, useLayoutEffect } from 'react';

import PropTypes from 'prop-types';

import Button from './Button';
import convertCssUnitsToPx from '../../lib/convertCssUnitstoPx';
import OverflowBottom from '../OverflowBottom/OverflowBottom';
import OverflowTop from '../OverflowTop/OverflowTop';

function BoxButton({
  notriangle = false,
  center = false,
  label = '',
  container = document.body,
  scrollableElement = window,
  className = '',
  children,
  closeOnClick = false,
  buttonStyle = {},
  labelStyle = {},
  boxStyle = {},
  style = {},
  ...otherProps
}) {
  const boxRef = useRef();
  const boxButtonRef = useRef();
  const boxContentRef = useRef();
  const [openBox, setOpenBox] = useState(false);
  const [showOverflow, setShowOverflow] = useState(false);
  const [showBottomTriangle, setShowBottomTriangle] = useState(true);
  const [showTopTriangle, setShowTopTriangle] = useState(false);

  useLayoutEffect(() => {
    function closeOnClickOutside(event) {
      if (openBox && event.target.closest('.box-button-container') !== boxButtonRef.current) setOpenBox(false);
    }
    window.addEventListener('click', closeOnClickOutside);
    return () => window.removeEventListener('click', closeOnClickOutside);
  });

  useLayoutEffect(() => {
    function setXBoxPosition(buttonOffset, containerOffset) {
      const boxComputedStyle = window.getComputedStyle(boxRef.current);
      const containerGap = convertCssUnitsToPx(boxComputedStyle.getPropertyValue('--box-gap-with-container'));

      const distanceFromContainerLeft = buttonOffset.left - containerOffset.left - containerGap;
      const distanceFromContainerRight = containerOffset.right - buttonOffset.right - containerGap;
      let boxXPosition;
      if (center) {
        boxXPosition = 'center';
      } else if (distanceFromContainerLeft > distanceFromContainerRight) {
        boxXPosition = 'left';
      } else {
        boxXPosition = 'right';
      }
      /* Cases when space is too small on the right or/and left:
        The screen's border pushes the box' border until the screen is too small,
        then the box is resized to the maximum size available. */

      const containerInnerWidth = containerOffset.width - 2 * containerGap;
      const boxOuterWidth = convertCssUnitsToPx(boxStyle?.width || boxComputedStyle.width);

      /* IF CONTAINER SIZE IS BIGGER THAN THE BOX SIZE */
      if (boxOuterWidth < containerInnerWidth) {
        boxRef.current.style.width = `${boxOuterWidth}px`;
        if (boxXPosition === 'left') {
          const spaceAvailableOntheLeft = buttonOffset.right - containerOffset.left - containerGap;
          if (spaceAvailableOntheLeft > boxOuterWidth) {
            /* Stuck to the right border of the button (distance relative to the right border of the button)
             * Let the left border of the box free */
            boxRef.current.style.right = '0';
            boxRef.current.style.left = 'auto';
          } else {
            /* Stuck to the left border of the window (distance relative to the left border of the button)
             * Let the right border of the box free */
            boxRef.current.style.left = `-${distanceFromContainerLeft}px`;
            boxRef.current.style.right = 'auto';
          }
        } else if (boxXPosition === 'right') {
          const spaceAvailableOnTheRight = containerOffset.right - buttonOffset.left - containerGap;
          if (spaceAvailableOnTheRight > boxOuterWidth) {
            /* Stuck to the left border of the button (distance relative to the left border of the button)
             * Let the right border of the box free */
            boxRef.current.style.left = '0';
            boxRef.current.style.right = 'auto';
          } else {
            /* Stuck to the right border of the window (distance relative to the right border of the button)
             * Let the left border of the box free */
            boxRef.current.style.right = `-${distanceFromContainerRight}px`;
            boxRef.current.style.left = 'auto';
          }
        } else if (boxXPosition === 'center') {
          boxRef.current.style.left = `calc(-${boxOuterWidth}px / 2 + 50%)`;
          boxRef.current.style.right = 'auto';
        }
      } else { // IF CONTAINER SIZE IS SMALLER THAN THE BOX SIZE
        /* Stuck the left and right borders to the container element
         * Let the box' width to adjust */
        boxRef.current.style.width = 'auto';
        boxRef.current.style.left = `-${distanceFromContainerLeft}px`;
        boxRef.current.style.right = `-${distanceFromContainerRight}px`;
        boxContentRef.current.style.width = 'auto';
      }
    }

    function setYBoxPosition(buttonOffset, containerOffset) {
      const distanceFromContainerTop = buttonOffset.top - containerOffset.top;
      const distanceFromContainerBottom = containerOffset.bottom - buttonOffset.bottom;

      const distanceFromWindowTop = buttonOffset.top;
      const distanceFromWindowBottom = window.innerHeight - buttonOffset.bottom;

      const isContainerTopVisible = containerOffset.top >= 0;
      const isContainerBottomVisible = containerOffset.bottom <= window.innerHeight;

      /* Get which border (container or window) is the physical border */
      const distanceFromTop = isContainerTopVisible ? distanceFromContainerTop : distanceFromWindowTop;
      const distanceFromBottom = isContainerBottomVisible ? distanceFromContainerBottom : distanceFromWindowBottom;

      const boxYPosition = distanceFromTop > distanceFromBottom ? 'top' : 'bottom';

      if (boxYPosition === 'top') {
        boxRef.current.style.bottom = `calc(${buttonOffset.height}px + var(--box-gap-with-button))`;
        boxRef.current.style.top = 'auto';
        boxContentRef.current.style.maxHeight = 'calc('
          + `${buttonOffset.top - containerOffset.top}px` // space on top of the button
          + ' - var(--box-gap-with-container)' // Gap between the box and the container
          + ' - var(--box-gap-with-button)' // Gap between the box and the button
          + ' - 2 * var(--overflow-indicator-height)' // overflow triangle height
          + ')';
      } else if (boxYPosition === 'bottom') {
        boxRef.current.style.top = `calc(${buttonOffset.height}px + var(--box-gap-with-button))`;
        boxRef.current.style.bottom = 'auto';
        boxContentRef.current.style.maxHeight = 'calc('
          + `${containerOffset.bottom - buttonOffset.bottom}px` // space under the button
          + ' - var(--box-gap-with-container)' // Gap between the box and the container
          + ' - var(--box-gap-with-button)' // Gap between the box and the button
          + ' - 2 * var(--overflow-indicator-height)' // overflow triangle height
          + ')';
      }

      const isOverflown = boxContentRef.current.scrollHeight > boxContentRef.current.clientHeight;
      setShowOverflow(isOverflown);
    }

    function setBoxPosition() {
      /* Get elements properties from current element
      to check if the box is on the left/right or the top/bottom of the button,
      depending on the container element and the screen to correctly. */
      const buttonOffset = boxButtonRef?.current?.getBoundingClientRect();
      const containerOffset = container?.getBoundingClientRect();
      if (buttonOffset && containerOffset) {
        setYBoxPosition(buttonOffset, containerOffset);
        setXBoxPosition(buttonOffset, containerOffset);
      }
    }

    window.addEventListener('resize', setBoxPosition);
    window.addEventListener('orientationchange', setBoxPosition);
    document.addEventListener('scroll', setBoxPosition);
    setBoxPosition();

    return () => {
      window.removeEventListener('resize', setBoxPosition);
      window.removeEventListener('orientationchange', setBoxPosition);
      document.removeEventListener('scroll', setBoxPosition);
    };
  }, [
    openBox,
    center,
    container,
    scrollableElement,
    boxStyle?.width,
  ]);

  useLayoutEffect(() => {
    const contentElement = boxContentRef.current;
    const handleTriangleColor = () => {
      const hasReachedTheBottom = contentElement.scrollTop >= contentElement.scrollHeight - contentElement.clientHeight;
      setShowBottomTriangle(!hasReachedTheBottom);
      const hasReachedTheTop = contentElement.scrollTop <= 0;
      setShowTopTriangle(!hasReachedTheTop);
    };
    contentElement.addEventListener('scroll', handleTriangleColor);
    return () => contentElement.removeEventListener('scroll', handleTriangleColor);
  }, []);

  const handleVisibilityFromButton = () => {
    setOpenBox((bool) => !bool);
  };

  const handleVisibilityFromBox = () => {
    if (closeOnClick) {
      setOpenBox((bool) => !bool);
    }
  };

  const containerStyle = {
    width: buttonStyle.width,
    maxWidth: buttonStyle.maxWidth,
    height: buttonStyle.height,
    maxHeight: buttonStyle.maxHeight,
  };
  return (
    <div
      ref={boxButtonRef}
      className={`box-button-container${className ? ` ${className}` : ''}`}
      style={containerStyle}
    >
      <Button
        onClick={handleVisibilityFromButton}
        className={`box-button${notriangle ? '' : ' triangle'}${openBox ? ' active' : ''}`}
        style={{ ...buttonStyle, ...style }}
        {...otherProps}
      >
        <div className="box-button-label" style={labelStyle}>{label}</div>
      </Button>
      <div
        ref={boxRef}
        className={`box${center ? ' center' : ''}${openBox ? ' opened' : ''}`}
        style={boxStyle}
      >
        {showOverflow && <OverflowTop showTopTriangle={showTopTriangle} />}
        <div
          ref={boxContentRef}
          role="button"
          tabIndex="0"
          onClick={handleVisibilityFromBox}
          onKeyPress={handleVisibilityFromBox}
          className="box-content"
          style={{
            overflow: 'scroll',
          }}
        >
          {children}
        </div>
        {showOverflow && <OverflowBottom showBottomTriangle={showBottomTriangle} />}
      </div>
    </div>
  );
}

BoxButton.propTypes = {
  boxStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  buttonStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  center: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  container: PropTypes.instanceOf(Element),
  scrollableElement: PropTypes.oneOfType([
    PropTypes.instanceOf(Window),
    PropTypes.instanceOf(Element),
  ]),
  closeOnClick: PropTypes.bool,
  label: PropTypes.node,
  labelStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  notriangle: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

BoxButton.defaultProps = {
  notriangle: false,
  center: false,
  label: '',
  container: document.body,
  scrollableElement: window,
  className: '',
  children: [],
  closeOnClick: false,
  buttonStyle: {},
  labelStyle: {},
  boxStyle: {},
  style: {},
};

export default BoxButton;
