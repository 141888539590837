import './section.scss';

import React, { forwardRef, useRef } from 'react';

import PropTypes from 'prop-types';

// eslint-disable-next-line prefer-arrow-callback
const Section = forwardRef(function Section({
  colorizeButtons,
  style,
  className,
  children,
  ...otherProps
}, forwardedRef) {
  const localRef = useRef();
  const sectionRef = forwardedRef || localRef;

  return (
    <section
      className={`section${
        className ? ` ${className}` : ''}${
        colorizeButtons ? ' colored-buttons' : ''
      }`}
      ref={sectionRef}
      style={style}
      {...otherProps}
    >
      {children}
    </section>
  );
});

Section.propTypes = {
  colorizeButtons: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  className: PropTypes.string,
  children: PropTypes.node,
};

Section.defaultProps = {
  colorizeButtons: false,
  style: {},
  className: '',
  children: [],
};

export default Section;
