import React, { useState, useCallback, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

// import applyTheme from '../lib/applyTheme';
import fetchUserData from '../api/fetchUserData';
import defaultUser from './defaultUser';

export function UserProvider({ children }) {
  const [user, setUser] = useState(defaultUser);

  const setUserHandler = useCallback((newUser) => {
    setUser((prevUser) => {
      const newUserObject = typeof newUser === 'function'
        ? newUser(prevUser) // newUser can be a callback function: prevUser => ({ ...prevUser, property: newValue })
        : newUser; // Or a just new value of user
      localStorage.setItem('user', JSON.stringify(newUserObject));
      return newUserObject;
    });
  }, []);

  useEffect(() => {
    fetchUserData().then((data) => {
      setUserHandler(data);
      // eslint-disable-next-line no-console
      console.log('User data has been loaded.');
    });
  }, [setUserHandler]);

  const context = useMemo(() => ({ user, setUser: setUserHandler }), [setUserHandler, user]);
  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
}

UserProvider.propTypes = {
  children: PropTypes.node,
};

UserProvider.defaultProps = {
  children: [],
};

const UserContext = React.createContext(defaultUser);

export default UserContext;
