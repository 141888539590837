import React from 'react';

import PropTypes from 'prop-types';

import Input from '../../../../components/inputs/Input';

function ExerciseSimpleSettings({
  newRestTime,
  newRestTimeIsValid,
  newRestTimeMessage,
  handleRestTime,
  handleRestTimeValidation,
  newAddedWeight,
  newAddedWeightIsValid,
  newAddedWeightMessage,
  handleWeight,
  handleWeightValidation,
}) {
  return (
    <>
      <Input
        label="rest time"
        placeholder="120"
        type="text"
        onChange={handleRestTime}
        animate
        required
        value={newRestTime}
        onBlur={handleRestTimeValidation}
        invalid={!newRestTimeIsValid}
        message={newRestTimeMessage}
        messageColor="var(--red, red)"
      />
      <Input
        label="added weight"
        placeholder="0"
        type="text"
        onChange={handleWeight}
        animate
        required
        value={newAddedWeight}
        onBlur={handleWeightValidation}
        invalid={!newAddedWeightIsValid}
        message={newAddedWeightMessage}
        messageColor="var(--red, red)"
      />
    </>
  );
}

ExerciseSimpleSettings.propTypes = {
  handleRestTime: PropTypes.func,
  handleRestTimeValidation: PropTypes.func,
  handleWeight: PropTypes.func,
  handleWeightValidation: PropTypes.func,
  newAddedWeight: PropTypes.string,
  newAddedWeightIsValid: PropTypes.bool,
  newAddedWeightMessage: PropTypes.string,
  newRestTime: PropTypes.string,
  newRestTimeIsValid: PropTypes.bool,
  newRestTimeMessage: PropTypes.string,
  userContext: PropTypes.shape({
    setUser: PropTypes.func,
    user: PropTypes.shape({
      sport: PropTypes.shape({
        currentExercise: PropTypes.string,
        exercises: PropTypes.string,
      }),
    }),
  }),
};

ExerciseSimpleSettings.defaultProps = {
  handleRestTime: PropTypes.func,
  handleRestTimeValidation: PropTypes.func,
  handleWeight: () => {},
  handleWeightValidation: PropTypes.func,
  newAddedWeight: '',
  newAddedWeightIsValid: true,
  newAddedWeightMessage: '',
  newRestTime: '',
  newRestTimeIsValid: true,
  newRestTimeMessage: '',
  userContext: {
    setUser: () => {},
    user: {
      sport: {
        currentExercise: '',
        exercises: '',
      },
    },
  },
};

export default ExerciseSimpleSettings;
