import './menuitem.scss';
import React from 'react';

import PropTypes from 'prop-types';

import Checkbox from '../../../../components/inputs/Checkbox';

function MenuItem({ checked, value, handleClick, handleSelect, selected, disabled, ...otherProps }) {
  return (
    <div
      role="button"
      value={value}
      className={`menu-item item${selected ? ' selected' : ''}${disabled ? ' disabled' : ''}`}
      onClick={handleClick}
      onKeyPress={handleClick}
      tabIndex="0"
      {...otherProps}
    >
      <Checkbox
        onClick={handleSelect}
        label={value}
        checked={checked}
        disabled={disabled || selected}
        noborder
        style={{
          '--input-margin': 0,
        }}
      />
    </div>
  );
}

MenuItem.propTypes = {
  handleClick: PropTypes.func,
  handleSelect: PropTypes.func,
  value: PropTypes.node.isRequired,
  checked: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

MenuItem.defaultProps = {
  handleClick: null,
  handleSelect: null,
  checked: false,
  selected: false,
  disabled: false,
};

export default MenuItem;
