import React from 'react';

import PropTypes from 'prop-types';

import historyJS from '../../lib/historyJS';

function CircleIndicator({ x, y, color }) {
  return (
    <circle
      cx={x}
      cy={y + 12}
      r={2}
      fill={color}
    />
  );
}
CircleIndicator.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

function LineIndicator({ x, y, color }) {
  return (
    <rect x={x} y={y} width={1} height={6} stroke="0" strokeWidth="0" fill={color} />
  );
}
LineIndicator.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

function Label({
  x,
  y,
  time,
  color,
  // offset = 0, /** shift the label in pixels */
  indicator = 'line', // ['line' | 'circle']
  period,
  groupBy,
  ...otherProps
}) {
  const Indicator = indicator === 'circle' ? CircleIndicator : LineIndicator;
  return (
    <g {...otherProps}>
      <Indicator x={x} y={y} color={color} />
      <text
        x={x - 2}
        y={y + 20}
        style={{ fontSize: '.7em' }}
        fill={color}
      >
        {historyJS.getTimelineLabel(time, period, groupBy)}
      </text>
    </g>
  );
}

Label.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  time: PropTypes.number.isRequired,
  color: PropTypes.string,
  // offset: PropTypes.number, /** shift the label in pixels */
  indicator: PropTypes.string,
  period: PropTypes.string.isRequired,
  groupBy: PropTypes.string.isRequired,
};

Label.defaultProps = {
  color: 'var(--border-color, rgb(229, 229, 234))',
  // offset: PropTypes.number, /** shift the label in pixels */
  indicator: 'line',
};

export default Label;
