import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Input from '../Input';

function NumberInput({
  label,
  name,
  defaultValue,
  placeholder,
  required,
  message,
  messageColor,
  style,
  className,
  noborder,
  zIndex,
  id,
  ...inputAttributes
}) {
  const isNewNumberFormatValid = (value) => /^\d+$/.test(value);
  const [newNumber, setNewNumber] = useState(defaultValue);
  const [isNewNumberInputValid, setIsNewNumberInputValid] = useState(true);
  const handleNewNumber = (event) => {
    setIsNewNumberInputValid(true);
    setNewNumber(event.target.value);
  };
  const handleNewNumberFormatValidation = () => {
    setIsNewNumberInputValid(isNewNumberFormatValid(newNumber) || newNumber === '');
  };

  return (
    <Input
      type="text"
      label={label}
      required={required}
      placeholder={placeholder}
      inputMode="decimal"
      value={newNumber}
      onChange={handleNewNumber}
      onBlur={handleNewNumberFormatValidation}
      invalid={!isNewNumberInputValid}
      showMessage={!isNewNumberInputValid}
      message={message || `${label} must be a number`}
      messageColor={messageColor}
      style={style}
      className={className}
      noborder={noborder}
      zIndex={zIndex}
      id={id}
      {...inputAttributes}
    />
  );
}

NumberInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  message: PropTypes.string,
  messageColor: PropTypes.string,
  noborder: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  className: PropTypes.string,
  zIndex: PropTypes.number,
  id: PropTypes.string,
};
NumberInput.defaultProps = {
  label: 'number',
  name: null,
  defaultValue: '',
  placeholder: '120',
  required: false,
  message: null,
  messageColor: 'var(--red, red)',
  style: {},
  className: null,
  noborder: false,
  zIndex: null,
  id: null,
};
export default NumberInput;
