import './button.scss';
import React, { forwardRef, useRef } from 'react';

import PropTypes from 'prop-types';

const Button = forwardRef(function Button({ // eslint-disable-line prefer-arrow-callback
  submit = false,
  active = false,
  round = false,
  square = false,
  nopadding = false,
  border = false,
  inverse = false,
  noresize = false,
  className = '',
  style = {},
  children,
  ...otherProps
}, ref) {
  const defaultRef = useRef();
  const buttonRef = ref || defaultRef;

  return (
    <button
      type={submit ? 'submit' : 'button'}
      ref={buttonRef}
      className={`button${
        className ? ` ${className}` : ''}${
        round ? ' round' : ''}${
        square ? ' square' : ''}${
        nopadding ? ' nopadding' : ''}${
        border ? ' border' : ''}${
        inverse ? ' inverse-colors' : ''}${
        noresize ? ' noresize' : ''}${
        active ? ' active' : ''}`}
      style={style}
      {...otherProps}
    >
      {children}
    </button>
  );
});

Button.propTypes = {
  submit: PropTypes.bool,
  active: PropTypes.bool,
  round: PropTypes.bool,
  square: PropTypes.bool,
  nopadding: PropTypes.bool,
  border: PropTypes.bool,
  inverse: PropTypes.bool,
  noresize: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  children: PropTypes.node,
};

Button.defaultProps = {
  submit: false,
  active: false,
  round: false,
  square: false,
  nopadding: false,
  border: false,
  inverse: false,
  noresize: false,
  className: '',
  style: {},
  children: [],
};

export default Button;
