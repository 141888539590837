import defaultTheme from './defaultTheme';

/**
 * THIS FILE CANNOT BE MODIFIED WITHOUT INCREMENTING THE VERSION NUMBER
 *
 * Modifying the structure and incrementing the version here
 * means it has already been implemented server side with a data conversion function.
 */

export const version = 0.3;

const defaultExercise = {
  day: 1,
  categories: [''],
  restTime: 120,
  addedWeight: 0,
  isBodyweight: false,
  history: [],
};

const defaultUser = {
  profile: {
    username: '',
    first_name: '',
    last_name: '',
    bodyweight: '',
    profile_picture: '',
  },
  sport: {
    currentExercise: 'pushups',
    selectedExercises: ['pushups'],
    exercises: JSON.stringify({
      pushups: defaultExercise,
      pullups: defaultExercise,
      squats: defaultExercise,
    }),
    period: 'one_week',
    grouping: JSON.stringify({
      one_week: 'day',
      one_month: 'day',
      three_months: 'week',
      one_year: 'month',
    }),
    showAverage: false,
    showDates: false,
    groupByCategory: false,
    dynamicColumns: true,
    showTooltip: true,
  },
  theme: JSON.stringify(defaultTheme),
  version,
};

export default defaultUser;
