import React from 'react';

import PropTypes from 'prop-types';

import Button from '../../../components/buttons/Button';
import Modal from '../../../components/Modal/Modal';
import defaultTheme from '../../../contexts/defaultTheme';
import UserContext from '../../../contexts/UserContext';
import applyTheme from '../../../lib/applyTheme';

function ResetModal({ isOpen, setIsOpen }) {
  const { setUser } = React.useContext(UserContext);

  const handleReset = () => {
    setUser((prevUser) => {
      const newUser = {
        ...prevUser,
        theme: JSON.stringify(defaultTheme),
      };
      return newUser;
    });
    applyTheme(defaultTheme);
    setIsOpen(false);
  };

  const handleCancel = () => setIsOpen(false);

  return (
    <Modal
      title="Reset theme"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div style={{ padding: '0 3rem 2rem 3rem' }}>
        <p>You are about to reset all your theme preferences.</p>
        <p>Do you want to continue ?</p>
        <br />
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleReset}>Reset</Button>
        </div>
      </div>
    </Modal>
  );
}

ResetModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default ResetModal;
