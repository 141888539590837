import './timer.scss';
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

function getTwoDigitMinutes(timerTimeInSeconds) {
  return Math.floor(timerTimeInSeconds / 60).toString().padStart(2, '0');
}
function getMinutesUnit(timerTimeInSeconds) {
  return getTwoDigitMinutes(timerTimeInSeconds)[1];
}
function getMinutesTens(timerTimeInSeconds) {
  const tensDigit = getTwoDigitMinutes(timerTimeInSeconds)[0];
  return tensDigit !== '0' ? tensDigit : '0';
}
function getTwoDigitsSeconds(timerTimeInSeconds) {
  return (timerTimeInSeconds % 60).toString().padStart(2, '0');
}
function getSecondsUnit(timerTimeInSeconds) {
  return getTwoDigitsSeconds(timerTimeInSeconds)[1];
}
function getSecondsTens(timerTimeInSeconds) {
  return getTwoDigitsSeconds(timerTimeInSeconds)[0];
}

function Timer({ minutes, seconds, isActive, onFinish, ...props }) {
  const [startTime] = useState(Date.now());
  const intervalTime = minutes * 60 * 1000 + seconds * 1000;
  const endTime = startTime + intervalTime;
  const [timerTime, setNowTime] = useState(intervalTime);

  useEffect(() => {
    const timer = setInterval(() => {
      setNowTime(endTime - Date.now());
    }, 1000);

    if (timerTime <= 0 || !isActive) {
      clearInterval(timer);
      onFinish();
    }
    return () => clearInterval(timer);
  }, [timerTime, onFinish, isActive, endTime]);

  const timerTimeInSeconds = Math.round(timerTime / 1000);
  return (
    <div className="timer" {...props}>
      <div className="digits">{getMinutesTens(timerTimeInSeconds)}</div>
      <div className="digits">{getMinutesUnit(timerTimeInSeconds)}</div>
      <div className="separator">:</div>
      <div className="digits">{getSecondsTens(timerTimeInSeconds)}</div>
      <div className="digits">{getSecondsUnit(timerTimeInSeconds)}</div>
    </div>
  );
}

Timer.propTypes = {
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  isActive: PropTypes.bool,
  onFinish: PropTypes.func,
};

Timer.defaultProps = {
  minutes: 0,
  seconds: 0,
  isActive: true,
  onFinish: () => {},
};

export default Timer;
