import React from 'react';

import PropTypes from 'prop-types';

import CssInput from '../../../components/CssInput/CssInput';
import DropDown from '../../../components/DropDown/DropDown';
import UserContext from '../../../contexts/UserContext';

function CssInputs({ cssVariables, ...otherProps }) {
  const { user } = React.useContext(UserContext);

  return (
    <div className="css-input">
      {cssVariables.map(({ variable, name, type, values, defaultValue }, i) => {
        const cssValue = JSON.parse(user.theme)[variable] || defaultValue;

        if (type === 'select') {
          return (
            <DropDown
              key={variable}
              label={name}
              name={variable}
              value={cssValue}
              values={values}
              zIndex={cssVariables.length - i} // To make sur that dropdown list is on top of next elements
              {...otherProps}
            />
          );
        }
        return (
          <CssInput
            key={variable}
            type={type}
            label={name}
            name={variable}
            value={cssValue}
            placeholder={cssValue}
            {...otherProps}
          />
        );
      })}
    </div>
  );
}

CssInputs.propTypes = {
  cssVariables: PropTypes.arrayOf(PropTypes.shape(
    {
      name: PropTypes.string,
      tip: PropTypes.string,
      variable: PropTypes.string,
      type: PropTypes.string,
    },
  )).isRequired,
};

export default CssInputs;
