/* eslint-disable react/destructuring-assignment */
import './sidebar.scss';
import React, { createRef } from 'react';

import PropTypes from 'prop-types';

function Sidebar({
  isOpen,
  className,
  children,
  ...otherProps
}) {
  const sidebarRef = createRef();

  return (
    <nav
      ref={sidebarRef}
      className={`sidebar${
        isOpen ? ' is-open' : ' is-closed'}${
        className ? ` ${className}` : ''
      }`}
      {...otherProps}
    >
      <div className="sidebar-items">
        {children}
      </div>
    </nav>
  );
}

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};
Sidebar.defaultProps = {
  isOpen: false,
  children: '',
  className: '',
};
export default Sidebar;
