import React from 'react';

import PropTypes from 'prop-types';

import SaveButton from '../../../../components/buttons/SaveButton';
import saveExerciseSimpleSettings from './saveExerciseSimpleSettings';

function ExerciseSimpleSettingsSaveButton({
  userContext,
  newRestTime,
  newRestTimeIsValid,
  newAddedWeight,
  newAddedWeightIsValid,
  closeSettings,
}) {
  const handleSaveSettings = () => saveExerciseSimpleSettings({ userContext, newRestTime, newAddedWeight });

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'flex-end',
      margin: 'var(--button-margin) 0',
    }}
    >
      <SaveButton
        disabled={!newRestTimeIsValid || !newAddedWeightIsValid}
        onClick={handleSaveSettings}
        timeoutCallback={closeSettings}
      />
    </div>
  );
}

ExerciseSimpleSettingsSaveButton.propTypes = {
  closeSettings: PropTypes.func,
  newAddedWeight: PropTypes.string,
  newAddedWeightIsValid: PropTypes.bool,
  newRestTime: PropTypes.string,
  newRestTimeIsValid: PropTypes.bool,
  userContext: PropTypes.shape({
    setUser: PropTypes.func,
    user: PropTypes.object,
  }),
};

ExerciseSimpleSettingsSaveButton.defaultProps = {
  closeSettings: () => {},
  newAddedWeight: '',
  newAddedWeightIsValid: true,
  newRestTime: '',
  newRestTimeIsValid: true,
  userContext: {
    setUser: () => {},
    user: {},
  },
};

export default ExerciseSimpleSettingsSaveButton;
