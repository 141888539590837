/* eslint-disable no-console */
// import dataVideo from './emptyVideoData';

function WakeLock() {
  /*
  let stayAwakeVideo = null;

  const handleClick = () => { stayAwakeVideo.play(); };
  const handleTimeUpdate = () => {
    if (stayAwakeVideo.currentTime > 0.5) {
      stayAwakeVideo.currentTime = 0.1;
    }
  };
  const handleLoadedmetaData = () => { stayAwakeVideo.addEventListener('timeupdate', handleTimeUpdate); };
  const addStayAwakeVideo = () => {
    stayAwakeVideo = document.createElement('video');
    stayAwakeVideo.setAttribute('playsinline', '');
    stayAwakeVideo.setAttribute('style', 'position: absolute; opacity: 0; height: 1px');
    const stayAwakeSource = document.createElement('source');
    stayAwakeSource.setAttribute('type', 'video/mp4');
    // eslint-disable-next-line max-len
    stayAwakeSource.setAttribute('src', dataVideo);
    stayAwakeVideo.append(stayAwakeSource);
    document.body.insertBefore(stayAwakeVideo, document.body.firstChild);
    document.addEventListener('click', handleClick);
    stayAwakeVideo.addEventListener('loadedmetadata', handleLoadedmetaData);
  };
  */

  let wakeLock = null; // Will store wakeLock for futur release
  return {
    request: () => {
      if ('wakeLock' in navigator) {
        navigator.wakeLock.request('screen')
          .then((result) => {
            console.log('Wake Lock actived');
            wakeLock = result;
          })
          .catch(() => console.error('Wake Lock failed to activate'));
      } else {
        console.log('Wake Lock is not supported');
        /*
        console.log('Wake Lock is not supported\nStarting StayAwake video instead...');
        addStayAwakeVideo();
        */
      }
    },

    release: () => {
      if ('wakeLock' in navigator && wakeLock?.release) {
        wakeLock.release()
          .then(() => console.log('Wake Lock released'))
          .catch(() => console.error('Wake Lock failed to release'));
      } /* else {
        document.removeEventListener('timeupdate', handleTimeUpdate);
        document.removeEventListener('loadedmetadata', handleLoadedmetaData);
        document.removeEventListener('click', handleClick);
        stayAwakeVideo.remove();
      } */
    },
  };
}

export default WakeLock;
