import defaultUser, { version } from '../contexts/defaultUser';
import fetchGraphQL from '../lib/fetchGraphQL';

const fetchUserData = async () => {
  const isLoggedIn = !!localStorage.getItem('token');
  const localUserString = localStorage.getItem('user');
  if (!isLoggedIn) {
    return (localUserString ? JSON.parse(localUserString) : defaultUser);
  }

  const userId = localUserString && JSON.parse(localUserString)?._id;
  if (!localUserString || !userId) {
    localStorage.clear();
    // eslint-disable-next-line no-console
    console.log('No local data found.\nYou have been logged out.');
    return defaultUser;
  }

  // eslint-disable-next-line no-console
  console.log('Fetching user data...');
  return fetchGraphQL(`{ getUser(_id: "${userId}", version: ${version}) {
        _id
        profile {
          email
          username
          bodyweight
          profile_picture
        }
        sport {
            period
            grouping
            currentExercise
            selectedExercises
            showSelectedExercises
            exercises
            showAverage
            showDates
            groupByCategory
            dynamicColumns
            showTooltip
          }
        theme
        version
    } }`)
    .then((data) => {
      if (data instanceof Error) throw data;
      return data;
    })
    .catch(({ message }) => {
      // eslint-disable-next-line no-console
      console.error(message);

      // eslint-disable-next-line no-console
      console.log('Loading local user data...');
      return JSON.parse(localUserString);
    });
};

export default fetchUserData;
