import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Input from '../Input';

function DecimalInput({
  label,
  name,
  defaultValue,
  placeholder,
  required,
  message,
  messageColor,
  style,
  className,
  noborder,
  zIndex,
  id,
  ...inputAttributes
}) {
  const isNewDecimalFormatValid = (value) => /^\d+([.,]\d)*$/.test(value);
  const [newDecimal, setNewDecimal] = useState(defaultValue);
  const [isNewDecimalInputValid, setIsNewDecimalInputValid] = useState(true);
  const handleNewDecimal = (event) => {
    setIsNewDecimalInputValid(true);
    setNewDecimal(event.target.value);
  };
  const handleNewDecimalFormatValidation = () => {
    setIsNewDecimalInputValid(isNewDecimalFormatValid(newDecimal) || newDecimal === '');
  };

  return (
    <Input
      type="text"
      label={label}
      required={required}
      placeholder={placeholder}
      inputMode="decimal"
      value={newDecimal}
      onChange={handleNewDecimal}
      onBlur={handleNewDecimalFormatValidation}
      invalid={!isNewDecimalInputValid}
      showMessage={!isNewDecimalInputValid}
      message={message || `${label} must be a decimal number`}
      messageColor={messageColor}
      style={style}
      className={className}
      noborder={noborder}
      zIndex={zIndex}
      id={id}
      {...inputAttributes}
    />
  );
}

DecimalInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  message: PropTypes.string,
  messageColor: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  className: PropTypes.string,
  noborder: PropTypes.bool,
  zIndex: PropTypes.number,
  id: PropTypes.string,
};
DecimalInput.defaultProps = {
  label: 'decimal',
  name: null,
  defaultValue: '',
  placeholder: '72.5',
  required: false,
  message: null,
  messageColor: 'var(--red, red)',
  style: {},
  className: null,
  noborder: false,
  zIndex: null,
  id: null,
};
export default DecimalInput;
