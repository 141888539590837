import './sidenavbar.scss';
import React from 'react';

import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import Sidebar from '../../components/Sidebar/Sidebar';
import routes from '../../config/routes';

function Sidenavbar({ isOpen, ...otherProps }) {
  const location = useLocation();
  return (
    <Sidebar isOpen={isOpen} {...otherProps}>
      {routes.map((route) => (route.topnavbar || route.sidenavbar) && (
        <div className="item-wrapper" key={route.name}>
          <Link
            to={route.path}
            className={
              `outside${location.pathname === route.path ? ' active' : ''
              }${route.topnavbar ? ' topnavbar-item' : ''
              }${route.sidenavbar ? ' sidenavbar-item' : ''
              }`
            }
          >
            <div className="text-wrapper">
              {route.name}
              <div className="underline" />
            </div>
          </Link>
        </div>
      ))}
    </Sidebar>
  );
}

Sidenavbar.propTypes = {
  isOpen: PropTypes.bool,
};

Sidenavbar.defaultProps = {
  isOpen: true,
};

export default Sidenavbar;
