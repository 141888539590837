import React from 'react';

import FacebookIcon from '../components/svgs/FacebookIcon';
import FacebookLogo from '../components/svgs/FacebookLogo';
import GitHubIcon from '../components/svgs/GitHubIcon';
import GithubLogo from '../components/svgs/GitHubLogo';
import InstagramIcon from '../components/svgs/InstagramIcon';
import InstagramLogo from '../components/svgs/InstagramLogo';
import TwitterIcon from '../components/svgs/TwitterIcon';
import TwitterLogo from '../components/svgs/TwitterLogo';
import company from './company';

const socials = {
  FACEBOOK: {
    LINK: 'https://www.facebook.com/javascriptJS/',
    ICON: FacebookIcon,
    LOGO: FacebookLogo,
  },
  TWITTER: {
    LINK: 'https://twitter.com/JavaScript',
    ICON: TwitterIcon,
    LOGO: TwitterLogo,
  },
  GITHUB: {
    LINK: 'https://github.com/topics/javascripts',
    ICON: GitHubIcon,
    LOGO: GithubLogo,
  },
  INSTAGRAM: {
    LINK: 'https://www.instagram.com/javascript.js/',
    ICON: InstagramIcon,
    LOGO: InstagramLogo,
  },
  EMAIL: {
    LINK: `mailto:${company.EMAIL}`,
    ICON: (props) => <span {...props}>@</span>,
    LOGO: (props) => <span {...props}>email</span>,
  },
};

export default socials;
