/* eslint-disable no-console */
import setExercisesAPI from '../../../../api/setExercisesAPI';

const deleteWorkout = async (workoutToDelete, userContext) => {
  if (typeof workoutToDelete === 'undefined') return console.error('"workoutToDelete" must be defined');
  if (typeof userContext === 'undefined') return console.error('"userContext" must be defined');
  if (typeof userContext.user === 'undefined') return console.error('"userContext.user" must be defined');
  if (typeof userContext.setUser === 'undefined') return console.error('"userContext.setUser" must be defined');

  const { user, setUser } = userContext;

  let newExercises;

  setUser((prevUser) => {
    const exercises = JSON.parse(prevUser.sport.exercises);
    const exercise = exercises[workoutToDelete.name];
    const newHistory = exercise.history.filter((workout) => workout.time !== workoutToDelete.time);
    newExercises = JSON.stringify({
      ...exercises,
      [workoutToDelete.name]: {
        ...exercise,
        history: newHistory,
      },
    });
    const newUser = {
      ...prevUser,
      sport: {
        ...prevUser.sport,
        exercises: newExercises,
      },
    };
    return newUser;
  });

  return setExercisesAPI(user._id, newExercises, 'workout deleted');
};

export default deleteWorkout;
