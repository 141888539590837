const importFonts = (fontName) => {
  try {
    const fontLinkElement = document.getElementById('font-url');
    if (fontLinkElement) {
      fontLinkElement.setAttribute('href', `https://fonts.googleapis.com/css?family=${fontName}&display=swap`);
    } else {
      const link = document.createElement('link');
      link.setAttribute('id', 'font-url');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('href', `https://fonts.googleapis.com/css?family=${fontName}&display=swap`);
      document.getElementsByTagName('head')[0].insertBefore(link, document.getElementsByTagName('link')[0]);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export default importFonts;
