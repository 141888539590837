/* eslint-disable max-len */
import React from 'react';

function ProfileIcon(props) {
  return (
    <svg {...props} width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#000000" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(4.9, 1)">
        <path d="M5.57785529,15.2327889 C6.90544774,16.0753815 8.36937628,16.4966778 9.96964093,16.4966778 C11.5699056,16.4966778 13.0540965,16.0753815 14.4222137,15.2327889 C16.5240212,16.6382625 17.8472224,17.8267062 18.3918175,18.7981202 C18.9364126,19.7695341 19.2206604,21.4483553 19.2445607,23.8345837 C19.0047655,24.4479453 18.7205177,24.8442948 18.3918175,25.0236321 C15.3694354,26.6726297 11.714828,26.7779938 9.96964093,26.7779938 C8.28974829,26.7779938 4.38894986,26.5628815 1.53551098,25.0236321 C1.30958926,24.9017616 1.02928721,24.5054122 0.694604855,23.8345837 C0.704377595,21.447225 0.984679638,19.7684039 1.53551098,18.7981202 C2.08634233,17.8278365 3.43379043,16.6393927 5.57785529,15.2327889 Z" />
        <circle cx="9.98437482" cy="9" r="6" />
      </g>
    </svg>
  );
}

export default ProfileIcon;
