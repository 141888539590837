import './sectioninputs.scss';
import React from 'react';

import NextSection from '../../../components/buttons/NextSection';
import Checkbox from '../../../components/inputs/Checkbox';
import DateTextInput from '../../../components/inputs/DateTextInput';
import DecimalInput from '../../../components/inputs/DecimalInput/DecimalInput';
import EmailTextInput from '../../../components/inputs/EmailTextInput';
import HourTextInput from '../../../components/inputs/HourTextInput';
import Input from '../../../components/inputs/Input';
import NumberInput from '../../../components/inputs/NumberInput';
import TextInput from '../../../components/inputs/TextInput';
import Section from '../../../components/Section/Section';

function SectionInputs(props) {
  return (
    <Section {...props}>
      <h1>Inputs</h1>
      <div className="inputs-container">
        <Input
          label="simple text"
          placeholder="hello!"
        />
        <TextInput
          label="text with pattern"
          placeholder="letters only"
          pattern={/^[a-zA-Z]+$/g}
          message="text must be letters only"
        />
        <Input label="password" type="password" />
        <Input label="color" type="color" />
        <Input label="file" type="file" />
        <Input
          label="button"
          type="button"
          value="test"
        />
        <Checkbox label="checkbox" required />
        <Checkbox label="checkbox" required defaultChecked noborder />
        <DateTextInput required />
        <EmailTextInput required />
        <Input label="password" type="password" required />
        <HourTextInput required />
        <NumberInput required />
        <DecimalInput required />
      </div>
      <NextSection />
    </Section>
  );
}

export default SectionInputs;
