import './linkbar.scss';
import React from 'react';

import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import routes from '../../config/routes';

function Linkbar({ className, ...otherProps }) {
  const location = useLocation();
  return (
    <nav className={`topnavbar ${className}`} {...otherProps}>
      {routes.map((route) => route.topnavbar && (
        <Link
          key={route.name}
          to={route.path}
          className={location.pathname === route.path ? 'active' : null}
        >
          <div className="text-wrapper">
            {route.name}
            <div className="underline" />
          </div>
        </Link>
      ))}
    </nav>
  );
}

Linkbar.propTypes = {
  className: PropTypes.string,
};

Linkbar.defaultProps = {
  className: '',
};

export default Linkbar;
