import './editworkoutform.scss';
import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';

import setExercisesAPI from '../../../../api/setExercisesAPI';
import Button from '../../../../components/buttons/Button';
import SaveButton from '../../../../components/buttons/SaveButton';
import DeleteModal from '../../../../components/DeleteModal';
import DropDown from '../../../../components/DropDown/DropDown';
import Input from '../../../../components/inputs/Input';
import UserContext from '../../../../contexts/UserContext';
import SelectSetModal from '../SelectSetModal';
import deleteWorkout from './deleteWorkout';

function EditWorkoutForm({ workout, closeModal }) {
  const { user, setUser } = useContext(UserContext);
  // Define default values when adding a new workout (workout === null)
  const newWorkout = workout || {
    name: user.sport.currentExercise,
    time: Date.now(),
    sets: [],
    restTime: 120,
    bodyweight: user.profile.bodyweight,
    addedWeight: 0,
    day: 1,
  };

  const [newName, setNewName] = useState(newWorkout.name || '');
  const handleNewName = (event) => {
    setNewName(event.target.value);
  };

  const date = new Date(newWorkout.time || '');
  /** Format is "mm/dd/yyyy" */
  // const newDateInvalidChars = /[^\d/]/g;
  // const hasNewDateOnlyValidChars = (value) => !newDateInvalidChars.test(value);
  const isNewDateFormatValid = (value) => /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(value);
  const [newDate, setNewDate] = useState(
    date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' }),
  );
  const [isNewDateInputValid, setIsNewDateInputValid] = useState(true);
  const handleNewDate = ({ target: { value } }) => {
    setIsNewDateInputValid(true);
    setNewDate(value);
  };
  const handleNewDateFormatValidation = () => setIsNewDateInputValid(isNewDateFormatValid(newDate));

  // const newHoursInvalidChars = /[^\d\s:apmAPM]/g;
  // const hasNewHourOnlyValidChars = (value) => !newHoursInvalidChars.test(value);
  /** Format is "hh:mm:ss AM/PM" */
  const isNewHourFormatValid = (value) => /^(0?[1-9]|1[0-2]):(0[1-9]|[12345][0-9]):(0[0-9]|[12345][0-9])\s(am|AM|pm|PM)$/.test(value); // eslint-disable-line
  const [newHour, setNewHour] = useState(date.toLocaleTimeString('en-US'));
  const [isNewHourInputValid, setIsNewHourInputValid] = useState(true);
  const handleNewHour = (event) => {
    setIsNewHourInputValid(true);
    setNewHour(event.target.value);
  };
  const handleNewHourFormatValidation = () => setIsNewHourInputValid(isNewHourFormatValid(newHour));

  /** Format is "12 34 56..." */
  const isNewSetsFormatValid = (value) => /^(\d+\s*)+$/.test(value);
  const [newSets, setNewSets] = useState(newWorkout?.sets?.join(' ') ?? '');
  const [isNewSetsInputValid, setIsNewSetsInputValid] = useState(true);
  const handleNewSets = (event) => {
    setIsNewSetsInputValid(true);
    setNewSets(event.target.value);
  };
  const handleNewSetsFormatValidation = () => setIsNewSetsInputValid(isNewSetsFormatValid(newSets));

  const isNewRestTimeFormatValid = (value) => /^\d+$/.test(value);
  const [newRestTime, setNewRestTime] = useState(newWorkout?.restTime?.toString() ?? '');
  const [isNewRestTimeInputValid, setIsNewRestTimeInputValid] = useState(true);
  const handleNewRestTime = (event) => {
    setIsNewRestTimeInputValid(true);
    setNewRestTime(event.target.value);
  };
  const handleNewRestTimeFormatValidation = () => setIsNewRestTimeInputValid(isNewRestTimeFormatValid(newRestTime));

  const isNewBodyweightFormatValid = (value) => /^\d+([.,]\d)*$/.test(value);
  const [newBodyweight, setNewbodyWeight] = useState(newWorkout?.bodyweight?.toString() ?? '');
  const [isNewBodyweightInputValid, setIsNewBodyweightInputValid] = useState(true);
  const handleNewBodyweight = (event) => {
    setIsNewBodyweightInputValid(true);
    setNewbodyWeight(event.target.value);
  };
  const handleNewBodyweightFormatValidation = () => setIsNewBodyweightInputValid(isNewBodyweightFormatValid(newBodyweight));

  const isNewAddedWeightFormatValid = (value) => /^\d+([.,]\d)*$/.test(value);
  const [newAddedWeight, setNewAddedWeight] = useState(newWorkout?.addedWeight?.toString() ?? '');
  const [isNewAddedWeightInputValid, setIsNewAddedWeightInputValid] = useState(true);
  const handleNewAddedWeight = (event) => {
    setIsNewAddedWeightInputValid(true);
    setNewAddedWeight(event.target.value);
  };
  const handleNewAddedWeightFormatValidation = () => setIsNewAddedWeightInputValid(isNewAddedWeightFormatValid(newAddedWeight));

  const [newDay, setNewDay] = useState(newWorkout.day ?? 'free-sets');
  const [newProgram, setNewProgram] = useState(newWorkout.day === 'free-sets' ? 'free-sets' : `day ${newDay}`);
  const [isProgramModalOpen, setIsProgramModalOpen] = useState(false);
  const handleNewProgram = (event) => {
    if (event.target.value !== 'free-sets') {
      setIsProgramModalOpen(true);
    } else {
      setNewProgram('free-sets');
    }
  };
  const handleNewDay = (day) => {
    setNewDay(day);
    setNewProgram(`${day === 'free-sets' ? 'free-sets' : `day: ${day}`}`);
    setIsProgramModalOpen(false);
  };

  const isSaveButtonDisabled = !(
    newDate !== ''
    && isNewDateFormatValid(newDate)
    && newHour !== ''
    && isNewHourFormatValid(newHour)
    && newSets !== ''
    && isNewSetsFormatValid(newSets)
    && newRestTime !== ''
    && isNewRestTimeFormatValid(newRestTime)
    && newAddedWeight !== ''
    && isNewAddedWeightFormatValid(newAddedWeight)
  );

  const handleSave = () => {
    if (isSaveButtonDisabled) return null;

    let newExercises;
    setUser((prevUser) => {
      const exercises = JSON.parse(prevUser.sport.exercises);
      const newEntry = {
        time: new Date(`${newDate} ${newHour}`).getTime(),
        day: newDay,
        restTime: parseFloat(newRestTime),
        addedWeight: parseFloat((newAddedWeight).toString().replace(',', '.')),
        bodyweight: parseFloat((newBodyweight).toString().replace(',', '.')),
        sets: newSets.split(' ').map((set) => parseFloat(set)),
      };

      /**
       * When editing  an existating workout we need to
       * remove the old entry as it could be moved to another exercise
       * */
      let historyWithEntryToRemove;
      if (workout) {
        historyWithEntryToRemove = exercises[newWorkout.name].history;
        const entryToRemoveIndex = historyWithEntryToRemove.findIndex((entry) => entry.time === newWorkout.time);
        historyWithEntryToRemove.splice(entryToRemoveIndex, 1);
      }
      // Insert new entry in the good history
      const historyWithNewEntry = exercises[newName].history;
      historyWithNewEntry.push(newEntry);
      historyWithNewEntry.sort((a, b) => (a.time - b.time));

      newExercises = { ...exercises };

      if (workout) {
        newExercises[newWorkout.name] = {
          ...exercises[newWorkout.name],
          history: historyWithEntryToRemove,
        };
      }

      newExercises[newName] = {
        ...exercises[newName],
        history: historyWithNewEntry,
      };
      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser.sport,
          exercises: JSON.stringify(newExercises),
        },
      };
      return newUser;
    });

    return setExercisesAPI(user._id, JSON.stringify(newExercises), 'workout edit saved');
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const handleDeleteWorkout = () => {
    deleteWorkout(newWorkout, { user, setUser });
    setIsDeleteModalOpen(false);
    closeModal();
  };

  const dateToNumericString = (d) => d.toLocaleDateString(
    'en-US',
    { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' },
  );
  const exercises = JSON.parse(user.sport.exercises);
  const exercisesNames = Object.keys(exercises);
  return (
    <form className="edit-workout-form">
      <DropDown
        label="exercise"
        name="exercise"
        value={newName}
        values={exercisesNames}
        onChange={handleNewName}
      />
      <Input
        label="date"
        name="date"
        placeholder="01/01/2022"
        required
        value={dateToNumericString(date)}
        onChange={handleNewDate}
        onBlur={handleNewDateFormatValidation}
        invalid={!isNewDateInputValid}
        message={!isNewDateInputValid ? 'date must be like "mm/dd/yyyy"' : null}
      />
      <Input
        label="hour"
        name="hour"
        required
        placeholder="02:34:56 PM"
        value={newHour}
        onChange={handleNewHour}
        onBlur={handleNewHourFormatValidation}
        invalid={!isNewHourInputValid}
        message={!isNewHourInputValid ? 'hour must be like "hh:mm:ss AM/PM"' : null}
      />
      <Input
        label="sets"
        name="sets"
        required
        placeholder="8 8 7 7 6..."
        inputMode="decimal"
        value={newSets}
        onChange={handleNewSets}
        onBlur={handleNewSetsFormatValidation}
        invalid={!isNewSetsInputValid}
        message={!isNewSetsInputValid ? 'sets must be numbers separated by spaces' : null}
      />
      <Input
        label="rest time"
        name="rest time"
        required
        placeholder="120"
        inputMode="decimal"
        value={newRestTime}
        onChange={handleNewRestTime}
        onBlur={handleNewRestTimeFormatValidation}
        invalid={!isNewRestTimeInputValid}
        message={!isNewRestTimeInputValid ? 'rest time must be a number' : null}
      />
      {!workout || exercises[newWorkout.name].isBodyweight ? (
        <Input
          label="bodyweight"
          name="bodyweight"
          required
          placeholder="75"
          inputMode="decimal"
          value={newBodyweight}
          onChange={handleNewBodyweight}
          onBlur={handleNewBodyweightFormatValidation}
          invalid={!isNewBodyweightInputValid}
          message={!isNewBodyweightInputValid ? 'bodyweight must be a number' : null}
        />
      ) : null}
      <Input
        label="added weight"
        name="added weight"
        required
        placeholder="10"
        inputMode="decimal"
        value={newAddedWeight}
        onChange={handleNewAddedWeight}
        onBlur={handleNewAddedWeightFormatValidation}
        invalid={!isNewAddedWeightInputValid}
        message={!isNewAddedWeightInputValid ? 'addded weight must be a number' : null}
      />
      <Button onClick={handleNewProgram} className="program-button">
        {newProgram}
      </Button>
      <Button onClick={openDeleteModal} className={`delete-button${workout ? ' visible' : ' hidden'}`}>
        Delete
      </Button>
      <SaveButton
        onClick={handleSave}
        submit
        timeoutCallback={closeModal}
        disabled={isSaveButtonDisabled}
        className="save-button"
      >
        Save
      </SaveButton>
      <SelectSetModal
        isOpen={isProgramModalOpen}
        setIsOpen={setIsProgramModalOpen}
        onSelect={handleNewDay}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        title="Delete Workout"
        name="this workout"
        onDelete={handleDeleteWorkout}
      />
    </form>
  );
}

EditWorkoutForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  workout: PropTypes.shape({
    name: PropTypes.any,
    time: PropTypes.number,
    sets: PropTypes.array,
    restTime: PropTypes.number,
    addedWeight: PropTypes.number,
    bodyweight: PropTypes.number,
    day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
};

EditWorkoutForm.defaultProps = {
  workout: {
    name: '',
    time: 0,
    sets: [],
    restTime: 0,
    addeddWeight: 0,
    bodyweight: 0,
    day: 'free-sets',
  },
};

export default EditWorkoutForm;
