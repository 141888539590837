import './histogram.scss';

import React, { useState, useMemo } from 'react';

import PropTypes from 'prop-types';

import Label from './Label';
import useClickOutside from '../../hooks/useClickOutside';
import { convertColorToRGBValues } from '../../lib/colors';
import historyJS from '../../lib/historyJS';
import replaceSpecialChars from '../../lib/replaceSpecialChars';
import Tooltip from '../svgs/Tooltip';

const MemoizedTooltip = React.memo(Tooltip);
const MemoizedLabel = React.memo(Label);
function Histogram({ // eslint-disable-line prefer-arrow-callback
  histories, // Array of history array of object with time and values
  dynamicColumns = true,
  showingDays = 31, // graph period in days
  containerWidth = 300, // Viewbox width throught which the graph will scroll
  height = 200, // Graph height in pixels
  fontSize = 16,
  showName = true,
  showColors = true,
  groupBy = showingDays <= 7 ? 'day' : showingDays <= 31 ? 'day' : showingDays <= 92 ? 'week' : 'month',
  noDataMessage = (
    <>
      <span>Start today&nbsp;</span>
      <span>and get your body in shape :)</span>
    </>
  ),
  showTooltip = true, // Trigger the automatic display of a Tooltip when its column is in the middle
  middleTime = 0, // Needed for showTooltip to know if a Tooltip is in the middle
  defaultColor = '#565763',
  ...otherProps
}) {
  // Keep track of the tooltips visiblity to avoid unecessary display/hideTolltip trigger on scroll
  // const [tooltipsVisibility, setTooltipsVisibility] = useState({});
  const [tooltipIdVisible, setTooltipIdVisible] = useState(null);
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const selectedExerciseName = selectedColumnName?.match(/.+?(?=-[0-9]{13})/)?.[0]; // remove time number
  const [previousMiddleTime, setPreviousMiddleTime] = useState(middleTime);
  const hasScrolled = useMemo(() => {
    if (middleTime !== previousMiddleTime) {
      setPreviousMiddleTime(middleTime);
      return true;
    }
    return false;
  }, [middleTime, previousMiddleTime]);

  useClickOutside({
    selectors: ['.history-column', '.button', 'button', '[role=button]'],
    action: () => {
      setSelectedColumnName(null);
      setTooltipIdVisible(null);
    },
  });

  const groupedHistories = useMemo(
    () => histories.map((history) => historyJS.groupHistory(history, groupBy)), // groupedHistories
    [groupBy, histories],
  );
  const fusedHistory = useMemo(() => (
    groupedHistories
      .flatMap(({ data, ...restOfGroupedHistory }) => (
        data.map((entry) => ({
          ...restOfGroupedHistory,
          ...entry,
        }))
      ))
      .sort((a, b) => a.time - b.time) // flatGroupedHistories
      .reduce((consolidatedEntry, entry, i) => {
        if (i === 0) {
          consolidatedEntry[0].time = entry.time; // eslint-disable-line no-param-reassign
        }
        if (entry.time === consolidatedEntry.at(-1).time) {
          consolidatedEntry.at(-1).data.push(entry);
        } else {
          consolidatedEntry.push({
            time: entry.time,
            data: [entry],
          });
        }
        return consolidatedEntry;
      }, [{
        time: 0,
        data: [],
      }])
  ), [groupedHistories]);

  /*
    histories = [{
      name: '1rst History',
      color: 'var(--red, red)',
      data: [{
        time: 123456789,
        values: [12, 34, 56, 78, 90],
      }]
    },{
      name: '2nd History',
      color: 'var(--red, red)',
      data: [{
        time: 123456789,
        values: [09,87,65,43,21],
      }]
    }]

    groupedHistories = same structure as histories = [{
      name: '1rst History',
      color: 'var(--red, red)',
      data: [{
        time: 123456789,
        values: [12, 34, 56, 78, 90],
      }]
    },{
      name: '2nd History',
      color: 'var(--red, red)',
      data: [{
        time: 123456789,
        values: [09,87,65,43,21],
      }]
    }]

    flatGroupedHistory = [{
      name: '1rst History',
      time: 123456789,
      values: [12, 34, 56, 78, 90],
      color: 'var(--red, red)',
    },{
      name: '2nd History',
      time: 123456789,
      values: [09,87,65,43,21],
      color: 'var(--red, red)',
    }]

    fusedHistory = [{
      time: 123456789,
      data: [
        {
          name: '1rst History',
          time: 123456789,
          values: [12,34,56,78,90],
        },{
          name: '2nd History',
          time: 123456789,
          values: [09,87,65,43,21],
        }
      ]
    }]
  */

  const period = showingDays <= 7 ? 'one_week'
    : showingDays <= 31 ? 'one_month'
      : showingDays <= 92 ? 'three_months'
        : 'one_year';
  // Keep now-based values from changing between renders
  const endOfTimeline = useMemo(() => historyJS.getTimelineEndTime(period, groupBy), [period, groupBy]);
  /**
   * Memoize value of graphStartingTime to keep it unchanged
   * when selecting a column that will modify the history data
   */
  const graphStartingTime = useMemo(
    () => historyJS.getTimelineStartTime(fusedHistory[0]?.time, period),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // fusedHistory, /* => We don't want it, to keep timeline starting time from changing when selecting a column  */
      period,
    ],
  );

  /** Get visible history to set maximum column height */
  const tooltipOffset = 10; // Little triangle height
  const tooltipsGap = 3; // The space between the top of the column and the value
  const spaceAboveForTooltips = showTooltip ? (fontSize * 4 + tooltipOffset + tooltipsGap) : 0;
  const reflectToHeightRatio = 1 / 5;
  const reflectOpacity = 0.2;
  const maxColumnHeight = height * (1 - reflectToHeightRatio) - spaceAboveForTooltips;
  const maxReflectHeight = (height - spaceAboveForTooltips) * reflectToHeightRatio;
  // (height - spaceAboveForTooltips) / (1 + reflectToHeightRatio);
  const filteredHistories = useMemo(() => groupedHistories.filter((history) => (
    !selectedColumnName
    || (selectedColumnName && history.name === selectedColumnName.match(/.+?(?=-[0-9]{13})/)[0])// remove time number
  )), [groupedHistories, selectedColumnName]);
  // Use filteredHistories to get new column height when one column is selected.
  const maxReps = useMemo(() => historyJS.getMaxReps(filteredHistories), [filteredHistories]);
  const heightRatio = maxColumnHeight / maxReps; // heightRatio = 1 => 1px height for 1 rep.
  const reflectRatio = maxReflectHeight / maxReps;

  const convertTimeToPixels = (duration) => duration * (containerWidth / (showingDays * 1000 * 60 * 60 * 24));
  const getXPosition = (time) => convertTimeToPixels(time - graphStartingTime);

  const histogramWidth = getXPosition(endOfTimeline);
  const graphWidth = histogramWidth > containerWidth ? histogramWidth : containerWidth;

  const timeline = useMemo(
    () => historyJS.getTimelineLabelTimes(graphStartingTime, period, groupBy),
    [graphStartingTime, groupBy, period],
  );

  const tooltips = [];
  const graphHistory = fusedHistory.flatMap((group) => {
    const groupColumns = group.data.map((exercise, columnNumber) => {
      /** Set column width to correctly fit with the visible period */
      /**
       * 1 column width = visible width / showingDays * 2/3
       * total graph width = number of days * column width * 3/2 = number of days * visible width / showingDays
       */
      const spaceRatioBetweenColumns = 1 / 6;
      const groupWidth = historyJS.getGroupWidth(containerWidth, period, groupBy, group.time);
      const spaceBeforeColumns = groupWidth * (spaceRatioBetweenColumns / 2);

      const column = [];
      const reflect = [];
      // Set the inner columns width
      let numberOfColumns;
      const showOnlySelectedExercise = selectedExerciseName === exercise.name;
      if (showOnlySelectedExercise) {
        numberOfColumns = 1;
      } else if (dynamicColumns && group.data.length <= 1) {
        numberOfColumns = 1;
      } else if (dynamicColumns && group.data.length > 1) {
        numberOfColumns = group.data.length;
      } else if (!dynamicColumns && histories.length <= 1) {
        numberOfColumns = 1;
      } else if (!dynamicColumns && histories.length > 1) {
        numberOfColumns = histories.length;
      }
      const innerColumnWidth = ((groupWidth - spaceBeforeColumns) / numberOfColumns) - spaceBeforeColumns;
      const xPosition = getXPosition(exercise.time);
      // Shift the next columns if multiple columns for the same group
      const columnShift = (showOnlySelectedExercise ? 0 : columnNumber) * (innerColumnWidth + spaceBeforeColumns);

      const rgbValues = convertColorToRGBValues(
        (showColors && exercise.color) ? exercise.color : defaultColor,
      ).toString();
      const normalizedName = replaceSpecialChars(exercise.name);// Used for ids
      const x = xPosition + spaceBeforeColumns + columnShift;
      const tooltipId = `tooltip-${normalizedName}-${exercise.time}`;
      let total = 0;
      let set;
      const setsNumber = exercise.values.length;
      const showColumn = !selectedExerciseName || showOnlySelectedExercise;
      for (let i = 0; i < setsNumber; i += 1) {
        set = parseFloat(exercise.values[i]);
        total += set;
        const columnY = -total * heightRatio; // The y-axis is turned top-to-bottom, so to go above we need negative values
        /**
         * Make tooltip Y constant between visible/hidden.
         * We dont want spaceAboveForTooltips = 0 when tooltip is hidden
         * because for unknown reasons, transition on "y" atttribut does not work.
         * tooltipY = columnY
         *          = -total * heightRatio
         *          = -total * maxColumnHeight / maxReps
         *          = -total * (height * (1 - reflectToHeightRatio) - spaceAboveForTooltips) / maxReps
         *          = -total * (height * (1 - reflectToHeightRatio) - (fontSize * 4 + tooltipOffset + tooltipsGap)) / maxReps
         */
        // eslint-disable-next-line max-len
        const tooltipY = -total * ((height * (1 - reflectToHeightRatio) - (fontSize * 4 + tooltipOffset + tooltipsGap)) / maxReps);
        // Add Tooltip at the same time as the last value to get the height of the column
        if (i === setsNumber - 1) {
          tooltips.push(
            <MemoizedTooltip
              key={tooltipId}
              id={tooltipId} // Set id to handle tooltip visibility
              position="top"
              offset={tooltipOffset}
              x={x + innerColumnWidth / 2}
              y={tooltipY - tooltipsGap}
              className={`svg-tooltip${tooltipIdVisible === tooltipId && showColumn && showTooltip ? ' visible' : ' hidden'}`}
            >
              {showName ? <tspan x="0" dy="-0.6em">{exercise.name}</tspan> : ''}
              <tspan x="0" dy="1.2em">
                {`${exercise.values.reduce((total2, set2) => total2 + parseFloat(set2))} kg`}
              </tspan>
            </MemoizedTooltip>,
          );
        }
        const columnHeight = set * heightRatio;
        column.unshift(<rect
          key={`column-${exercise.time}-${i}`}
          x={x}
          y={showColumn ? columnY : 0}
          width={innerColumnWidth}
          height={showColumn ? columnHeight : 0}
          fill={`rgba(${rgbValues}, ${1 - i / setsNumber})`}
        />);
        const reflectHeight = set * reflectRatio;
        const reflectY = total * reflectRatio - reflectHeight;
        reflect.unshift(<rect
          key={`reflect-${exercise.time}-${i}`}
          x={x}
          y={showColumn ? reflectY : 0}
          width={innerColumnWidth}
          height={showColumn ? reflectHeight : 0}
          fill={`rgba(${rgbValues}, ${(1 - i / setsNumber) * reflectOpacity})`}
        />);
      }

      const middlePosition = getXPosition(middleTime);
      const columnInTheMiddle = (x < middlePosition) && (middlePosition < (x + innerColumnWidth));
      if (
        showTooltip
        && hasScrolled
        && tooltipIdVisible !== tooltipId // This tooltip is not already visible
        && columnInTheMiddle
      ) {
        setTooltipIdVisible(tooltipId);
      } else if (
        showTooltip
        && hasScrolled
        && tooltipIdVisible === tooltipId // Only if the tooltip is visible
        && !columnInTheMiddle // And not in the middle anymore
      ) {
        setTooltipIdVisible(null);
      }

      const handleColumnClick = ({ target }) => {
        if (showTooltip) {
          setTooltipIdVisible(tooltipId);
        }
        setSelectedColumnName(target.closest('.history-column').getAttribute('name'));
      };

      return (
        <g
          name={`${exercise.name}-${exercise.time}`}
          className="history-column"
          key={`${exercise.name}-${exercise.time}`}
          onClick={handleColumnClick}
        >
          {[reflect, column]}
        </g>
      );
    });
    return groupColumns;
  });

  /**
 * Conditionnal rendering MUST be placed after all hooks because hooks cannot be executed conditionnaly
 * But the higher the better to avoid useless calculations
 */
  if (!graphStartingTime || maxReps === 0) {
    return (
      <div
        className="histogram no-data-message"
        style={{ height }}
      >
        {noDataMessage}
      </div>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={graphWidth}
      height={height}
      /** viewBox="origin-x-position origin-y-position width-zoom height-zoom" */
      viewBox={`0 ${-(height * (1 - reflectToHeightRatio))} ${graphWidth} ${height}`}
      className="histogram"
      {...otherProps}
    >
      <g name="history">{graphHistory}</g>
      <g name="tooltips">{tooltips}</g>
      <g name="labels">
        {timeline && timeline.map((labelTime) => (
          <MemoizedLabel
            key={labelTime}
            x={getXPosition(labelTime)}
            y={0}
            time={labelTime}
            color="var(--gray3, rgb(199, 199, 204))"
            period={period}
            groupBy={groupBy}
          />
        ))}
      </g>
      <g name="timeline">
        <rect
          x={0}
          y={0}
          width={graphWidth}
          height={1}
          stroke="0"
          strokeWidth="0"
          fill="var(--border-color, rgb(229, 229, 234))"
        />
      </g>
    </svg>
  );
}

Histogram.propTypes = {
  histories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.shape({
        values: PropTypes.arrayOf(PropTypes.number),
        time: PropTypes.number,
      })),
    }),
  ), // [[name, data: [time, values], color]]
  showingDays: PropTypes.number,
  dynamicColumns: PropTypes.bool,
  containerWidth: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  showName: PropTypes.bool,
  showColors: PropTypes.bool,
  groupBy: PropTypes.string,
  noDataMessage: PropTypes.node,
  showTooltip: PropTypes.bool,
  middleTime: PropTypes.number,
  defaultColor: PropTypes.string,
};

Histogram.defaultProps = {
  histories: [], // Array of object with time and values
  dynamicColumns: true, // Ajust the width  of the columns to fill empty space
  showingDays: 31, // Graph period in days
  containerWidth: 300, // Graph width in pixels
  height: 200, // Graph height in pixels
  fontSize: 16,
  showName: true, // Show the name in the tooltip on top of columns
  showColors: true, // Show the color of columns
  groupBy: 'day', // day|week|month
  noDataMessage: (
    <>
      <span>Start today&nbsp;</span>
      <span>and get your body in shape :)</span>
    </>
  ),
  showTooltip: true,
  middleTime: 0,
  defaultColor: '#565763',
};

export default Histogram;
