import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Button from '../../../components/buttons/Button';
import Document from '../../../components/Document/Document';
import Section from '../../../components/Section/Section';
import DownIcon from '../../../components/svgs/DownIcon';

function SectionDocuments(props) {
  const [isDocumentOpen, setIsDocumentOpen] = useState(true);
  const toggleDocument = () => {
    setIsDocumentOpen((bool) => !bool);
  };

  const navigate = useNavigate();
  const goToTheme = () => navigate('/theme');

  return (
    <Section
      className="section-documents"
      colorizeButtons
      {...props}
    >
      <h1>Documents</h1>
      <Button
        onClick={toggleDocument}
        style={{ width: '100%' }}
      >
        {isDocumentOpen ? 'Close Document' : 'Open Document'}
      </Button>
      <br />
      <Document theme="dark" close={toggleDocument} isOpen={isDocumentOpen}>
        <h2>Document title</h2>
        <p>
          Documents displays information as a paper sheets.
        </p>
        <p>
          It has two Close button, top and bottom, +/- buttons to increase or decrease font size within itself,
          <br />
          and can be expanded and closed to liberate space on the page.
        </p>
        <p>
          Use multiple Documents after one another to create accordeon effect.
        </p>
      </Document>
      <Button
        aria-label="Go to Theme page"
        nopadding
        className="next-section"
        onClick={goToTheme}
      >
        <DownIcon />
      </Button>
    </Section>
  );
}

export default SectionDocuments;
