import './sectionbuttons.scss';
import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import BoxButton from '../../../components/buttons/BoxButton';
import Button from '../../../components/buttons/Button';
import NextSection from '../../../components/buttons/NextSection';
import SaveButton from '../../../components/buttons/SaveButton';
import Popup from '../../../components/Popup/Popup';
import Section from '../../../components/Section/Section';
import GearIcon from '../../../components/svgs/GearIcon';
import SVGTooltip from '../../../components/svgs/Tooltip';
import Tooltip from '../../../components/Tooltip/Tooltip';

function SectionButtons({ header, ...otherProps }) {
  const containerRef = useRef();
  const containerElement = containerRef.current;
  return (
    <Section {...otherProps}>
      {header}
      {[
        { name: 'normal' },
        { name: 'normal border', border: true },
        { name: 'round', round: true },
        { name: 'round border', round: true, border: true },
        { name: 'disabled', disabled: true },
        { name: 'disabled border', disabled: true, border: true },
        { name: 'inverse', inverse: true },
        { name: 'inverse border', inverse: true, border: true },
      ].map(({ name, ...props }) => (
        <div key={name} className="flex-container" ref={containerRef}>
          <Button {...props}>{name}</Button>
          <BoxButton
            label="box"
            boxStyle={{ width: '300px' }}
            container={containerElement}
            {...props}
          >
            <h3>Information</h3>
            <p>
              This popup will move itself at the appropriate place depending on the space available,
              and adapt its width if the space available is too small, like on mobile devices.
            </p>
          </BoxButton>
          <BoxButton
            label="?"
            nopadding
            notriangle
            boxStyle={{ width: '300px' }}
            container={containerElement}
            {...props}
          >
            <h3>Popup</h3>
            <p>Help users to know that the button will pop up.</p>
            <p>You can do it with a question mark or a little triangle for example.</p>
          </BoxButton>
          <Button {...props} square nopadding><GearIcon /></Button>
          <SaveButton onClick={() => true} {...props}>button with feedback</SaveButton>
          <SaveButton onClick={() => true} nopadding {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <GearIcon />
              <div style={{ margin: '0 1rem' }}>success with svg icon</div>
            </div>
          </SaveButton>
          <SaveButton onClick={() => false} {...props}>feedback fail</SaveButton>
          <SaveButton onClick={() => false} nopadding {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <GearIcon />
              <div style={{ margin: '0 1rem' }}>fail with svg icon</div>
            </div>
          </SaveButton>
        </div>
      ))}
      <div className="flex-container">
        <span className="hover-me hover">
          html tooltip
          <Tooltip>
            reveal a simple tooltip
          </Tooltip>
        </span>
        <span className="hover-me hover">
          tooltip
          <Tooltip center>
            tooltips can be centered
          </Tooltip>
        </span>
        <span className="hover-me hover">
          tooltip
          <Tooltip bottom>
            or at the bottom
          </Tooltip>
        </span>
      </div>
      <div className="flex-container">
        <div className="flex-container">
          <span className="hover-me hover">
            svg tooltip
            <SVGTooltip
              className="svg-tooltip right"
              position="right"
            >
              this one look similar...
            </SVGTooltip>
          </span>
        </div>
        <div className="flex-container">
          <span className="hover-me hover">
            svg tooltip
            <SVGTooltip
              className="svg-tooltip top"
              position="top"
            >
              ...but is in fact quite different...
            </SVGTooltip>
          </span>
        </div>
        <div className="flex-container">
          <span className="hover-me hover">
            svg tooltip
            <SVGTooltip
              className="svg-tooltip bottom"
              position="bottom"
            >
              ...it is made with SVG...
            </SVGTooltip>
          </span>
        </div>
        <div className="flex-container">
          <span className="hover-me hover">
            svg tooltip
            <SVGTooltip
              className="svg-tooltip left"
              position="left"
            >
              ...and can be used within graphs ;)
            </SVGTooltip>
          </span>
        </div>
      </div>
      <div className="rigid-container">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Popup
            hover
            element={<span className="hover-me">dynamic popup</span>}
            boxStyle={{ width: '15rem' }}
            containerSelector=".rigid-container"
          >
            Popups are much more intelligent than tooltips.
            <br />
            For example, they can be constrained by containers.
          </Popup>
          <Popup
            hover
            element={<span className="hover-me">hover me</span>}
            boxStyle={{ width: '220px' }}
            containerSelector=".rigid-container"
          >
            This one appears at the bottom left because there no space above or on the right hand side.
          </Popup>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Popup
            hover
            center
            element={<span className="hover-me">hover me</span>}
            boxStyle={{ width: '10rem', textAlign: 'center' }}
            containerSelector=".rigid-container"
          >
            I am centered.
          </Popup>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Popup
            hover
            element={<span className="hover-me">hover me</span>}
            boxStyle={{ width: '250px' }}
            containerSelector=".rigid-container"
          >
            If there is too much content,
            <br />
            and too little space.
            <br />
            <br />
            This popup has the ability to be scrollable.
            <br />
            <br />
            Top and bottom bars appear and disappear to help you know if there is more to read.
          </Popup>
          <Popup
            element={<span className="hover-me">click me</span>}
            boxStyle={{ width: '17rem' }}
            containerSelector=".rigid-container"
          >
            Popup can appear on click.
            <br />
            This way, the popup is still open when the mouse leave it.
            <br />
            Click anywhere outside to close it.
          </Popup>
        </div>
      </div>
      <NextSection />
    </Section>
  );
}

SectionButtons.propTypes = {
  header: PropTypes.node,
};

SectionButtons.defaultProps = {
  header: [],
};

export default SectionButtons;
