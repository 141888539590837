import './overflowbottom.scss';
import React from 'react';

import PropTypes from 'prop-types';

import Triangle from '../svgs/Triangle';

function OverflowBottom({ showBottomTriangle }) {
  return (
    <div className={`overflow-bottom${!showBottomTriangle ? ' hidden' : ''}`}>
      <Triangle className={!showBottomTriangle ? 'hidden' : ''} />
    </div>
  );
}

OverflowBottom.propTypes = {
  showBottomTriangle: PropTypes.bool.isRequired,
};

export default OverflowBottom;
