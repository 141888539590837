import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import setCurrentExerciseAPI from '../../../../api/setCurrentExerciseAPI';
import setSelectedExercisesAPI from '../../../../api/setSelectedExercisesAPI';
import MenuButton from '../../../../components/buttons/MenuButton';
import UserContext from '../../../../contexts/UserContext';
import MenuList from './MenuList';

function TitleMenu({ currentExercise, container }) {
  const { user, setUser } = useContext(UserContext);
  const exercises = JSON.parse(user.sport.exercises);
  // Make a sorted list of unique category's names, with undefined and empty string as "uncategorized"
  const categoriesNames = Object.keys(exercises).reduce((categories, exercise) => [...new Set([
    ...categories,
    ...(
      exercises[exercise]
        && exercises[exercise].categories
        && exercises[exercise].categories[0] !== ''
        ? exercises[exercise].categories
        : ['uncategorized']
    ),
  ])], []).sort();

  const handleClick = async (event) => {
    const newCurrentExercise = event.target.closest('.item').getAttribute('value');

    setUser((prevUser) => {
      const prevSelectedExercises = prevUser.sport.selectedExercises || [prevUser.sport.currentExercise];
      const newSelectedExercises = [...new Set([...prevSelectedExercises, newCurrentExercise])]; // Be sure values are unique
      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser.sport,
          currentExercise: newCurrentExercise,
          selectedExercises: newSelectedExercises,
        },
      };

      if (navigator.onLine && !!localStorage.getItem('token')) {
        setCurrentExerciseAPI(prevUser._id, newCurrentExercise);
        setSelectedExercisesAPI(prevUser._id, newSelectedExercises);
      }

      return newUser;
    });
  };

  const handleSelect = async (event) => {
    event.stopPropagation(); // Prevent handleClick from happening

    const newSelectedExercise = event.target.closest('.item').getAttribute('value');
    let newSelectedExercises = [];
    setUser((prevUser) => {
      const prevSelectedExercises = prevUser.sport.selectedExercises || [prevUser.sport.currentExercise];
      newSelectedExercises = event.target.checked
        ? [...new Set([...prevSelectedExercises, newSelectedExercise])]
        : prevSelectedExercises.filter((exercise) => exercise !== newSelectedExercise);

      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser.sport,
          selectedExercises: newSelectedExercises,
        },
      };

      return newUser;
    });

    if (navigator.onLine && !!localStorage.getItem('token')) {
      setSelectedExercisesAPI(user._id, newSelectedExercises);
    }
  };

  return (
    <MenuButton
      aria-label="Select your exercise"
      center
      label={currentExercise}
      labelStyle={{ display: 'inline', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '1.2rem' }}
      buttonStyle={{ width: '100%' }}
      boxStyle={{ width: '100vw' }} // As wide as possibble, but constrain by container
      container={container}
    >
      <MenuList
        items={exercises}
        categories={categoriesNames}
        handleClick={handleClick}
        handleSelect={handleSelect}
        checkedItems={user.sport.selectedExercises || [user.sport.currentExercise]}
        selectedItem={currentExercise}
        groupByCategory={user.sport.groupByCategory}
      />
    </MenuButton>
  );
}

TitleMenu.propTypes = {
  currentExercise: PropTypes.string.isRequired,
  container: PropTypes.instanceOf(Element),
};

TitleMenu.defaultProps = {
  container: document.body,
};

export default TitleMenu;
