import { useEffect } from 'react';

function useClickOutside({
  selectors = [], // CSS selector to detect element outside of whicj we click
  additionalCriteria = true, // Additional criteria to trigger action
  action = () => {}, // Action triggered when licked outsidde of element
}) {
  useEffect(() => {
    const closeOnClickOutside = (event) => {
      const isClickedOutside = selectors
        .reduce((result, selector) => result && !event.target.closest(selector), true);
      if (isClickedOutside && additionalCriteria) {
        action();
      }
    };
    document.addEventListener('click', closeOnClickOutside);
    return () => {
      document.removeEventListener('click', closeOnClickOutside);
    };
  }, [additionalCriteria, selectors, action]);
}

export default useClickOutside;
