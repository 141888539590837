/* eslint-disable no-console */
import fetchGraphQL from '../lib/fetchGraphQL';

/**
 * Save workout exercises.
 * @param {string} id User id.
 * @param {JSON} exercisesJSON Stringified workout exercises.
 * @param {string} logMessage Log the specific data thhat has been saved within the exercises object.
 * @returns {boolean} Is succesfully saved.
 */
async function setExercisesAPI(id, exercisesJSON, logMessage) {
  if (typeof id !== 'string') return console.error('"id" must be a string');
  if (typeof exercisesJSON !== 'string') return console.error('"exercisesJSON" must be a string');
  if (!['undefined', 'string'].includes(typeof logMessage)) return console.error('"logMessage" must be a string');

  return fetchGraphQL(`mutation { setExercises(
      _id: "${id}",
      exercises: ${JSON.stringify(exercisesJSON)}
    ) { matchedCount, modifiedCount, upsertedId, upsertedCount } }`)
    .then((response) => {
      if (logMessage && response.modifiedCount === 1) console.log(logMessage);
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}

export default setExercisesAPI;
