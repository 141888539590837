import Logo from '../components/svgs/Logo';

const company = {
  NAME: 'React Template',
  SHORT_NAME: 'React Template',
  LOGO: Logo,
  EMAIL: 'mathieu.valez@valezconsulting.com',
  DESCRIPTION: 'Demonstration of a React Single Page Application with user profile, theming and other things',
};

export default company;
