/* eslint-disable max-len */
function EditPencil(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      {...props}
    >
      <g
        stroke="#000000"
        fill="none"
        className="stroke"
      >
        <path d="m1,29l0,-8l20,-20l8,8l-20,20l-8,0z" />
        <line x1="19" y1="3" x2="27" y2="11" />
        <line x1="1" y1="21" x2="9" y2="29" />
      </g>
    </svg>
  );
}

export default EditPencil;
