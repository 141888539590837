import React, { useState, useLayoutEffect, useRef } from 'react';

import PropTypes from 'prop-types';

function topTooltipPath({ width, height, offset, radius }) {
  const left = -width / 2;
  const right = width / 2;
  const top = -offset - height;
  const bottom = -offset;
  return `M 0,0 
    L ${-offset},${bottom} 
    H ${left + radius}
    Q ${left},${bottom} ${left},${bottom - radius}  
    V ${top + radius}   
    Q ${left},${top} ${left + radius},${top}
    H ${right - radius}
    Q ${right},${top} ${right},${top + radius}
    V ${bottom - radius}
    Q ${right},${bottom} ${right - radius},${bottom}
    H ${offset} 
    L 0,0 z`;
}

function bottomTooltipPath({ width, height, offset, radius }) {
  const left = -width / 2;
  const right = width / 2;
  const bottom = offset + height;
  const top = offset;
  return `M 0,0 
    L ${-offset},${top} 
    H ${left + radius}
    Q ${left},${top} ${left},${top + radius}  
    V ${bottom - radius}   
    Q ${left},${bottom} ${left + radius},${bottom}
    H ${right - radius}
    Q ${right},${bottom} ${right},${bottom - radius}
    V ${top + radius}
    Q ${right},${top} ${right - radius},${top}
    H ${offset} 
    L 0,0 z`;
}

function leftTooltipPath({ width, height, offset, radius }) {
  const left = -offset - width;
  const right = -offset;
  const top = -height / 2;
  const bottom = height / 2;
  return `M 0,0 
    L ${right},${-offset} 
    V ${top + radius}
    Q ${right},${top} ${right - radius},${top}  
    H ${left + radius}   
    Q ${left},${top} ${left},${top + radius}
    V ${bottom - radius}
    Q ${left},${bottom} ${left + radius},${bottom}
    H ${right - radius}
    Q ${right},${bottom} ${right},${bottom - radius}
    V ${offset} 
    L 0,0 z`;
}
function rightTooltipPath({ width, height, offset, radius }) {
  const left = offset;
  const right = offset + width;
  const top = -height / 2;
  const bottom = height / 2;
  return `M 0,0
    L ${left},${-offset} 
    V ${top + radius}
    Q ${left},${top} ${left + radius},${top}  
    H ${right - radius}   
    Q ${right},${top} ${right},${top + radius}
    V ${bottom - radius}
    Q ${right},${bottom} ${right - radius},${bottom}
    H ${left + radius}
    Q ${left},${bottom} ${left},${bottom - radius}
    V ${offset} 
    L 0,0 z`;
}

function tooltipPath({ width, height, offset, radius, position }) {
  switch (position) {
    case 'top':
      return topTooltipPath({ width, height, offset, radius });
    case 'left':
      return leftTooltipPath({ width, height, offset, radius });
    case 'right':
      return rightTooltipPath({ width, height, offset, radius });
    case 'bottom':
      return bottomTooltipPath({ width, height, offset, radius });
    default:
      return topTooltipPath({ width, height, offset, radius });
  }
}

function Tooltip({
  x, y, width, height, offset, radius, position, fontSize, children, strokeWidth, stroke, fill, textColor, ...props
}) {
  const textRef = useRef();
  const [newWidth, setNewWidth] = useState(width);
  const [newHeight, setNewHeight] = useState(height);
  let viewBoxWidth;
  let viewBoxHeight;
  let textX; // Move the text to the center
  let textY; // Move the text to the center // Move the text to the center
  let newX; // Move the coordinates to the tip of the triangle
  let newY; // Move the coordinates to the tip of the triangle
  let viewBox; // Move the view to the center
  const descenderHeight = fontSize * 0.2;
  if (position === 'top') {
    viewBoxWidth = newWidth + 2 * strokeWidth;
    viewBoxHeight = newHeight + 2 * strokeWidth + offset;
    textX = 0;
    textY = -offset - newHeight / 2 - descenderHeight + fontSize / 2;
    viewBox = `${-viewBoxWidth / 2} ${-viewBoxHeight} ${viewBoxWidth} ${viewBoxHeight}`;
    newX = x - viewBoxWidth / 2;
    newY = y - viewBoxHeight;
  } else if (position === 'bottom') {
    viewBoxWidth = newWidth + 2 * strokeWidth;
    viewBoxHeight = newHeight + 2 * strokeWidth + offset;
    textX = 0;
    textY = offset + newHeight / 2 - descenderHeight + fontSize / 2;
    viewBox = `${-viewBoxWidth / 2} ${0} ${viewBoxWidth} ${viewBoxHeight}`;
    newX = x - viewBoxWidth / 2;
    newY = y;
  } else if (position === 'left') {
    viewBoxWidth = newWidth + 3 * strokeWidth + offset;
    viewBoxHeight = newHeight + 2 * strokeWidth;
    textX = -newWidth / 2 - offset;
    textY = -descenderHeight + fontSize / 2;
    viewBox = `${-viewBoxWidth} ${-viewBoxHeight / 2} ${viewBoxWidth} ${viewBoxHeight}`;
    newX = x - viewBoxWidth;
    newY = y - viewBoxHeight / 2;
  } else if (position === 'right') {
    viewBoxWidth = newWidth + 2 * strokeWidth + offset;
    viewBoxHeight = newHeight + 2 * strokeWidth;
    textX = newWidth / 2 + offset;
    textY = -descenderHeight + fontSize / 2;
    viewBox = `${0} ${-viewBoxHeight / 2} ${viewBoxWidth} ${viewBoxHeight}`;
    newX = x;
    newY = y - viewBoxHeight / 2;
  }

  useLayoutEffect(() => {
    const paddingY = 1 * fontSize;
    const paddingX = 1 * fontSize;
    const { width: textWidth, height: textHeight } = textRef.current.getBoundingClientRect();
    let newWidthFromText;
    let newHeightFromText;
    if (position === 'top' || position === 'bottom') {
      newWidthFromText = textWidth + paddingX + 2 * strokeWidth;
      newHeightFromText = textHeight + paddingY + 2 * strokeWidth;
    } else if (position === 'left' || position === 'right') {
      newWidthFromText = textWidth + paddingX + 2 * strokeWidth;
      newHeightFromText = textHeight + paddingY + 2 * strokeWidth;
    }
    setNewWidth(newWidthFromText);
    setNewHeight(newHeightFromText);
  }, [fontSize, position, offset, strokeWidth]);

  return (
    <svg
      width={`${viewBoxWidth || newWidth}px`}
      height={`${viewBoxHeight || newHeight}px`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      x={newX}
      y={newY}
      {...props}
    >
      <g>
        <path
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          stroke={stroke}
          fill={fill}
          d={tooltipPath({ width: newWidth, height: newHeight, offset, radius, position })}
        />
        <text
          ref={textRef}
          x={textX}
          y={textY}
          textAnchor="middle"
          stroke="none"
          fill={textColor}
          fontSize={fontSize}
        >
          {children}
        </text>
      </g>
    </svg>
  );
}

Tooltip.propTypes = {
  position: PropTypes.string,
  offset: PropTypes.number,
  fontSize: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  radius: PropTypes.number,
  strokeWidth: PropTypes.number,
  stroke: PropTypes.string,
  fill: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.node,
};

Tooltip.defaultProps = {
  position: 'top',
  offset: 8,
  fontSize: 16,
  x: 0,
  y: 0,
  width: 100,
  height: 30,
  radius: 10, // 'var(--border-radius, 10px)',
  strokeWidth: 1,
  stroke: 'var(--border-color, rgb(229, 229, 234))',
  fill: 'var(--layout-background-color, #ffffff)',
  textColor: 'var(--font-color, #565763)',
  children: '',
};

export default Tooltip;
