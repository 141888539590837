import './selectsetmodal.scss';
import React from 'react';

import PropTypes from 'prop-types';

import Button from '../../../../components/buttons/Button';
import Modal from '../../../../components/Modal/Modal';
import pushupsDays from '../progressionDays';

function SelectSetModal({ isOpen, setIsOpen, onSelect }) {
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Select a Set"
    >
      <div className="select-set-modal">
        <div className="free-statement">
          Select Free Set to folow your own workout plan
        </div>
        <Button
          className="freeset-button"
          onClick={() => onSelect('free-sets')}
        >
          Free Set
        </Button>
        <div className="or-statement">
          Or select a training day below as a starting day
        </div>
        <div className="select-title">
          <div>day</div>
          <div>set</div>
          <div>total</div>
        </div>
        <div className="select-list">
          {pushupsDays.map((day, i) => (
            <Button
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              value={i}
              onClick={() => onSelect(i + 1)}
              className="select-option"
            >
              <div className="option-day">{i + 1}</div>
              <div className="option-set">{day.join('\xa0\xa0')}</div>
              <div className="option-total">{day.reduce((k, j) => k + j)}</div>
            </Button>
          ))}
        </div>
        <div
          style={{
            height: '0',
            width: '15em',
            margin: '0  auto',
            borderBottom: '1px solid var(--border-color)',
          }}
        />
      </div>
    </Modal>
  );
}

SelectSetModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SelectSetModal;
