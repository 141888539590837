/* eslint-disable max-len */
import React from 'react';

function GithubIcon(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 39 39"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <mask id="github-icon-mask">
          <path fill="#FFF" d="M19.4672131,0 C8.71157787,0 0,8.7175609 0,19.480583 C0,28.100741 5.57248975,35.3816089 13.310707,37.9627862 C14.2840676,38.1332413 14.6490779,37.5488238 14.6490779,37.0374585 C14.6490779,36.5747946 14.6247439,35.0406987 14.6247439,33.4091999 C9.73360656,34.3101768 8.4682377,32.2160142 8.07889344,31.1202314 C7.8598873,30.5601646 6.91086066,28.8312629 6.0835041,28.368599 C5.40215164,28.0033381 4.42879098,27.1023611 6.05917008,27.0780104 C7.59221311,27.0536597 8.68724385,28.4903527 9.0522541,29.0747702 C10.8043033,32.0212083 13.6027152,31.1932836 14.7220799,30.6819183 C14.892418,29.4156804 15.4034324,28.5634048 15.9631148,28.0763903 C11.6316598,27.5893757 7.10553279,25.9091754 7.10553279,18.4578524 C7.10553279,16.339339 7.8598873,14.5860865 9.10092213,13.2224457 C8.90625,12.7354311 8.22489754,10.7386714 9.29559426,8.06009122 C9.29559426,8.06009122 10.9259734,7.54872592 14.6490779,10.056851 C16.2064549,9.61853786 17.861168,9.39938131 19.5158811,9.39938131 C21.1705943,9.39938131 22.8253074,9.61853786 24.3826844,10.056851 C28.1057889,7.52437519 29.736168,8.06009122 29.736168,8.06009122 C30.8068648,10.7386714 30.1255123,12.7354311 29.9308402,13.2224457 C31.171875,14.5860865 31.9262295,16.3149883 31.9262295,18.4578524 C31.9262295,25.9335261 27.3757684,27.5893757 23.0443135,28.0763903 C23.75,28.6851585 24.3583504,29.8539935 24.3583504,31.6802981 C24.3583504,34.2858261 24.3340164,36.3799888 24.3340164,37.0374585 C24.3340164,37.5488238 24.6990266,38.157592 25.6723873,37.9627862 C33.3619365,35.3816089 38.9344262,28.0763903 38.9344262,19.480583 C38.9344262,8.7175609 30.2228484,0 19.4672131,0 Z" />
        </mask>
      </defs>
      <g mask="url(#github-icon-mask)">
        <rect x="0" y="0" width="39" height="39" fill="#6E5494" />
        <rect x="0" y="0" width="39" height="39" fill="#000" className="mask" />
      </g>
    </svg>
  );
}

export default GithubIcon;
