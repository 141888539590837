const changeFontSize = (increment, element = document.documentElement) => {
  try {
    if (!Number.isInteger(increment)) {
      throw new Error('First argument: increment, must an integer');
    }

    const computedStyle = window.getComputedStyle(element);
    const newFontSize = Number(computedStyle.getPropertyValue('font-size').slice(0, -2)) + increment;

    element.style.setProperty('font-size', `${newFontSize}px`);
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }
};

export default changeFontSize;
