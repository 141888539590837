import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import Button from './Button';

function SaveButton({
  children,
  disabled,
  onClick,
  submit,
  style,
  timeoutCallback,
  ...otherProps
}) {
  const defaultColor = 'var(--button-text-color, rgb(0, 122, 255))';
  const [color, setColor] = React.useState(defaultColor);
  const timerRef = useRef(null);
  const timeoutCallbackRef = useRef(null);

  const handleSave = async (event) => {
    const isSaved = await onClick(event);
    if (isSaved === true) {
      setColor('var(--green, green)');
    } else {
      setColor('var(--red, red)');
    }

    timerRef.current = setTimeout(() => {
      setColor(defaultColor);
    }, 3000);

    if (isSaved === true && timeoutCallback) {
      timeoutCallbackRef.current = setTimeout(() => {
        timeoutCallback(event);
      }, 1000);
    }
  };

  useEffect(() => () => clearTimeout(timerRef.current), []);
  useEffect(() => () => clearTimeout(timeoutCallbackRef.current), []);

  return (
    <Button
      submit={submit}
      onClick={onClick && handleSave}
      disabled={disabled}
      style={{
        '--button-text-color': color !== defaultColor ? color : '',
        '--button-hover-background-color': color !== defaultColor ? color : '',
        '--button-hover-border-color': color !== defaultColor ? color : '',
        '--button-inverse-background-color': color !== defaultColor ? color : '',
        '--button-inverse-noborder-color': color !== defaultColor ? color : '',
        '--button-inverse-hover-text-color': color !== defaultColor ? color : '',
        '--button-inverse-hover-border-color': color !== defaultColor ? color : '',
        ...style,
      }}
      {...otherProps}
    >
      { children || 'Save'}
    </Button>
  );
}

export default SaveButton;

SaveButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  submit: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.string),
  timeoutCallback: PropTypes.func,
};

SaveButton.defaultProps = {
  children: null,
  disabled: false,
  onClick: null,
  submit: false,
  style: null,
  timeoutCallback: null,
};
