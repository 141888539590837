import './modal.scss';
import React, { useRef, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import CrossButton from '../buttons/CrossButton';
import OverflowBottom from '../OverflowBottom/OverflowBottom';

function Modal({
  isOpen,
  setIsOpen,
  title,
  children,
  style,
  contentStyle,
  className,
  nopadding,
  container = document.body,
  ...otherProps
}) {
  const contentRef = useRef();
  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showBottomTriangle, setShowBottomTriangle] = useState(false);
  const [showChildren, setShowChildren] = useState(isOpen);

  /**
   * Show/hide children when opening/closing the modal
   * Hide children with a delay to let the transition effect finish before
   */
  useEffect(() => {
    if (isOpen) {
      setShowChildren(true);
    } else {
      const hideChildren = () => setShowChildren(false);
      const modalTransitionDurationInMs = parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue('--modal-transition-duration').trim().replace('s', ''),
      ) * 1000;
      setTimeout(hideChildren, modalTransitionDurationInMs);
    }
  }, [isOpen]);

  /**
   * Add Scroll event listener to show/hide shodow and indicator
   */
  useEffect(() => {
    const contentElement = contentRef.current;
    const handleOverflowIndicators = () => {
      const hasReachedTheBottom = contentElement.scrollTop >= contentElement.scrollHeight - contentElement.clientHeight;
      setShowBottomTriangle(!hasReachedTheBottom);
      const hasReachedTheTop = contentElement.scrollTop <= 0;
      setShowTopShadow(!hasReachedTheTop);
    };
    handleOverflowIndicators();
    contentElement.addEventListener('scroll', handleOverflowIndicators);
    return () => contentElement.removeEventListener('scroll', handleOverflowIndicators);
  }, [showChildren, contentRef?.current?.scrollHeight]);

  const closeModal = () => setIsOpen(false);

  return (
    createPortal(
      <div
        className={`modal${className ? ` ${className}` : ''}${isOpen ? '' : ' is-hidden'}`}
        style={{ height: `${container.clientHeight}px` }}
        name={title}
      >
        <div
          className="modal-box"
          // Add max-height for transition effect
          style={{
            maxHeight: isOpen
              ? `calc(${container.clientHeight}px`
              + ' - 2 * var(--modal-margin) - 2 * var(--modal-border-width)' // Background padding and borders
              + ')'
              : 0,
            ...style,
          }}
          {...otherProps}
        >
          <div className={`modal-header${(showTopShadow) ? ' show-shadow' : ' no-shadow'}`}>
            <h3 className="modal-title">{title}</h3>
            <CrossButton round className="cross-button" onClick={closeModal} />
          </div>
          <div
            ref={contentRef}
            className="modal-content"
            style={{
              maxHeight: `calc(${container.clientHeight}px`
                + ' - 2 * var(--modal-margin) - 2 * var(--modal-border-width)'
                + ' - var(--modal-header-height)'
                + ')',
              overflow: 'scroll',
              ...contentStyle,
            }}
          >
            {isOpen || showChildren ? children : null}
            {/**
              * (isOpen || showChildren) makes sur that children are show without delay when isOpen is true
              * but wait that showChildren is false before hiding it
              * Condition is needed to refresh children content when opening modal.
              * As the modal is created when mounting the parent (with some data),
              * Data could be different when it's time to display the modal.
              */}
          </div>
          <OverflowBottom showBottomTriangle={(showBottomTriangle)} />
        </div>
      </div>,
      container,
    )
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  contentStyle: PropTypes.objectOf(PropTypes.string),
  nopaddding: PropTypes.bool,
  container: PropTypes.instanceOf(Element),
};

Modal.defaultProps = {
  title: '',
  children: '',
  className: '',
  style: {},
  contentStyle: {},
  nopadding: false,
  container: document.body,
};

export default Modal;
