import React from 'react';

import Button from './Button';
import LeftIcon from '../svgs/LeftIcon';

function LeftButton(props) {
  return (
    <Button nopadding {...props}>
      <LeftIcon />
    </Button>
  );
}

export default LeftButton;
