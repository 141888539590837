import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { colorBrightness, convertColorToHex } from '../../lib/colors';
import Input from '../inputs/Input';

function CssInput({ value, onChange, ...otherProps }) {
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [value]); // Reapply value if it changes

  const handleColorChange = (event) => {
    setInputValue(event.target.value);
  };
  const getRootPropertyValue = (property) => window.getComputedStyle(document.documentElement).getPropertyValue(property).trim();
  const textColor = getRootPropertyValue('--text-color');
  const textBrightness = colorBrightness(textColor);
  const bgBrightness = colorBrightness(inputValue);
  const bgColor = convertColorToHex(inputValue);

  return (
    <Input
      value={inputValue}
      onChange={(event) => { handleColorChange(event); onChange(event); }}
      backgroundColor={bgColor}
      className={bgBrightness === textBrightness ? 'inverse-colors' : ''}
      {...otherProps}
    />
  );
}

CssInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

CssInput.defaultProps = {
  value: '',
};

export default CssInput;
