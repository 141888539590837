import React from 'react';

function EyeClosed(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      transform="scale(0.8)"
      {...props}
    >
      <g
        stroke="#000000"
        fill="none"
        className="stroke"
        clipPath="url(#clip0_113_48)"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1 14C2.1835 15.2273 3.35162 16.2702 4.5 17.1489M29 14C28.2858 14.9522 27.0899 16.2445 25.5 17.4721M4.5 17.1489C3.5 19.5 1 21 1 21M4.5 17.1489C6.75377 18.8733 8.93148 19.9651 11 20.5771M11 20.5771C10.5 22.5 9.5 24.5 9.5 24.5M11 20.5771C17.038 22.3635 22.1458 20.0618 25.5 17.4721M20 21V24.5M25.5 17.4721C26.5 19 27 21.5 27 21.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_113_48">
          <rect
            width="30"
            height="12"
            transform="translate(0 13)"
            fill="#ffffff"
            className="fill"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EyeClosed;
