/* eslint-disable no-multi-spaces */
import React, { useState } from 'react';

import PropTypes from 'prop-types';

function ExerciseSimpleSettingsContainer({ userContext, children }) {
  const { user } = userContext;
  const exercises = JSON.parse(user.sport.exercises);
  const { currentExercise } = user.sport;

  const [newRestTime, setNewRestTime] = useState(exercises?.[currentExercise]?.restTime?.toString() || '30');
  const [newRestTimeIsValid, setNewRestTimeIsValid] = useState(true);
  const [newRestTimeMessage, setNewRestTimeMessage] = useState('');
  const handleRestTime = (event) => {
    setNewRestTimeIsValid(true);
    setNewRestTimeMessage('');
    setNewRestTime(event?.target?.value);
  };
  const handleRestTimeValidation = (event) => {
    const value = event?.target?.value;
    if (value === '') {
      setNewRestTimeIsValid(false);
      setNewRestTimeMessage('rest time cannot be empty');
    } else if (!/^\d+$/.test(value)) {
      setNewRestTimeIsValid(false);
      setNewRestTimeMessage('rest time must be a number');
    } else {
      setNewRestTimeIsValid(true);
      setNewRestTimeMessage('');
    }
  };

  const [newAddedWeight, setAddedNewWeight] = useState(exercises?.[currentExercise]?.addedWeight?.toString() || '0');
  const [newAddedWeightIsValid, setAddedNewWeightIsValid] = useState(true);
  const [newAddedWeightMessage, setAddedNewWeightMessage] = useState('');
  const handleWeight = (event) => {
    const value = event?.target?.value;
    setAddedNewWeightIsValid(true);
    setAddedNewWeightMessage('');
    setAddedNewWeight(value);
  };
  const handleWeightValidation = (event) => {
    const value = event?.target?.value;
    if (value === '') {
      setAddedNewWeightIsValid(false);
      setAddedNewWeightMessage('added weight cannot be empty');
    } else if (!/^\d+$/.test(value)) {
      setAddedNewWeightIsValid(false);
      setAddedNewWeightMessage('added weight must be a number');
    } else {
      setAddedNewWeightIsValid(true);
      setAddedNewWeightMessage('');
    }
  };

  return (
    <>
      {React.Children.map(children, (child) => React.cloneElement(
        child,
        {
          userContext,
          newRestTime,
          newRestTimeIsValid,
          newRestTimeMessage,
          handleRestTime,
          handleRestTimeValidation,
          newAddedWeight,
          newAddedWeightIsValid,
          newAddedWeightMessage,
          handleWeight,
          handleWeightValidation,
        },
        null,
      ))}
    </>
  );
}

ExerciseSimpleSettingsContainer.propTypes = {
  userContext: PropTypes.shape({
    setUser: PropTypes.func,
    user: PropTypes.shape({
      sport: PropTypes.shape({
        currentExercise: PropTypes.any,
        exercises: PropTypes.any,
      }),
    }),
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default ExerciseSimpleSettingsContainer;
