/* eslint-disable no-console */
import fetchGraphQL from '../lib/fetchGraphQL';

/**
 * Save the period shown by the graph.
 * @param {string} id User Id.
 * @param {boolean} groupByCategory Defines if the workouts are grouped by category in the menu.
 * @returns {boolean} Is successfully saved?
 */
async function setGroupByCategoryAPI(id, groupByCategory) {
  if (typeof id !== 'string') return console.error('"id" must be a string');
  if (typeof groupByCategory !== 'boolean') return console.error('"groupByCategory" must be a boolean');

  return fetchGraphQL(`mutation { setGroupByCategory(
    _id: "${id}",
    groupByCategory: ${groupByCategory}
  ) { matchedCount, modifiedCount, upsertedId, upsertedCount } }`)
    .then((response) => {
      if (response.modifiedCount === 1) console.log('grouped by category saved');
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}

export default setGroupByCategoryAPI;
