/* eslint-disable no-mixed-operators */
/** Merge two arrays, sum each elements keeping length of the  array
 * @param {Array} array1 array of numbers
 * @param {Array} array2 array of numbers
 * @return {Array} element i is the sum of the elements i of the two arrays
 */
function addSession(array1 = [], array2 = []) {
  if (array1.length === 0) { return array2; }
  const result = [0, 0, 0, 0, 0];
  for (let i = 0; i < 5; i += 1) {
    result[i] = (parseFloat(array1[i]) || 0) + (parseFloat(array2[i]) || 0);
  }
  return result;
}

function groupByDay(history) {
  const groupedHistory = {
    ...history,
    data: [],
  };
  let day = {};
  for (let i = 0; i < history.data.length; i += 1) {
    if (!day?.time) {
      day.time = new Date(history.data[i].time).setUTCHours(0, 0, 0, 0);
    }
    day.values = addSession(day.values, history.data[i].values);
    day.sets = addSession(day.sets, history.data[i].sets);
    if (day.time !== new Date(history.data[i + 1]?.time).setUTCHours(0, 0, 0, 0)) {
      groupedHistory.data.push(day);
      day = {};
    }
  }
  return groupedHistory;
}

/** Get the beginning of the day in milliseconds timecode
 * @param {Number} time milliseconds since Jan 1, 1970
 * @returns {Number} milliseconds since Jan 1, 1970
 */
function beginningOfTheDay(time) {
  const day = new Date(time);
  return day.setUTCHours(0, 0, 0, 0);
}

/** Get the end of the day in milliseconds timecode
 * @param {Number} time milliseconds since Jan 1, 1970
 * @returns {Number} milliseconds since Jan 1, 1970
 */
function endOfTheDay(time) {
  const day = new Date(time);
  return day.setUTCHours(23, 59, 59, 999);
}

/** Get the beginning of the week (default is Sunday) in milliseconds timecode
 * @param {Number} time milliseconds since Jan 1, 1970
 * @return {Number} milliseconds since Jan 1, 1970
 */
function beginningOfTheWeek(time) {
  const date = new Date(time);
  // Date of the first day of the week = Date of the month - Day of the week
  const dateOfTheBeginningOfTheWeek = date.getUTCDate() - date.getUTCDay();
  date.setUTCDate(dateOfTheBeginningOfTheWeek);
  return date.setUTCHours(0, 0, 0, 0);
}

/** Get the middle of the week (default is tuesday noon) in milliseconds timecode
 * @param {Number} time milliseconds since Jan 1, 1970
 * @return {Number} milliseconds since Jan 1, 1970
 */
/*
function middleOfTheWeek(time) {
  const halfAWeek = 1000 * 60 * 60 * 24 * 3.5;
  return (beginningOfTheWeek(time) + halfAWeek);
}
*/

/** Get the end of the week (default is Saturday) in milliseconds timecode
 * @param {Number} time milliseconds since Jan 1, 1970
 * @return {Number} milliseconds since Jan 1, 1970
 */
function endOfTheWeek(time) {
  const date = new Date(time);
  const daysRemaining = 6 - date.getUTCDay(); // (days are numbered from 0 to 6)
  const dateOfTheEndOfTheWeek = date.getUTCDate() + daysRemaining;
  date.setUTCDate(dateOfTheEndOfTheWeek);
  return date.setUTCHours(23, 59, 59, 999);
}

function groupByWeek(history) {
  const groupedHistory = {
    ...history,
    data: [],
  };
  let week = {};
  for (let i = 0; i < history.data.length; i += 1) {
    if (!week?.time) { // If week.time is not defined => start a new week values to group
      week.time = beginningOfTheWeek(history.data[i].time);
    }
    week.values = addSession(week.values, history.data[i].values);
    week.sets = addSession(week.sets, history.data[i].sets);
    if (week.time !== beginningOfTheWeek(history.data[i + 1]?.time)) {
      // If this is the last values of the week => push the week to the grouped history
      groupedHistory.data.push(week);
      week = {}; // Reinitialize week to start a new one on the next iteration
    }
  }
  return groupedHistory;
}

/** Get the beginning of the month in milliseconds timecode
 * @param {Number} time milliseconds since Jan 1, 1970
 * @return {Number} milliseconds since Jan 1, 1970
 */
function beginningOfTheMonth(time) {
  const date = new Date(time);
  date.setUTCDate(1);
  return date.setUTCHours(0, 0, 0, 0);
}

/** Get the end of the month in milliseconds timecode
 * @param {Number} time milliseconds since Jan 1, 1970
 * @return {Number} milliseconds since Jan 1, 1970
 */
function endOfTheMonth(time) {
  const date = new Date(time);
  date.setUTCMonth(date.getUTCMonth() + 1); // Next month
  date.setUTCDate(0); // Day 0 gets the last day of the previous month
  return date.setUTCHours(23, 59, 59, 999);
}

function groupByMonth(history) {
  const groupedHistory = {
    ...history,
    data: [],
  };
  let month = {};
  for (let i = 0; i < history.data.length; i += 1) {
    if (!month?.time) {
      month.time = beginningOfTheMonth(history.data[i].time);
    }
    month.values = addSession(month.values, history.data[i].values);
    month.sets = addSession(month.sets, history.data[i].sets);
    if (month.time !== beginningOfTheMonth(history.data[i + 1]?.time)) {
      groupedHistory.data.push(month);
      month = {};
    }
  }
  return groupedHistory;
}

function groupShiftingDuration(groupBy) {
  const oneDay = 1000 * 60 * 60 * 24;
  const shifts = {
    day: oneDay / 2,
    week: 0,
    month: 31 * oneDay / 2,
  };
  return shifts[groupBy];
}

function groupHistory(history, groupBy = 'default') {
  let groupedHistory;
  switch (groupBy) {
    case 'day': groupedHistory = groupByDay(history); break;
    case 'week': groupedHistory = groupByWeek(history); break;
    case 'month': groupedHistory = groupByMonth(history); break;
    default: groupedHistory = history;
  }
  return groupedHistory;
}

/** Get the duration for the period
 * @param {'one_week'|'one_month'|'three_months'|'one_year'} period Period to convert
 * @returns {Number} Duration of the period in milliseconds
 */
function getPeriodDuration(period) {
  const day = 24 * 60 * 60 * 1000;
  const durations = {
    one_week: 7 * day,
    one_month: 31 * day,
    three_months: 92 * day,
    one_year: 365 * day,
  };
  if (!Object.keys(durations).includes(period)) {
    throw new Error(`period value is ${period} and should be one of [${Object.keys(durations)}]`);
  }

  return durations[period];
}

/** Get the time of the end  of the timeline including extra time depending on period and groupBy.
 * Adding extra time to the timeline allows to scroll to the end of the grouping with the last date centered.
 * @param {'one_week'|'one_month'|'three_months'|'one_year'} period Duration of the current histogram view
 * @param {'day'|'week'|'month'} groupBy Duration of the histogram data grouping
 * @returns {Number} milliseconds since Jan 1, 1970
 */
function getTimelineEndTime(period, groupBy) {
  const now = Date.now();
  let timeUntilTheEndOfGroupBy;
  switch (groupBy) {
    case 'day': timeUntilTheEndOfGroupBy = endOfTheDay(now); break;
    case 'week': timeUntilTheEndOfGroupBy = endOfTheWeek(now); break;
    case 'month': timeUntilTheEndOfGroupBy = endOfTheMonth(now); break;
    default: timeUntilTheEndOfGroupBy = 0;
  }
  return (getPeriodDuration(period) / 2 + timeUntilTheEndOfGroupBy);
}

/** Get the time of the beginning  of the timeline including extra time depending on period and groupBy.
 * Adding extra time to the timeline allows to scroll to the beginning of the grouping with the first date centered.
 * @param {[{ time: Number }]} history Array of object including a "time" property
 * @param {'one_week'|'one_month'|'three_months'|'one_year'} period String that express the duration of the current histogram view
 * @param {'day'|'week'|'month'} groupBy Duration of the histogram data grouping
 * @returns {Number} milliseconds since Jan 1, 1970
 */
function getTimelineStartTime(firstHistoryTime, period) {
  const periodDuration = getPeriodDuration(period);
  const beginingOfFirstHistoryDate = new Date(firstHistoryTime || Date.now());
  beginingOfFirstHistoryDate.setUTCHours(0, 0, 0, 0);
  return (beginingOfFirstHistoryDate.getTime() - periodDuration / 2);
}

function getHistoriesTimelineStartTime(histories, period, groupBy) {
  const firstHistoryTime = histories
    .flatMap((history) => history.data)
    .map((set) => set.time)
    .sort((a, b) => a - b)[0];

  let firstTimeGroupedHistory;
  switch (groupBy) {
    case 'day': firstTimeGroupedHistory = beginningOfTheDay(firstHistoryTime); break;
    case 'week': firstTimeGroupedHistory = beginningOfTheWeek(firstHistoryTime); break;
    case 'month': firstTimeGroupedHistory = beginningOfTheMonth(firstHistoryTime); break;
    default: throw new Error('"groupBy" argument should  be  one of day|week|month');
  }

  return getTimelineStartTime(firstTimeGroupedHistory, period);
}

// This matrix determines which labels are shown for which period-groupby combination
const periodGroupbyMatrix = {
  one_week: {
    day: 'day',
    week: 'day',
    month: 'month',
  },
  one_month: {
    day: 'week',
    week: 'week',
    month: 'month',
  },
  three_months: {
    day: 'week',
    week: 'week',
    month: 'month',
  },
  one_year: {
    day: 'month',
    week: 'month',
    month: 'month',
  },
};

/** Get timeline labels from history
 * to start timeline at the begining of history,
 * make a copy of the time to avoid reference and modifying it
 */
function getTimelineLabelTimes(startTime, period = 'one_month', groupBy = 'day') {
  const date = new Date(startTime); // Initalize to first day before iterating over history
  date.setUTCHours(0, 0, 0, 0); // Set midnight
  const timelineEndTime = getTimelineEndTime(period, groupBy);
  const timeline = []; // Will contains all dates of the timeline
  let historyDay;
  let historyDate;
  while (date.getTime() <= timelineEndTime) {
    historyDay = date.getUTCDay();
    historyDate = date.getUTCDate();
    if (
      (periodGroupbyMatrix[period][groupBy] === 'day') // everyday
      // eslint-disable-next-line max-len
      || (periodGroupbyMatrix[period][groupBy] === 'week' && historyDay === 0) // every 1st day of the week (default is sunday)
      || (periodGroupbyMatrix[period][groupBy] === 'month' && historyDate === 1) // every first day of the month
    ) {
      timeline.push(date.getTime()); // Push to timeline to show the date on the x-axis
    }
    date.setUTCDate(date.getUTCDate() + 1);
  }
  return timeline;
}

/** Get the label that will be displayed at a specific timecode
 * @param {Number} time Timecode where the label will be displayed
 * @param {String} period Length of the view
 * @param {String} groupBy Duration of the data grouping
 * @returns {String} The label
 */
function getTimelineLabel(time, period, groupBy) {
  const date = new Date(time);
  let label;
  switch (periodGroupbyMatrix[period][groupBy]) {
    case 'day': label = date.toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' }); break;
    case 'week': label = date.toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' }); break;
    case 'month': label = date.toLocaleDateString('en-us', { month: 'short', timeZone: 'UTC' }); break;
    default: label = '';
  }
  return label;
}

/** Return the width of the group
 * @param {Number} containerWidth Width of the Histograrm container view
 * @param {'one_week'|'one_month'|'three_months'|'one_year'} period Period shown throught the histogram container view
 * @param {'day'|'week'|'month'} groupBy Grouping of the data
 * @param {Number} time Time of the data
 * @returns {Number} Width of the group
 */
function getGroupWidth(
  containerWidth,
  period,
  groupBy,
  time,
) {
  const numberOfDaysThisMonth = new Date(endOfTheMonth(time)).getUTCDate();
  // days * (containerWidth / showingDays)
  const groupWidths = {
    one_week: {
      day: containerWidth / 7,
      week: containerWidth / 7 * 7,
      month: containerWidth / 7 * numberOfDaysThisMonth,
      default: containerWidth / 7,
    },
    one_month: {
      day: containerWidth / 31,
      week: containerWidth / 31 * 7,
      month: containerWidth / 31 * numberOfDaysThisMonth,
      default: containerWidth / 31,
    },
    three_months: {
      day: containerWidth / 92,
      week: containerWidth / 92 * 7,
      month: containerWidth / 92 * numberOfDaysThisMonth,
      default: containerWidth / 92 * 7,
    },
    one_year: {
      day: containerWidth / 365,
      week: containerWidth / 365 * 7,
      month: containerWidth / 365 * numberOfDaysThisMonth,
      default: containerWidth / 365 * numberOfDaysThisMonth,
    },
  };
  return groupWidths[period][groupBy];
}

/** Get the maximum height of the graph */
function getMaxReps(histories) {
  if (histories[0]?.data?.length === 0) return 0;
  return Math.max(
    ...histories
      .flatMap((history) => history.data.flatMap((day) => day.values.reduce((total, value) => total + parseFloat(value), 0))),
  ); // Get the higher column;
}

function getTotalInDuration(history, startTime, endTime, values = 'values') {
  if (!Array.isArray(history)) throw new Error(`history value is ${history} and should be an array`);
  if (typeof startTime !== 'number') throw new Error(`startTime value is ${startTime} and should be a number`);
  if (typeof endTime !== 'number') throw new Error(`endTime value is ${endTime} and should be a number`);
  return (history
    .filter((day) => {
      const beginningOfDay = new Date(day.time).setUTCHours(0, 0, 0, 0);
      return (startTime <= beginningOfDay) && (beginningOfDay < endTime);
    })
    .reduce((totalHistory, day) => totalHistory + day[values].reduce((totalDay, rep) => totalDay + parseFloat(rep), 0), 0));
}

function daysInYear(year) {
  return (
    (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) ? 366 : 365
  );
}

function getExactTimeForDuration(period, time) {
  const day = 24 * 60 * 60 * 1000;
  const daysInCurrentMonth = new Date(new Date(time).getUTCFullYear(), new Date(time).getUTCMonth(), 0).getUTCDate();
  if (period === 'one_month') return daysInCurrentMonth * day;
  if (period === 'one_week') return 7 * day;
  if (period === 'three_months') {
    const daysInLastMonth = new Date(new Date(time).getUTCFullYear(), new Date(time).getUTCMonth() - 1, 0).getUTCDate();
    const daysInLastLastMonth = new Date(new Date(time).getUTCFullYear(), new Date(time).getUTCMonth() - 2, 0).getUTCDate();
    return (daysInCurrentMonth + daysInLastMonth + daysInLastLastMonth) * day;
  }
  if (period === 'one_year') {
    return daysInYear(new Date(time).getUTCFullYear());
  }
  return null;
}

function getWeekNumber(time) {
  const date = new Date(time);
  const firstOfJanuary = new Date(date.getUTCFullYear(), 0, 1); // Fist of January of the year
  const days = Math.floor((date - firstOfJanuary) / (24 * 60 * 60 * 1000));
  const weekNumber = Math.ceil(days / 7);
  return weekNumber;
}

function getScrollLeftPosition(time, period, groupBy, containerElement) {
  const historyWidth = containerElement.scrollWidth;
  const containerWidth = containerElement.style.width.replace('px', '');
  const periodDuration = getPeriodDuration(period);
  const timelineEndTime = getTimelineEndTime(period, groupBy);
  const pixelToTimeRatio = containerWidth / periodDuration;
  // scrollLeft cannot be negative, but after calculus rounding, it could be
  // return Math.max(historyWidth - (timelineEndTime - time) * pixelToTimeRatio - containerWidth / 2, 0);
  return historyWidth - (timelineEndTime - time) * pixelToTimeRatio - containerWidth / 2;
}

function getFlatDataHistory(histories, groupBy = '') {
  return histories.map((history) => groupHistory(history, groupBy)) // groupedHistories
    .flatMap(({ data, ...restOfGroupedHistory }) => (
      data.map((entry) => ({
        ...restOfGroupedHistory,
        ...entry,
      }))
    ))
    .sort((a, b) => a.time - b.time); // flatGroupedHistories
  /*
    .reduce((consolidatedEntry, entry, i) => {
      if (i === 0) {
        consolidatedEntry[0].time = entry.time; // eslint-disable-line no-param-reassign
      }
      if (entry.time === consolidatedEntry.at(-1).time) {
        consolidatedEntry.at(-1).data.push(entry);
      } else {
        consolidatedEntry.push({
          time: entry.time,
          data: [entry],
        });
      }
      return consolidatedEntry;
    }, [{
      time: 0,
      data: [],
    }]);
  */
}

const historyJS = {
  groupByDay,
  groupByWeek,
  groupByMonth,
  beginningOfTheDay,
  beginningOfTheWeek,
  beginningOfTheMonth,
  endOfTheDay,
  endOfTheWeek,
  endOfTheMonth,
  addSession,
  groupHistory,
  getTimelineLabelTimes,
  getTimelineLabel,
  getTimelineEndTime,
  getTimelineStartTime,
  getHistoriesTimelineStartTime,
  getGroupWidth,
  getMaxReps,
  getTotalInDuration,
  getPeriodDuration,
  getExactTimeForDuration,
  groupShiftingDuration,
  getWeekNumber,
  getScrollLeftPosition,
  getFlatDataHistory,
};

export default historyJS;
