import './periodsbar.scss';

import React, { useContext } from 'react';

import setPeriodAPI from '../../../../api/setPeriodAPI';
import UserContext from '../../../../contexts/UserContext';
import PeriodItem from './PeriodItem';

function PeriodsBar() {
  const { user, setUser } = useContext(UserContext);
  const { period } = user.sport; // "one_week" | "one_month" | "three_months" | "one_year"

  const handlePeriod = async (event) => {
    const newPeriod = event.target.getAttribute('value');
    if (newPeriod === period) return;
    setUser((prevUser) => {
      const newUser = {
        ...prevUser,
        sport: {
          ...prevUser.sport,
          period: newPeriod,
        },
      };
      return newUser;
    });

    if (navigator.onLine && !!localStorage.getItem('token')) {
      setPeriodAPI(user._id, newPeriod);
    }
  };

  return (
    <div className="periods-bar">
      <PeriodItem value="one_week" period={period} onClick={handlePeriod}>
        1 week
      </PeriodItem>
      <PeriodItem value="one_month" period={period} onClick={handlePeriod}>
        1 month
      </PeriodItem>
      <PeriodItem value="three_months" period={period} onClick={handlePeriod}>
        3 months
      </PeriodItem>
      <PeriodItem value="one_year" period={period} onClick={handlePeriod}>
        1 year
      </PeriodItem>
    </div>
  );
}

export default PeriodsBar;
