import setExercisesAPI from '../../../../api/setExercisesAPI';

async function saveExerciseSimpleSettings({ userContext, newRestTime, newAddedWeight }) {
  const { user, setUser } = userContext;
  const exerciseName = user.sport.currentExercise;

  let newExercises;
  setUser((prevUser) => {
    const exercises = JSON.parse(prevUser.sport.exercises);
    newExercises = JSON.stringify({
      ...exercises,
      [exerciseName]: {
        ...exercises[exerciseName],
        restTime: parseFloat(newRestTime),
        addedWeight: parseFloat(newAddedWeight),
      },
    });
    const newUser = {
      ...prevUser,
      sport: {
        ...prevUser.sport,
        exercises: newExercises,
      },
    };
    return newUser;
  });

  return setExercisesAPI(user._id, newExercises, 'exercise settings saved');
}

export default saveExerciseSimpleSettings;
