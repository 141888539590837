import React from 'react';

import PropTypes from 'prop-types';

import Button from './Button';
import changeFontSize from '../../lib/changeFontSize';
import getContainerElement from '../../lib/getContainerElement';
import MinusIcon from '../svgs/MinusIcon';

function FontMinusButton({
  isLocal,
  element = document.documentElement,
  ...otherProps
}) {
  const decreaseFontSize = (event) => {
    try {
      if (isLocal) {
        changeFontSize(-1, getContainerElement(event.target.parentNode));
      }
      if (!isLocal || (isLocal && element !== document.documentElement)) {
        changeFontSize(-1, element);
      }
    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  };

  return (
    <Button
      aria-label="Decrease font size"
      nopadding
      onClick={decreaseFontSize}
      {...otherProps}
    >
      <MinusIcon />
    </Button>
  );
}

FontMinusButton.propTypes = {
  isLocal: PropTypes.bool,
  element: PropTypes.instanceOf(Element),
};

FontMinusButton.defaultProps = {
  isLocal: false,
  element: document.documentElement,
};

export default FontMinusButton;
