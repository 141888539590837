import React from 'react';

function DownIcon(props) {
  return (
    <svg {...props} width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#000000" transform="rotate(-90) translate(-30 0)">
        <path d="M6,15 L21,30" />
        <path d="M6,15 L21,0" />
      </g>
    </svg>
  );
}

export default DownIcon;
