import './startworkoutbutton.css';
import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/buttons/Button';
import saveExerciseSimpleSettings from './ExerciseSimpleSettings/saveExerciseSimpleSettings';

function StartWorkoutButton({
  userContext,
  newRestTime,
  newRestTimeIsValid,
  newAddedWeight,
  newAddedWeightIsValid,
}) {
  const navigate = useNavigate();
  const handleStart = useCallback(() => {
    saveExerciseSimpleSettings({ userContext, newRestTime, newAddedWeight });
    navigate('/sets');
  }, [navigate, newAddedWeight, newRestTime, userContext]);
  return (
    <Button
      className="start-workout-button"
      inverse
      onClick={handleStart}
      disabled={!newRestTimeIsValid || !newAddedWeightIsValid}
    >
      Start
    </Button>
  );
}

StartWorkoutButton.propTypes = {
  newAddedWeight: PropTypes.string,
  newAddedWeightIsValid: PropTypes.bool,
  newRestTime: PropTypes.string,
  newRestTimeIsValid: PropTypes.bool,
  userContext: PropTypes.shape({
    user: PropTypes.object,
    setUser: PropTypes.func,
  }),
};

StartWorkoutButton.defaultProps = {
  newAddedWeight: '',
  newAddedWeightIsValid: false,
  newRestTime: '',
  newRestTimeIsValid: false,
  userContext: {},
};

export default StartWorkoutButton;
