function readImage(file, callback) {
  // Create  a n ew reader instance
  const reader = new FileReader();
  // Attach onload listener to trigger the callback when loaded
  reader.addEventListener('load', () => callback(reader.result), { capture: false, once: true });
  // Pass the picture file to the reader
  if (file) {
    reader.readAsDataURL(file);
  }
}

export default readImage;
