import React from 'react';

import MenuItem from './MenuItem';

function MenuList({ handleClick, handleSelect, items, checkedItems, selectedItem, categories, groupByCategory }) {
  const disabledClick = (event) => { event.stopPropagation(); };
  return groupByCategory
    ? categories.map((category) => {
      const filteredItems = Object.keys(items)
        .filter((item) => (
          (category === 'uncategorized' && (!items[item]?.categories || items[item]?.categories[0] === ''))
          || (category !== 'uncategorized' && items[item]?.categories?.includes(category))))
        .map((item) => (
          <MenuItem
            value={item}
            key={`${category}-${item}`}
            handleClick={selectedItem !== item ? handleClick : disabledClick}
            handleSelect={selectedItem !== item ? handleSelect : disabledClick}
            checked={checkedItems?.includes(item)}
            selected={selectedItem === item}
          />
        ));

      return (
        <div key={category} style={{ fontSize: '0.7rem' }}>
          <div
            style={{
              borderBottom: '1px solid var(--color3, #ccc)',
              color: 'var(--color4, #7c777f)',
              margin: '0.5rem 1rem 0.2rem 1rem',
            }}
          >
            {category}
          </div>
          {filteredItems}
        </div>
      );
    })
    : Object.keys(items).map((item) => (
      <MenuItem
        value={item}
        key={item}
        handleClick={selectedItem !== item ? handleClick : disabledClick}
        handleSelect={selectedItem !== item ? handleSelect : disabledClick}
        checked={checkedItems?.includes(item)}
        selected={selectedItem === item}
      />
    ));
}

export default MenuList;
