import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Input from '../Input';

function DateTextInput({
  label,
  name,
  defaultValue,
  placeholder,
  required,
  message,
  messageColor,
  style,
  className,
  noborder,
  zIndex,
  id,
  ...inputAttributes
}) {
  const isNewDateFormatValid = (value) => /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/.test(value);
  const [newDate, setNewDate] = useState(defaultValue);
  const [isNewDateInputValid, setIsNewDateInputValid] = useState(true);
  const handleNewDate = ({ target: { value } }) => {
    setIsNewDateInputValid(true);
    setNewDate(value);
  };
  const handleNewDateFormatValidation = () => {
    setIsNewDateInputValid(isNewDateFormatValid(newDate) || newDate === '');
  };

  return (
    <Input
      type="text"
      label={label}
      name={name}
      required={required}
      placeholder={placeholder}
      value={newDate}
      onChange={handleNewDate}
      onBlur={handleNewDateFormatValidation}
      invalid={!isNewDateInputValid}
      showMessage={!isNewDateInputValid}
      message={message || `${label} format must be "mm/dd/yyyy"`}
      messageColor={messageColor}
      style={style}
      className={className}
      noborder={noborder}
      zIndex={zIndex}
      id={id}
      {...inputAttributes}
    />
  );
}

DateTextInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  message: PropTypes.string,
  messageColor: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  className: PropTypes.string,
  noborder: PropTypes.bool,
  zIndex: PropTypes.number,
  id: PropTypes.string,
};
DateTextInput.defaultProps = {
  label: 'date',
  name: null,
  defaultValue: '',
  placeholder: new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' }),
  required: false,
  message: null,
  messageColor: 'var(--red, red)',
  style: {},
  className: null,
  noborder: false,
  zIndex: null,
  id: null,
};
export default DateTextInput;
